import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { HStack, VStack, StackDivider, useColorModeValue, Center, Box, Icon, Text, Heading } from "@chakra-ui/react";
import { Card } from "components";
import { useAppContext } from "hooks";
import { IoChatbubblesOutline, IoCloudOfflineOutline } from "react-icons/io5";
import { Sidebar, Toolbar, Container, Member } from "./containers";
import { ChatContext, SocketContext } from "./contexts";
import SocketProvider from "./socket";

const ChatProvider = () => {
  const { currentUser } = useAppContext();
  const { socket } = useContext(SocketContext);
  const backgroundColor = useColorModeValue("white", "gray.800");
  const [selectedAttendance, setSelectedAttendance] = useState({});
  const [selectedMember, setSelectedMember] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (selectedAttendance) setSelectedMember(null);
  }, [selectedAttendance]);

  useEffect(() => {
    const isMember = _.findIndex(selectedAttendance.members, (o) => o.user?._id === currentUser.uid && o.active) !== -1;
    setIsMember(isMember);
  }, [currentUser.uid, selectedAttendance.members]);

  useEffect(() => {
    setIsFinished(selectedAttendance.status === "FINISHED");
  }, [selectedAttendance.status]);

  return (
    <ChatContext.Provider value={{ selectedAttendance, selectedMember, isMember, isFinished, setSelectedAttendance, setSelectedMember }}>
      <VStack h="100%" alignItems="stretch">
        {!socket && (
          <HStack p="20px" borderRadius="lg" _light={{ bg: "gray.100" }} _dark={{ bg: "gray.900" }}>
            <Center bg="blackAlpha.50" w="40px" h="40px" borderRadius="full">
              <Icon as={IoCloudOfflineOutline} />
            </Center>
            <Box>
              <Heading size="xs">Você está offline</Heading>
              <Text fontSize="xs">
                Não foi possível estabelecer um canal de comunicação com o servidor. Por favor, verifique sua conexão.
              </Text>
            </Box>
          </HStack>
        )}
        <Card flex="1" overflow="hidden" mb={0} bg={backgroundColor}>
          <HStack h="100%" spacing={0} align="flex-start" divider={<StackDivider />}>
            <Sidebar />
            <VStack h="100%" spacing={0} flex={1} align="stretch" divider={<StackDivider />}>
              {selectedAttendance?._id ? (
                <>
                  <Toolbar />
                  <Container />
                  <Member />
                </>
              ) : (
                <Center flex={1}>
                  <Box textAlign="center">
                    <Icon as={IoChatbubblesOutline} boxSize={20} marginBottom="10px" />
                    <Text fontSize="lg" fontWeight="bold">
                      Selecione um atendimento
                    </Text>
                    <Text fontSize="sm">Para visualizar as mensagens selecione um atendimento.</Text>
                  </Box>
                </Center>
              )}
            </VStack>
          </HStack>
        </Card>
      </VStack>
    </ChatContext.Provider>
  );
};

export const Chat = () => (
  <SocketProvider>
    <ChatProvider />
  </SocketProvider>
);
