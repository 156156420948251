import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { UsersContext } from "./index";
import { MdEdit } from "react-icons/md";

const Personal = (props, ref) => {
  const { _id } = useParams();
  const { formData, formErrors, setFormData, setIsOpenPhoneNumber } = useContext(UsersContext);

  return (
    <>
      <SimpleGrid spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors?.name}>
          <FormLabel>Nome</FormLabel>
          <Input value={formData?.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
          <FormErrorMessage>{formErrors?.name}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors?.phone}>
          <FormLabel>Número de telefone</FormLabel>
          <InputGroup>
            <Input
              as={InputMask}
              mask="+99 (99) 9 9999-9999"
              value={formData?.phone ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
              isDisabled={!!_id}
            />
            {!!_id && (
              <InputRightElement>
                <IconButton icon={<Icon as={MdEdit} />} onClick={() => setIsOpenPhoneNumber(true)} isDisabled={formData.anonymous} />
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage>{formErrors?.phone}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formErrors?.document}>
          <FormLabel>CPF</FormLabel>
          <Input
            as={InputMask}
            mask="999.999.999-99"
            value={formData?.document ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
          />
          <FormErrorMessage>{formErrors?.document}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
        <FormControl isInvalid={formErrors?.email}>
          <FormLabel>E-mail</FormLabel>
          <Input value={formData?.email ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))} />
          <FormErrorMessage>{formErrors?.email}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formErrors?.birth}>
          <FormLabel>Nascimento</FormLabel>
          <Input
            as={InputMask}
            mask="99/99/9999"
            value={formData?.birth ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, birth: target.value }))}
          />
          <FormErrorMessage>{formErrors?.birth}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid spacing={4} mb={4}>
        <FormControl isInvalid={formErrors?.comments}>
          <FormLabel>Observações</FormLabel>
          <Textarea
            value={formData?.comments ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
          />
          <FormErrorMessage>{formErrors?.comments}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default Personal;
