import React, { useContext } from "react";
import { SimpleGrid, FormControl, FormLabel, Input, FormErrorMessage, FormHelperText } from "@chakra-ui/react";
import { EnvironmentContext } from "./index";

const Messages = () => {
  const { formData, setFormData, formErrors } = useContext(EnvironmentContext);

  const handleChange = (value) => setFormData((state) => ({ ...state, messages: { ...state.messages, ...value } }));

  return (
    <SimpleGrid spacing={4} mb={4}>
      <FormControl isRequired={true} isInvalid={formErrors["messages.unavailableRealTime"]}>
        <FormLabel fontSize="xs">Tempo real indisponível</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.unavailableRealTime ?? ""}
          onChange={({ target }) => handleChange({ unavailableRealTime: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.unavailableRealTime"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.unavailableDynamicRecharge"]}>
        <FormLabel fontSize="xs">Recarga dinâmica indisponível</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.unavailableDynamicRecharge ?? ""}
          onChange={({ target }) => handleChange({ unavailableDynamicRecharge: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.unavailableDynamicRecharge"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentSuccessCreditCard"]}>
        <FormLabel fontSize="xs">Pagamento | Sucesso | Cartão de Crédito</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentSuccessCreditCard ?? ""}
          onChange={({ target }) => handleChange({ paymentSuccessCreditCard: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentSuccessCreditCard"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentWaitingCreditCard"]}>
        <FormLabel fontSize="xs">Pagamento | Aguardando | Cartão de Crédito</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentWaitingCreditCard ?? ""}
          onChange={({ target }) => handleChange({ paymentWaitingCreditCard: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentWaitingCreditCard"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentWaitingCreditCard"]}>
        <FormLabel fontSize="xs">Pagamento | Falha | Cartão de Crédito</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentErrorCreditCard ?? ""}
          onChange={({ target }) => handleChange({ paymentErrorCreditCard: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentErrorCreditCard"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentSuccessPix"]}>
        <FormLabel fontSize="xs">Pagamento | Sucesso | Pix</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentSuccessPix ?? ""}
          onChange={({ target }) => handleChange({ paymentSuccessPix: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentSuccessPix"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentWaitingPix"]}>
        <FormLabel fontSize="xs">Pagamento | Aguardando | Pix</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentWaitingPix ?? ""}
          onChange={({ target }) => handleChange({ paymentWaitingPix: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentWaitingPix"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentErrorPix"]}>
        <FormLabel fontSize="xs">Pagamento | Falha | Pix</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentErrorPix ?? ""}
          onChange={({ target }) => handleChange({ paymentErrorPix: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentErrorPix"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentWaitingBoleto"]}>
        <FormLabel fontSize="xs">Pagamento | Aguardando | Boleto</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentWaitingBoleto ?? ""}
          onChange={({ target }) => handleChange({ paymentWaitingBoleto: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentWaitingBoleto"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentErrorBoleto"]}>
        <FormLabel fontSize="xs">Pagamento | Falha | Boleto</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentErrorBoleto ?? ""}
          onChange={({ target }) => handleChange({ paymentErrorBoleto: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentErrorBoleto"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentWaitingDeposit"]}>
        <FormLabel fontSize="xs">Pagamento | Aguardando | Depósito</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentWaitingDeposit ?? ""}
          onChange={({ target }) => handleChange({ paymentWaitingDeposit: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentWaitingDeposit"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentMethodSelectCreditCard"]}>
        <FormLabel fontSize="xs">Método de Pagamento Selecionado | Cartão de Crédito</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentMethodSelectCreditCard ?? ""}
          onChange={({ target }) => handleChange({ paymentMethodSelectCreditCard: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentMethodSelectCreditCard"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentMethodSelectPix"]}>
        <FormLabel fontSize="xs">Método de Pagamento Selecionado | Pix</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentMethodSelectPix ?? ""}
          onChange={({ target }) => handleChange({ paymentMethodSelectPix: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentMethodSelectPix"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentMethodSelectBoleto"]}>
        <FormLabel fontSize="xs">Método de Pagamento Selecionado | Boleto</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentMethodSelectBoleto ?? ""}
          onChange={({ target }) => handleChange({ paymentMethodSelectBoleto: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentMethodSelectBoleto"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.paymentMethodSelectDeposit"]}>
        <FormLabel fontSize="xs">Método de Pagamento Selecionado | Depósito</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.paymentMethodSelectDeposit ?? ""}
          onChange={({ target }) => handleChange({ paymentMethodSelectDeposit: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.paymentMethodSelectDeposit"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.issueCardRequestConfirm"]}>
        <FormLabel fontSize="xs">Solicitação de Primeira via</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.issueCardRequestConfirm ?? ""}
          onChange={({ target }) => handleChange({ issueCardRequestConfirm: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.issueCardRequestConfirm"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.reissueCardRequestConfirm"]}>
        <FormLabel fontSize="xs">Solicitação de Segunda via</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.reissueCardRequestConfirm ?? ""}
          onChange={({ target }) => handleChange({ reissueCardRequestConfirm: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.reissueCardRequestConfirm"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.rechargeNewCardRequestConfirm"]}>
        <FormLabel fontSize="xs">Carregar novo cartão?</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.rechargeNewCardRequestConfirm ?? ""}
          onChange={({ target }) => handleChange({ rechargeNewCardRequestConfirm: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.rechargeNewCardRequestConfirm"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.issueCardRequestWithBlockedCard"]}>
        <FormLabel fontSize="xs">Solicitação de cartão com cartão bloqueado</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.issueCardRequestWithBlockedCard ?? ""}
          onChange={({ target }) => handleChange({ issueCardRequestWithBlockedCard: target.value })}
        />
        <FormHelperText>[CARD_NUMBER] = Número do cartão</FormHelperText>
        <FormErrorMessage>{formErrors["messages.issueCardRequestWithBlockedCard"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.issueCardRequestWithActiveCard"]}>
        <FormLabel fontSize="xs">Solicitação de cartão com cartão ativo </FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.issueCardRequestWithActiveCard ?? ""}
          onChange={({ target }) => handleChange({ issueCardRequestWithActiveCard: target.value })}
        />
        <FormHelperText>[CARD_NUMBER] = Número do cartão</FormHelperText>
        <FormErrorMessage>{formErrors["messages.issueCardRequestWithActiveCard"]}</FormErrorMessage>
      </FormControl>
      <FormControl isRequired={true} isInvalid={formErrors["messages.reissueInsteadBlockCardRequestConfirm"]}>
        <FormLabel fontSize="xs">Emitir segunda via ao invés de bloquear?</FormLabel>
        <Input
          fontSize="xs"
          value={formData.messages?.reissueInsteadBlockCardRequestConfirm ?? ""}
          onChange={({ target }) => handleChange({ reissueInsteadBlockCardRequestConfirm: target.value })}
        />
        <FormErrorMessage>{formErrors["messages.reissueInsteadBlockCardRequestConfirm"]}</FormErrorMessage>
      </FormControl>
    </SimpleGrid>
  );
};

export default Messages;
