import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { MdSave, MdChevronLeft, MdAdd, MdDelete, MdOutlineFormatListBulleted } from "react-icons/md";
import _ from "lodash";
import * as yup from "yup";
import { messages } from "consts";
import { api, validationErrorHandler } from "lib";
import { useApiGet } from "hooks";
import Content from "./content";

export const FaqDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(`/faq/${_id}`);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? { sections: [] });
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        body: yup.string().required(messages.error.required),
        sections: yup.array().of(
          yup.object().shape({
            body: yup.string().required(messages.error.required),
          })
        ),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) await api.put(`/faq/${_id}`, data);
      else {
        const saved = await api.post("/faq", data);
        navigate(`/faq/details/${saved._id}`, { replace: true });
      }
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleAddSection = useCallback(() => {
    setFormData((state) => {
      const tmp = { ...state };
      if (_.isArray(tmp.sections) === false) tmp.sections = [];
      tmp.sections.push({});
      return tmp;
    });
  }, []);

  const handleDeleteSection = useCallback((index) => {
    setFormData((state) => {
      const tmp = { ...state };
      tmp.sections.splice(index, 1);
      return tmp;
    });
  }, []);

  const handleChangeSectionData = useCallback(
    (data, index) =>
      setFormData((state) => {
        const tmp = { ...state };
        tmp.sections[index] = data;
        return tmp;
      }),
    []
  );

  return (
    <>
      <Box>
        <HStack marginBottom="20px" justify="space-between">
          <Breadcrumb fontWeight="medium" fontSize="sm">
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/home">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/faq">
                Perguntas frequentes
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.title ?? "Novo"}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>

        <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
          <FormControl isInvalid={formErrors.deepLink}>
            <FormLabel>Deep Link</FormLabel>
            <Input
              value={formData.deepLink ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, deepLink: target.value }))}
            />
            <FormErrorMessage>{formErrors.deepLink}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formErrors.youTubeVideoId}>
            <FormLabel>ID do YouTube</FormLabel>
            <Input
              value={formData.youTubeVideoId ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, youTubeVideoId: target.value }))}
            />
            <FormErrorMessage>{formErrors.youTubeVideoId}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <HStack borderWidth="1px" borderRadius="lg" p="20px" borderColor={(formErrors.title || formErrors.body) && "red.500"}>
          <Image
            src={formData.img?.transforms?.original?.location}
            fallbackSrc="https://via.placeholder.com/50"
            alt={formData.img?.name}
            width="50px"
            borderWidth="1px"
            borderRadius="lg"
          />
          <Text fontSize="lg" fontWeight="bold" flex="1" opacity={formData.title ? 1 : 0.6}>
            {formData.title ?? "Título não informado"}
          </Text>
          <Content data={formData} onChange={(data) => setFormData(data)} />
        </HStack>
        <Text mt="2px" color="red.500" fontSize="sm">
          {formErrors.title || formErrors.body}
        </Text>

        <HStack justify="space-between" my={4}>
          <Box>
            <Text fontSize="lg" fontWeight="bold">
              Sessões
            </Text>
            <Text fontSize="sm">Adicione novas sessões ao conteúdo para segmentar a informação e inserir novas imagens.</Text>
          </Box>
          <IconButton icon={<Icon as={MdAdd} />} size="sm" colorScheme="main" onClick={handleAddSection} />
        </HStack>

        {_.map(formData?.sections, (section, index) => {
          const errorMessage = formErrors[`sections[${index}].body`];
          return (
            <Box key={index} mb="10px">
              <HStack borderWidth="1px" borderRadius="lg" p="20px" borderColor={errorMessage && "red.500"}>
                <Image
                  src={section.img?.transforms?.original?.location}
                  fallbackSrc="https://via.placeholder.com/50"
                  alt={section.img?.name}
                  width="50px"
                  borderWidth="1px"
                  borderRadius="lg"
                />
                <Text fontSize="lg" fontWeight="bold" flex="1" opacity={section.title ? 1 : 0.6}>
                  {section.title ?? "Título não informado"}
                </Text>
                <Content isTitleRequired={false} data={section} onChange={(data) => handleChangeSectionData(data, index)} />
                <IconButton icon={<Icon as={MdDelete} />} colorScheme="red" size="sm" onClick={() => handleDeleteSection(index)} />
              </HStack>
              {errorMessage && (
                <Text mt="2px" color="red.500" fontSize="sm">
                  {errorMessage}
                </Text>
              )}
            </Box>
          );
        })}

        {_.size(formData?.sections) === 0 && (
          <Center paddingTop="40px" paddingBottom="20px">
            <Box textAlign="center">
              <Icon as={MdOutlineFormatListBulleted} boxSize={20} marginBottom="10px" />
              <Text fontSize="lg" fontWeight="bold">
                Nenhuma sessão adicionada
              </Text>
              <Text fontSize="sm">Ainda não foram adicionadas sessões neste documento.</Text>
            </Box>
          </Center>
        )}

        <HStack justify="flex-end" spacing={4} mt={6}>
          <Button as={RouterLink} to="/faq" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData} onClick={handleSubmit}>
            Salvar
          </Button>
        </HStack>
      </Box>
    </>
  );
};
