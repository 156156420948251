export const document = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } catch (error) {
    return "-";
  }
};

export const phone = (value) => {
  try {
    value = value.replace(/[^\d]/g, "");
    return value.replace(/(\d{2})(\d{4})(\d{4,})/, "($1) $2-$3");
  } catch (error) {
    return "-";
  }
};

export const cardNumber = (value) => {
  try {
    if (!value || value?.length <= 14) return value;
    return `${value.substring(0, 14)}-${value.substring(14)}`;
  } catch (error) {
    return "-";
  }
};
