import React from "react";

const Logo = ({ width = 100, height = 40, color = "#fff" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 397.806 97.725">
      <g transform="translate(-916 -623.55)">
        <path
          id="path19"
          d="M63.739,72.594H51.723V66.388c0-2.653.588-4.416,1.8-5.406l.067-.064c1.319-.878,3.329-1.321,6.186-1.321h3.965V45.922c-.981-.1-2.222-.151-3.693-.151l-.174-.021a27.764,27.764,0,0,0-4.3-.266c-7.311,0-12.618,1.543-15.754,4.564-3.305,3.184-4.975,8.112-4.975,14.622v77.121H51.723V84.426h3.849a7.85,7.85,0,0,0,5.7-2.416A8.217,8.217,0,0,0,63.739,75.8Z"
          transform="translate(881.156 578.067)"
          fill={color}
          fill-rule="evenodd"
        />
        <path
          id="path21"
          d="M90.149,98.133c0,5.592-1.6,10.075-4.781,13.311-3.16,3.236-7.543,4.886-13.034,4.886-3.406,0-6.089-.866-7.949-2.564l-.049-.039a10.463,10.463,0,0,1-2.811-7.6,12.894,12.894,0,0,1,2.11-6.863l.143-.181c1.63-1.656,4.149-2.971,7.716-3.977l.07-.02,6.429-1.426a51.628,51.628,0,0,0,10.634-3.622l1.522-.777Zm12.17,18.1c2.572-4.079,3.876-9.886,3.876-17.246V73.02c0-6.53-2.494-11.473-7.64-15.1-5.006-3.469-12.7-5.238-22.862-5.238-9.007,0-16.009,2.133-20.82,6.321a21,21,0,0,0-7.282,12.657H64.429a8.756,8.756,0,0,1,4.758-4.222,21.042,21.042,0,0,1,7.35-1.2,29.257,29.257,0,0,1,8.243,1.331c3.329.983,5.094,3.259,5.094,6.6,0,4.456-4.325,7.243-13.193,8.476a44.061,44.061,0,0,0-4.4.7A17.548,17.548,0,0,1,68.8,83.8c-6.849,1.11-12.546,3.337-16.925,6.638-4.253,3.2-6.415,8.666-6.415,16.252,0,8.166,2.385,14.056,7.1,17.547a28.013,28.013,0,0,0,16.994,5.348,107.457,107.457,0,0,0,11.47-.7,32.871,32.871,0,0,0,12.048-3.737,24.106,24.106,0,0,0,9.242-8.909Z"
          transform="translate(903.536 591.693)"
          fill={color}
          fill-rule="evenodd"
        />
        <path
          id="path23"
          d="M85.889,141.6V53.921a7.87,7.87,0,0,0-2.456-5.929,8.275,8.275,0,0,0-5.988-2.41h-9V141.6Z"
          transform="translate(946.557 578.256)"
          fill={color}
          fill-rule="evenodd"
        />
        <path
          id="path25"
          d="M93.51,83.545c.19-5.209,1.7-9.294,4.5-12.151A14.521,14.521,0,0,1,108.8,67.1c4.746,0,8.489,1.449,11.1,4.338,2.4,2.637,3.8,6.7,4.193,12.056l.087,1.149H93.47ZM140.66,106.9H128.076a7.3,7.3,0,0,0-4.538,1.207c-1.484,1.175-1.713,1.675-1.725,1.771l-.071.24-1.723,1.747a4.987,4.987,0,0,1-1.846,1.224,16.626,16.626,0,0,1-8.532,2.089c-5.147,0-9.073-1.656-11.677-4.936-2.771-3.031-4.265-7.675-4.455-13.8l-.032-1.1h48.586a7.779,7.779,0,0,0,.043-.93V91.276c0-12.226-2.923-21.877-8.675-28.684-5.9-6.561-14.375-9.909-25.185-9.909-10.246,0-18.354,3.439-24.058,10.2-5.584,6.6-8.408,15.975-8.408,27.829,0,12.421,2.917,22.067,8.663,28.681,5.746,6.767,13.827,10.191,24.079,10.191,8.483,0,15.6-2.13,21.158-6.352,5.224-3.793,8.9-9.266,10.979-16.326Z"
          transform="translate(960.218 591.693)"
          fill={color}
          fill-rule="evenodd"
        />
        <path
          id="path31"
          d="M218.129,104.984c0-5.371-1.677-9.7-4.959-12.9C209.588,88.992,203.116,86.2,193.9,83.8a11.64,11.64,0,0,1-2.457-.315,26.868,26.868,0,0,1-2.984-.87c-10.439-2.255-15.3-5.037-15.3-8.739a6.867,6.867,0,0,1,2.978-6.027c2.083-1.277,5.006-1.89,8.967-1.89,4.13,0,7.433.942,9.838,2.784l.118.1,2.232,2.282.087.1.075.118a7.813,7.813,0,0,0,7.191,4.317h10.89a24.277,24.277,0,0,0-8.187-16.634c-5.563-4.2-13.137-6.332-22.526-6.332-8.65,0-15.74,2.135-21.125,6.332a21.7,21.7,0,0,0-7.708,17.139c0,5.441,1.706,9.663,5.239,12.9l.033.04c3.211,3.273,9.714,6.153,19.334,8.562l.083.024a52.4,52.4,0,0,0,7.383,1.671c8.5,1.865,12.617,4.723,12.617,8.762a7.106,7.106,0,0,1-3.88,6.348,18.789,18.789,0,0,1-9.182,2.147c-4.855,0-8.806-1.024-11.76-3.038a11.344,11.344,0,0,1-4.57-8.381H154.322c.048,7.889,2.638,13.965,7.7,18.075,5.006,4.195,12.769,6.313,23.09,6.313,10.878,0,19.131-2.036,24.506-6.063,5.723-4.381,8.514-10.445,8.514-18.534Z"
          transform="translate(1095.677 591.693)"
          fill={color}
          fill-rule="evenodd"
        />
        <path
          id="União_4"
          data-name="União 4"
          d="M-934.514-2992.571c-.3,0-.534.006-.745.006-10.235,0-18.336-3.429-24.079-10.191-5.748-6.617-8.662-16.267-8.662-28.681q0-1.8.086-3.518V-3090h9a8.277,8.277,0,0,1,5.989,2.409,7.87,7.87,0,0,1,2.456,5.928v15.3a34.161,34.161,0,0,1,14.933-3.1c.277,0,.553,0,.821.006h0c.3,0,.534-.005.745-.005,10.235,0,18.336,3.428,24.079,10.191,5.748,6.617,8.663,16.266,8.663,28.681,0,11.869-2.829,21.233-8.408,27.829-5.71,6.766-13.8,10.2-24.057,10.2C-933.97-2992.565-934.245-2992.568-934.514-2992.571Zm-15.8-33.268h.039l0,.138c.19,6.131,1.688,10.774,4.455,13.8,2.5,3.152,6.236,4.809,11.1,4.928v-.014c.148,0,.306.005.468.005a14.529,14.529,0,0,0,10.789-4.294c2.8-2.858,4.312-6.946,4.5-12.151l.028-.762h.014v-12h-.039l0-.138c-.19-6.132-1.688-10.775-4.455-13.8-2.5-3.152-6.234-4.81-11.1-4.93v.015c-.148,0-.306,0-.468,0a14.527,14.527,0,0,0-10.789,4.294c-2.8,2.857-4.313,6.946-4.5,12.151l-.028.762h-.015Z"
          transform="translate(2075 3713.839)"
          fill={color}
        />
        <path
          id="União_3"
          data-name="União 3"
          d="M-934.516-3012.233c-.31,0-.539.006-.745.006-10.235,0-18.336-3.428-24.079-10.19-5.506-6.338-8.413-15.465-8.648-27.135l-.012-.022v-.822c0-.035,0-.07,0-.1h0V-3090h17.727v44.692c.2,6.1,1.695,10.729,4.453,13.745,2.5,3.152,6.234,4.811,11.1,4.929v-.015c.15.005.313.005.468.005a14.532,14.532,0,0,0,10.789-4.293c2.8-2.858,4.312-6.947,4.5-12.152l.028-.76h.013v-37.473a7.819,7.819,0,0,1,2.49-5.929,8.451,8.451,0,0,1,6.07-2.409h9.126v40.087l0,.006c-.107,11.545-2.933,20.677-8.4,27.145-5.711,6.766-13.805,10.2-24.057,10.2C-933.98-3012.226-934.255-3012.228-934.516-3012.233Z"
          transform="translate(2146 3733.5)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Logo;
