import React from "react";
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, Button, Center } from "@chakra-ui/react";
import { CirclePicker } from "react-color";

export const ColorPicker = ({ value, onChange }) => {
  const bg = value && { bg: value, _hover: { bg: value }, _active: { bg: value } };
  return (
    <Popover>
      <PopoverTrigger>
        <Button isFullWidth {...bg} colorScheme="gray" />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Center>
            <CirclePicker color={value} onChangeComplete={(color) => onChange?.(color.hex)} />
          </Center>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
