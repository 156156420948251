import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Container from "./Container";
import * as Pages from "./";

const Public = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="phone-number" element={<Container />}>
          <Route path="send" element={<Pages.SignInPhoneNumberSend />} />
          <Route path="validate" element={<Pages.SignInPhoneNumberValidate />} />
        </Route>
        <Route path="*" element={<Navigate to="/phone-number/send" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Public;
