import React, { useState, useRef, useCallback } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdSearch, MdClose, MdDelete, MdRefresh, MdFilterList, MdVisibility, MdCheck, MdImage } from "react-icons/md";
import { BiListCheck, BiListMinus } from "react-icons/bi";
import { Dialog } from "components";
import { Paginator } from "components";
import { messages } from "consts";
import { useApiGet } from "hooks";
import { api } from "lib";
import { Upload } from "./upload";
import { Selecteds } from "./selecteds";
import { ImagePreview } from "./preview";

const sortable = [
  { key: "name", label: "Nome" },
  { key: "type", label: "Tipo" },
  { key: "size", label: "Tamanho" },
  { key: "createdAt", label: "Criação" },
];

export const ImageLibrary = ({ isOpen, onClose, onFinish }) => {
  const [perPage, setPerPage] = useState(50);
  const [sortKey, setSortKey] = useState("name");
  const [sortDirection, setSortDirection] = useState(1);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [response, loading, refresh] = useApiGet("/images", { search, perPage, page, sort: { [sortKey]: sortDirection } });
  const [selecteds, setSelecteds] = useState([]);
  const [showSelecteds, setShowSelecteds] = useState(false);
  const toast = useToast();
  const deleteRef = useRef();

  const handleFinishUpload = useCallback(
    (docs) => {
      setSelecteds(docs);
      refresh();
    },
    [refresh]
  );

  const handleClose = () => {
    setSelecteds([]);
    onClose?.();
  };

  const handleDelete = async () => {
    try {
      const promises = [];
      for (const selected of selecteds) promises.push(api.delete(`images/${selected._id}`));
      await Promise.all(promises);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setSelecteds([]);
      refresh();
    }
  };

  const handleSelect = (item) => {
    const tmp = [...selecteds];
    const index = tmp.findIndex((o) => o._id === item._id);
    if (index === -1) tmp.push(item);
    else tmp.splice(index, 1);
    setSelecteds(tmp);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearch(searchText);
  };

  const handleFinish = () => {
    onFinish?.(selecteds);
    handleClose();
  };

  const handleCloseSelectedsDrawer = useCallback(() => setShowSelecteds(false), []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        placement="bottom"
        onClose={handleClose}
        size="5xl"
        scrollBehavior="inside"
        isCentered={true}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader as={HStack} fontSize="md">
            {selecteds.length === (response?.data ?? []).length ? (
              <Tooltip label="Desmarcar todos">
                <IconButton
                  icon={<Icon as={BiListMinus} boxSize={5} />}
                  onClick={() => setSelecteds([])}
                  colorScheme="main"
                  variant="ghost"
                />
              </Tooltip>
            ) : (
              <Tooltip label="Marcar todos">
                <IconButton
                  icon={<Icon as={BiListCheck} boxSize={5} />}
                  onClick={() => setSelecteds(response?.data ?? [])}
                  colorScheme="main"
                  variant="ghost"
                />
              </Tooltip>
            )}
            <Box>
              <Menu closeOnSelect={false}>
                <Tooltip label="Ordenação">
                  <MenuButton as={IconButton} icon={<Icon as={MdFilterList} />} />
                </Tooltip>
                <MenuList>
                  <MenuOptionGroup
                    type="radio"
                    title="Direção"
                    value={sortDirection.toString()}
                    onChange={(value) => setSortDirection(parseInt(value))}
                  >
                    <MenuItemOption value="1">Crescente</MenuItemOption>
                    <MenuItemOption value="-1">Decrescente</MenuItemOption>
                  </MenuOptionGroup>
                  <MenuDivider />
                  <MenuOptionGroup type="radio" title="Atributo" value={sortKey} onChange={(value) => setSortKey(value)}>
                    {sortable.map(({ key, label }) => (
                      <MenuItemOption key={key} value={key}>
                        {label}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
            <Box as="form" flex="1" onSubmit={handleSearchSubmit}>
              <InputGroup>
                <Input
                  variant="filled"
                  placeholder="Buscar em Imagens"
                  value={searchText}
                  onChange={({ target }) => setSearchText(target.value)}
                />
                <InputRightElement>
                  <IconButton type="submit" icon={<Icon as={MdSearch} />} isLoading={loading} variant="ghost" colorScheme="none" />
                </InputRightElement>
              </InputGroup>
            </Box>
            <Tooltip label="Excluir">
              <IconButton
                icon={<Icon as={MdDelete} />}
                onClick={() => deleteRef.current.confirm({}, handleDelete)}
                isDisabled={!selecteds.length}
              />
            </Tooltip>

            <Tooltip label="Atualizar">
              <IconButton icon={<Icon as={MdRefresh} />} isLoading={loading} onClick={refresh} />
            </Tooltip>
            <Box>
              <Menu closeOnSelect={true}>
                <Tooltip label="Limite">
                  <MenuButton as={Button} leftIcon={<Icon as={MdVisibility} boxSize={4} />} fontSize="xs">
                    {perPage}
                  </MenuButton>
                </Tooltip>
                <MenuList minWidth="240px">
                  <MenuOptionGroup value={perPage.toString()} type="radio" onChange={(value) => setPerPage(parseInt(value))}>
                    {["10", "20", "50", "100", "200"].map((e) => (
                      <MenuItemOption key={e} value={e}>
                        {e}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
            <Button
              leftIcon={<Icon as={MdCheck} />}
              colorScheme="main"
              fontSize="sm"
              onClick={handleFinish}
              isDisabled={selecteds.length === 0}
            >
              Finalizar
            </Button>
            <IconButton icon={<Icon as={MdClose} />} onClick={handleClose} />
          </ModalHeader>
          <Divider />
          <ModalBody>
            <SimpleGrid columns={[2, 6]} spacing={2}>
              {(response?.data ?? []).map((item) => (
                <ImagePreview
                  key={item._id}
                  data={item}
                  isSelected={selecteds.findIndex((o) => o._id === item._id) !== -1}
                  onSelect={handleSelect}
                />
              ))}
            </SimpleGrid>
            {loading && (
              <Center py={6}>
                <Spinner />
              </Center>
            )}
            {!(response?.data ?? []).length && !loading && (
              <VStack spacing={0} py={6}>
                <Icon as={MdImage} boxSize={20} />
                <Text fontSize="lg" fontWeight="bold">
                  Nenhuma imagem encontrada
                </Text>
                <Text fontSize="sm">Não encontramos imagens correspondentes à sua busca.</Text>
              </VStack>
            )}
          </ModalBody>
          <Divider />
          <ModalFooter justifyContent="space-between">
            <HStack>
              <Upload onFinish={handleFinishUpload} />
              {selecteds.length > 0 && (
                <Button onClick={() => setShowSelecteds(true)} fontSize="sm">
                  {selecteds.length} selecionados
                </Button>
              )}
            </HStack>
            <Paginator loading={loading} page={page} size={response?.size ?? 0} perPage={perPage} onPaginate={(page) => setPage(page)} />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Selecteds isOpen={showSelecteds} onSelect={handleSelect} onClose={handleCloseSelectedsDrawer} data={selecteds} />
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
