export const currency = {
  format: (value) => {
    try {
      return "R$ ".concat(
        parseFloat(value)
          .toFixed(2)
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      );
    } catch (error) {
      return "R$ -";
    }
  },
};
