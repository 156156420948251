import React, { useContext } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { CurrencyInput, Card, CardBody } from "components";
import { EnvironmentContext } from "./index";
import { MdAttachMoney } from "react-icons/md";
import { RiPercentLine } from "react-icons/ri";

const ValueInput = ({ label, attr }) => {
  const { formData, setFormData, formErrors } = useContext(EnvironmentContext);

  const handleChange = (data) =>
    setFormData((state) => ({ ...state, taxes: { ...state.taxes, [attr]: { ...(state.taxes?.[attr] ?? {}), ...data } } }));

  return (
    <Card>
      <CardBody>
        <Text mb="10px" fontWeight="bold">
          {label}
        </Text>
        <HStack>
          <FormControl isRequired={true} isInvalid={formErrors[`taxes.${attr}.value`]}>
            <FormLabel fontSize="xs" mb="5px">
              Valor
            </FormLabel>
            <InputGroup>
              <Input
                as={CurrencyInput}
                allowNegative={true}
                decimalSeparator=","
                thousandSeparator="."
                precision="2"
                value={formData.taxes?.[attr]?.value ?? 0}
                onChangeEvent={(_, __, floatValue) => handleChange({ value: floatValue })}
                autoFocus={false}
              />
              <InputRightAddon
                cursor="pointer"
                onClick={() => handleChange({ isPercent: !formData.taxes?.[attr]?.isPercent, min: 0, max: 0 })}
              >
                <Icon as={formData.taxes?.[attr]?.isPercent ? RiPercentLine : MdAttachMoney} />
              </InputRightAddon>
            </InputGroup>
            <FormErrorMessage>{formErrors[`taxes.${attr}.value`]}</FormErrorMessage>
          </FormControl>
          {formData.taxes?.[attr]?.isPercent && (
            <>
              <FormControl isRequired={true} isInvalid={formErrors[`taxes.${attr}.min`]}>
                <FormLabel fontSize="xs" mb="5px">
                  Mínimo {label}
                </FormLabel>
                <InputGroup>
                  <InputLeftElement fontSize="xs" fontWeight="bold">
                    R$
                  </InputLeftElement>
                  <Input
                    as={CurrencyInput}
                    allowNegative={true}
                    decimalSeparator=","
                    thousandSeparator="."
                    precision="2"
                    value={formData.taxes?.[attr]?.min ?? 0}
                    onChangeEvent={(_, __, min) => handleChange({ min })}
                    autoFocus={false}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors[`taxes.${attr}.min`]}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors[`taxes.${attr}.max`]}>
                <FormLabel fontSize="xs" mb="5px">
                  Máximo {label}
                </FormLabel>
                <InputGroup>
                  <InputLeftElement fontSize="xs" fontWeight="bold">
                    R$
                  </InputLeftElement>
                  <Input
                    as={CurrencyInput}
                    allowNegative={true}
                    decimalSeparator=","
                    thousandSeparator="."
                    precision="2"
                    value={formData.taxes?.[attr]?.max ?? 0}
                    onChangeEvent={(_, __, max) => handleChange({ max })}
                    autoFocus={false}
                  />
                </InputGroup>
                <FormErrorMessage>{formErrors[`taxes.${attr}.max`]}</FormErrorMessage>
              </FormControl>
            </>
          )}
        </HStack>
      </CardBody>
    </Card>
  );
};

const Taxes = () => {
  return (
    <>
      <ValueInput label="Entrega" attr="delivery" />
      <ValueInput label="Boleto" attr="boleto" />
      <ValueInput label="Cartão de Crédito" attr="credit_card" />
      <ValueInput label="Depósito" attr="deposit" />
      <ValueInput label="Pix" attr="pix" />
    </>
  );
};

export default Taxes;
