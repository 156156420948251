import React, { useState, useRef, createContext, useMemo, useCallback, useContext, useEffect } from "react";
import moment from "moment";
import { Link as RouterLink, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Alert,
  AlertDescription,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  SimpleGrid,
  Spinner,
  StackDivider,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  MdOpenInNew,
  MdOutlineCheck,
  MdPrint,
  MdKeyboardArrowDown,
  MdOutlineCancel,
  MdSave,
  MdDeliveryDining,
  MdArrowDropDown,
} from "react-icons/md";
import { TbWebhook } from "react-icons/tb";
import ReactToPrint from "react-to-print";
import { messages } from "consts";
import { masks, currency, translator, api } from "lib";
import { useApiGet } from "hooks";
import { StatusBadge } from "components";
import { ImageViewer } from "containers";
import { AppContext } from "App";
import PrintableDelivery from "./printableDelivery";

export const OrdersDetailsContext = createContext();

export const OrdersDetails = () => {
  const { _id } = useParams();
  const { currentClaims } = useContext(AppContext);
  const [data, loadingData, refreshData] = useApiGet(`/orders/${_id}`);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingSaveRecharge, setLoadingSaveRecharge] = useState(false);
  const [loadingCancelRecharge, setLoadingCancelRecharge] = useState(false);
  const [isOpenImageViewer, setIsOpenImageViewer] = useState(false);
  const [isLoadingWebhook, setIsLoadingWebhook] = useState(false);
  const isLoading = useMemo(() => loadingSaveData || loadingData, [loadingSaveData, loadingData]);
  const [formData, setFormData] = useState({});
  const toast = useToast();
  const printableMainRef = useRef();
  const printableDeliveryRef = useRef();
  const commentsRef = useRef();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      await api.put(`/orders/${_id}`, data);
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleUpdateRechargeStatus = async (status) => {
    try {
      const confirm = window.confirm(`Deseja realmente alterar esta recarga para o status ${translator(status)}?`);
      if (!confirm) return;
      setLoadingSaveRecharge(true);
      await api.put(`/orders/${_id}/recharge`, { status });
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveRecharge(false);
    }
  };

  const handleWebhook = useCallback(async () => {
    try {
      setIsLoadingWebhook(true);
      await api.put(`/orders/${_id}/webhook`);
      refreshData();
    } finally {
      setIsLoadingWebhook(false);
    }
  }, [_id, refreshData]);

  const handleUpdateCanceledConfirm = async () => {
    const confirm = window.prompt(messages.warning.cancelOrder);
    if (confirm !== "CONFIRMAR") return;
    handleSaveData({ status: "canceled" });
  };

  const handleCancelRechargeConfirm = async () => {
    try {
      const confirm = window.prompt("Deseja realmente cancelar esta recarga? Escreva CONFIRMAR para prosseguir.");
      if (confirm !== "CONFIRMAR") return;
      setLoadingCancelRecharge(true);
      await api.delete(`/orders/${_id}/recharge`);
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingCancelRecharge(false);
    }
  };

  return (
    <OrdersDetailsContext.Provider value={{ data }}>
      <HStack mb="20px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/orders">
              Pedidos
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <HStack>
          {data?.status !== "paid" && data?.payment?.method !== "deposit" && (
            <Button size="sm" colorScheme="purple" leftIcon={<Icon as={TbWebhook} />} isLoading={isLoadingWebhook} onClick={handleWebhook}>
              Webhook
            </Button>
          )}
          {data?.status === "paid" && currentClaims?.role === "admin" && (
            <Button
              size="sm"
              fontSize="sm"
              colorScheme="red"
              variant="ghost"
              leftIcon={<Icon as={MdOutlineCancel} />}
              isLoading={isLoading}
              onClick={handleUpdateCanceledConfirm}
            >
              Cancelar pedido
            </Button>
          )}
          {data?.payment?.method === "deposit" && !data?.closedAt && (
            <Button
              size="sm"
              fontSize="sm"
              colorScheme="green"
              leftIcon={<Icon as={MdOutlineCheck} />}
              isLoading={isLoading}
              onClick={() => handleSaveData({ status: "paid" })}
            >
              Confirmar pagamento
            </Button>
          )}
          {data?.type === "physical" && (
            <Box>
              <Menu>
                <MenuButton
                  as={Button}
                  variant="outline"
                  size="sm"
                  leftIcon={<Icon as={MdDeliveryDining} />}
                  rightIcon={<Icon as={MdArrowDropDown} />}
                  isLoading={isLoading}
                  isDisabled={data?.status !== "paid"}
                >
                  Entrega
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup
                    type="radio"
                    value={data?.shipping?.status}
                    onChange={(status) => handleSaveData({ shipping: { status } })}
                  >
                    <MenuItemOption value="manufacturing">Produzindo cartão</MenuItemOption>
                    <MenuItemOption value="delivering">Saiu para entrega</MenuItemOption>
                    <MenuItemOption value="delivered">Pedido entregue</MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </Box>
          )}
          <Box>
            <Menu>
              <MenuButton
                as={Button}
                variant="outline"
                size="sm"
                leftIcon={<Icon as={MdPrint} />}
                rightIcon={<Icon as={MdArrowDropDown} />}
              >
                Imprimir
              </MenuButton>
              <MenuList>
                <ReactToPrint trigger={() => <MenuItem>Detalhamento pedido</MenuItem>} content={() => printableMainRef.current} />
                <ReactToPrint trigger={() => <MenuItem>Ordem de entrega</MenuItem>} content={() => printableDeliveryRef.current} />
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </HStack>

      <VStack ref={printableMainRef} align="stretch" divider={<StackDivider />} p={5} spacing={6}>
        <Box>
          <Text fontWeight="bold" mb={4}>
            Transação
          </Text>
          <HStack justify="space-between" spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs">ID Pedido</Text>
              <Text>{data?._id}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">NID Pedido</Text>
              <Text>{data?.nid}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Status</Text>
              <Text>
                <StatusBadge label={data?.status ?? ""} />
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs">Método</Text>
              <Text>{translator(data?.payment?.method)}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Valor</Text>
              <Text fontWeight="bold">{currency.format(data?.amount ?? 0)}</Text>
            </Box>
          </HStack>

          {_.size(data?.errs) > 0 && (
            <>
              <Text fontWeight="bold" my={4}>
                Falhas
              </Text>
              <VStack align="stretch">
                {_.map(data?.errs, (item) => (
                  <Alert status="error" borderRadius="lg">
                    <AlertDescription>
                      [{moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}] {item.message}
                    </AlertDescription>
                  </Alert>
                ))}
              </VStack>
            </>
          )}
        </Box>

        <Box>
          <Text fontWeight="bold" mb={4}>
            Itens
          </Text>
          <HStack>
            {_.map(data?.items, (item) => (
              <Box flex={1} p="20px" borderRadius="lg" borderWidth="1px" key={item.id}>
                <HStack>
                  <Text fontSize="xs">{translator(item.id)}</Text>
                  {["recharge", "reissue"].indexOf(item.id) !== -1 && (
                    <Text fontSize="xs" fontWeight="bold">
                      ({masks.cardNumber(data?.cardNumber ?? "")})
                    </Text>
                  )}
                </HStack>
                <Text fontWeight="bold">{currency.format(item.amount)}</Text>
              </Box>
            ))}
          </HStack>
        </Box>

        {data?.payment?.method === "boleto" && (
          <Box>
            <HStack mb={4} justify="space-between">
              <Text fontWeight="bold">Boleto</Text>
              <Button
                size="sm"
                variant="ghost"
                colorScheme="main"
                leftIcon={<Icon as={MdOpenInNew} />}
                onClick={() => window.open(data?.payment?.boleto?.url)}
              >
                Abrir PDF
              </Button>
            </HStack>
            <SimpleGrid columns={2} spacing={4} mb={4}>
              <Box>
                <Text fontSize="xs">Linha digitável</Text>
                <Text fontWeight="bold">{data?.payment?.boleto?.line}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Vencimento</Text>
                <Text>{moment(data?.payment?.boleto?.dueAt).format("DD/MM/YYYY")}</Text>
              </Box>
            </SimpleGrid>
          </Box>
        )}

        {data?.payment?.method === "pix" && (
          <Box>
            <HStack mb={4} justify="space-between">
              <Text fontWeight="bold">Pix</Text>
              <Button
                size="sm"
                variant="ghost"
                colorScheme="main"
                leftIcon={<Icon as={MdOpenInNew} />}
                onClick={() => window.open(data?.payment?.pix?.qrCodeUrl)}
              >
                Abrir QRCode
              </Button>
            </HStack>
            <SimpleGrid columns={2} spacing={4} mb={4}>
              <Box>
                <Text fontSize="xs">QRCode</Text>
                <Text fontSize="xs" fontWeight="bold">
                  {data?.payment?.pix?.qrCode}
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs">Expiração</Text>
                <Text>{moment(data?.payment?.pix?.expiresAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
              </Box>
            </SimpleGrid>
          </Box>
        )}

        {data?.payment?.method === "credit_card" && (
          <Box>
            <Text fontWeight="bold" mb={4}>
              Cartão de Crédito
            </Text>
            <SimpleGrid columns={4} spacing={4}>
              <Box>
                <Text fontSize="xs">Número</Text>
                <Text fontWeight="bold">**** **** **** {data?.payment?.creditCard?.lastFourDigits}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Nome do titular</Text>
                <Text>{data?.payment?.creditCard?.holderName}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Bandeira</Text>
                <Text>{data?.payment?.creditCard?.brand}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">TID</Text>
                <Text>{data?.payment?.creditCard?.acquirerTid}</Text>
              </Box>
            </SimpleGrid>
          </Box>
        )}

        {data?.payment?.method === "deposit" && (
          <Box>
            <Text fontWeight="bold" mb={4}>
              Depósito
            </Text>
            <HStack spacing={6}>
              <Image
                src={data?.payment?.deposit?.attach?.transforms?.original?.location}
                maxWidth={60}
                maxHeight={60}
                borderRadius="lg"
                cursor="pointer"
                onClick={() => setIsOpenImageViewer(true)}
              />
              <VStack align="stretch" flex={1} columns={4} spacing={4}>
                <Box>
                  <Text fontSize="xs">Tipo</Text>
                  <Text fontWeight="bold">{data?.payment?.deposit?.attach?.type}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs">Tamanho</Text>
                  <Text fontWeight="bold">{(data?.payment?.deposit?.attach?.transforms?.original?.size ?? 0) / 1000}kb</Text>
                </Box>
                <Box>
                  <Text fontSize="xs">Chave</Text>
                  <Text fontSize="sm">{data?.payment?.deposit?.attach?.transforms?.original?.key}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs">Localização</Text>
                  <Text fontSize="sm">{data?.payment?.deposit?.attach?.transforms?.original?.location}</Text>
                </Box>
              </VStack>
            </HStack>
            <ImageViewer
              src={data?.payment?.deposit?.attach?.transforms?.original?.location}
              isOpen={isOpenImageViewer}
              onClose={() => setIsOpenImageViewer(false)}
            />
          </Box>
        )}

        {data?.recharge && (
          <Box>
            <HStack mb={4} justify="space-between">
              <Text fontWeight="bold">Recarga</Text>
              {data?.recharge?.status === "failed" && (
                <Box>
                  <Menu>
                    <MenuButton
                      as={Button}
                      size="sm"
                      colorScheme="main"
                      rightIcon={<Icon as={MdKeyboardArrowDown} />}
                      isLoading={loadingSaveRecharge}
                    >
                      Alterar Status
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => handleUpdateRechargeStatus("success")}>Sucesso</MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={() => handleUpdateRechargeStatus("pending")}>Pendente</MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              )}
              {data?.recharge?.status === "success" && currentClaims?.role === "admin" && (
                <Button
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  leftIcon={<Icon as={MdOutlineCancel} />}
                  isLoading={loadingCancelRecharge}
                  onClick={handleCancelRechargeConfirm}
                >
                  Cancelar recarga
                </Button>
              )}
            </HStack>
            <SimpleGrid columns={5} spacing={4}>
              <Box>
                <Text fontSize="xs">Status</Text>
                <Text>
                  <StatusBadge label={data?.recharge?.status ?? ""} />
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs">Cartão</Text>
                <Text>{masks.cardNumber(data?.recharge?.cardNumber ?? "")}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">NID recarga</Text>
                <Text>{data?.recharge?.nid}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">ID estocagem</Text>
                <Text>{data?.recharge?.storage?.id ?? "-"}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Data estocagem</Text>
                <Text>
                  {data?.recharge?.storage?.createdAt ? moment(data?.recharge?.storage?.createdAt).format("DD/MM/YYYY [às] HH:mm:ss") : "-"}
                </Text>
              </Box>
            </SimpleGrid>

            {_.size(data?.recharge?.errs) > 0 && (
              <>
                <Text fontWeight="bold" my={4}>
                  Falhas
                </Text>
                <VStack align="stretch">
                  {_.map(data?.recharge?.errs, (item) => (
                    <Alert status="error" borderRadius="lg">
                      <AlertDescription>
                        [{moment(item.createdAt).format("DD/MM/YYYY [às] HH:mm:ss")}] {item.message}
                      </AlertDescription>
                    </Alert>
                  ))}
                </VStack>
              </>
            )}
          </Box>
        )}

        <Box>
          <Text fontWeight="bold" mb={4}>
            Cliente
          </Text>
          <SimpleGrid columns={3} spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs">Nome do cliente</Text>
              <Text>{data?.user?.name}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Documento</Text>
              <Text>{masks.document(data?.user?.document ?? "")}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Nascimento</Text>
              <Text>{moment(data?.user?.birth).format("DD/MM/YYYY")}</Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid columns={3} spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs">Telefone</Text>
              <Text>{masks.phone(data?.user?.phone?.replace("55", ""))}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">E-mail</Text>
              <Text>{data?.user?.email}</Text>
            </Box>
          </SimpleGrid>
        </Box>

        <Box>
          <Text fontWeight="bold" mb={4}>
            Endereço
          </Text>
          <SimpleGrid columns={3} spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs">CEP</Text>
              <Text>{data?.address?.zipCode}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Rua</Text>
              <Text>{data?.address?.street}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Número</Text>
              <Text>{data?.address?.number}</Text>
            </Box>
          </SimpleGrid>
          <SimpleGrid columns={3} spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs">Complemento</Text>
              <Text>{data?.address?.complement}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Bairro</Text>
              <Text>{data?.address?.neighborhood}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Cidade</Text>
              <Text>{data?.address?.city}</Text>
            </Box>
          </SimpleGrid>
        </Box>

        <Box>
          <Text fontWeight="bold" mb={4}>
            Plataforma
          </Text>
          <HStack justify="space-between" spacing={4} mb={4}>
            <Box>
              <Text fontSize="xs">Marca</Text>
              <Text>{data?.platform?.brand ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Modelo</Text>
              <Text>{data?.platform?.model ?? "-"}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Sistema Operacional</Text>
              <Text>{data?.platform?.os}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Versão do Sistema Operacional</Text>
              <Text>{data?.platform?.osVersion}</Text>
            </Box>
            <Box>
              <Text fontSize="xs">Versão do Aplicativo</Text>
              <Text>{data?.platform?.appVersion}</Text>
            </Box>
          </HStack>
        </Box>

        {data?.location && (
          <Box mb={6} borderRadius="lg" overflow="hidden">
            <iframe
              title="Localização"
              width="100%"
              height="300px"
              frameBorder="0"
              style={{ border: 0 }}
              src={`https://www.google.com/maps?q=${data?.location?.latitude},${data?.location?.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
              allowFullScreen
            ></iframe>
          </Box>
        )}

        <Box>
          <FormControl>
            <FormLabel>Observações</FormLabel>
            <HStack alignItems="stretch">
              <Textarea
                value={formData.comments || ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
              <Box>
                <IconButton
                  h="100%"
                  colorScheme="main"
                  icon={<Icon as={MdSave} />}
                  onClick={() => handleSaveData({ comments: formData.comments })}
                  isLoading={isLoading}
                />
              </Box>
            </HStack>
          </FormControl>
        </Box>
      </VStack>
      <PrintableDelivery ref={printableDeliveryRef} />
    </OrdersDetailsContext.Provider>
  );
};
