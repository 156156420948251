import React from "react";
import { Text, Tooltip, IconButton, Icon, HStack } from "@chakra-ui/react";
import { MdFirstPage, MdLastPage, MdChevronLeft, MdChevronRight } from "react-icons/md";

export const Paginator = ({ loading, page, size, perPage, onPrev, onNext, onPaginate, onFirst, onLast }) => {
  function displayingUpTo() {
    let value = (page + 1) * perPage;
    if (value > size) return size;
    return value;
  }

  function getLastPage() {
    if (perPage === -1) return 1;
    return Math.ceil(size / Math.abs(perPage));
  }

  function handleFirst() {
    if (onFirst) onFirst(0);
    handlePaginate(0);
  }

  function handlePrev() {
    if (onPrev) onPrev(page - 1);
    handlePaginate(page - 1);
  }

  function handleNext() {
    if (onNext) onNext(page + 1);
    handlePaginate(page + 1);
  }

  function handleLast() {
    if (onLast) onLast(Math.round(size / perPage) - 1);
    handlePaginate(Math.round(size / perPage) - 1);
  }

  function handlePaginate(_page) {
    if (!onPaginate) return;
    onPaginate(_page);
  }

  return (
    <HStack spacing={1}>
      <Text fontSize="xs">
        Exibindo {perPage === -1 ? size : `${page * perPage + 1} a ${displayingUpTo()}`} de {size} resultados
      </Text>
      <Tooltip label="Primeira">
        <IconButton icon={<Icon as={MdFirstPage} />} isDisabled={loading || page === 0 || !size} onClick={handleFirst} colorScheme="main" />
      </Tooltip>
      <Tooltip label="Anterior">
        <IconButton
          icon={<Icon as={MdChevronLeft} />}
          isDisabled={loading || page === 0 || !size}
          onClick={handlePrev}
          colorScheme="main"
        />
      </Tooltip>

      <Text fontSize="xs" fontWeight="bold">
        {page + 1 || 0}
      </Text>
      <Text fontSize="xs">de</Text>
      <Text fontSize="xs" fontWeight="bold">
        {getLastPage() || 0}
      </Text>

      <Tooltip label="Próxima">
        <IconButton
          icon={<Icon as={MdChevronRight} />}
          isDisabled={loading || page + 1 === getLastPage() || !size}
          onClick={handleNext}
          colorScheme="main"
        />
      </Tooltip>
      <Tooltip label="Última">
        <IconButton
          icon={<Icon as={MdLastPage} />}
          isDisabled={loading || page + 1 === getLastPage() || !size}
          onClick={handleLast}
          colorScheme="main"
        />
      </Tooltip>
    </HStack>
  );
};
