import React, { useContext } from "react";
import _ from "lodash";
import { VStack, HStack, Box, Text, StackDivider, Icon, IconButton } from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import { UsersContext } from "./index";

const Addresses = () => {
  const { formData, setFormData } = useContext(UsersContext);

  const handleChangePrimary = (index) => {
    setFormData((state) => ({
      ...state,
      addresses: _.map(state.addresses, (o, _index) => ({
        ...o,
        primary: index === _index,
      })),
    }));
  };

  return (
    <VStack align="stretch" divider={<StackDivider />} spacing={6}>
      {_.map(formData.addresses, (item, index) => (
        <HStack key={item._id} justify="space-between">
          <Box>
            <Text fontWeight="bold">{item.name}</Text>
            <Text fontSize="xs">{item.line1}</Text>
            {item.line2?.length > 0 && <Text fontSize="xs">{item.line2}</Text>}
          </Box>
          <IconButton
            size="xs"
            colorScheme={item.primary ? "green" : "gray"}
            icon={<Icon as={MdCheck} />}
            onClick={() => handleChangePrimary(index)}
          />
        </HStack>
      ))}
    </VStack>
  );
};

export default Addresses;
