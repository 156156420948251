import { FiShoppingBag } from "react-icons/fi";
import { BiUser, BiShapePolygon, BiMapPin } from "react-icons/bi";
import { RiRouteLine, RiQuestionLine } from "react-icons/ri";
import { HiVariable, HiOutlineDocumentText } from "react-icons/hi";
import { BiNotification } from "react-icons/bi";
import { IoChatbubblesOutline, IoNotificationsOutline } from "react-icons/io5";
import { BsChatSquareText } from "react-icons/bs";
import { MdOutlineWavingHand } from "react-icons/md";
import { AiOutlineLock, AiOutlineUserSwitch } from "react-icons/ai";
import { FaListOl } from "react-icons/fa";

export const sidebarItems = [
  {
    id: Math.random(),
    items: [
      {
        id: Math.random(),
        icon: BiUser,
        text: "Usuários",
        to: "/users",
      },
      {
        id: Math.random(),
        icon: RiRouteLine,
        text: "Linhas",
        to: "/lines",
      },
      {
        id: Math.random(),
        icon: BiShapePolygon,
        text: "Zonas",
        to: "/zones",
      },
      {
        id: Math.random(),
        icon: BiMapPin,
        text: "Pontos de venda",
        to: "/outlets",
      },
      {
        id: Math.random(),
        icon: FiShoppingBag,
        text: "Pedidos",
        to: "/orders",
      },
      {
        id: Math.random(),
        icon: BsChatSquareText,
        text: "Atendimentos",
        items: [
          {
            id: Math.random(),
            text: "Listagem",
            to: "/attendances",
          },
          {
            id: Math.random(),
            text: "Assuntos",
            to: "/attendance-subjects",
          },
          {
            id: Math.random(),
            text: "Mensagens rápidas",
            to: "/attendance-quick-messages",
          },
        ],
      },
      {
        id: Math.random(),
        icon: IoChatbubblesOutline,
        text: "Chat",
        to: "/chat",
      },
      {
        id: Math.random(),
        icon: IoNotificationsOutline,
        text: "Notificações",
        to: "/notifications",
      },
      {
        id: Math.random(),
        icon: FaListOl,
        text: "Listas de contato",
        to: "/contact-lists",
      },
      {
        id: Math.random(),
        icon: MdOutlineWavingHand,
        text: "Saudações",
        to: "/greetings",
      },
      {
        id: Math.random(),
        icon: RiQuestionLine,
        text: "FAQ",
        to: "/faq",
      },
    ],
  },
  {
    id: Math.random(),
    title: "Configurações",
    items: [
      {
        id: Math.random(),
        icon: BiNotification,
        text: "Templates",
        to: "/templates",
      },
      {
        id: Math.random(),
        icon: HiVariable,
        text: "Ambiente",
        to: "/environment",
      },
      {
        id: Math.random(),
        icon: HiOutlineDocumentText,
        text: "Termos",
        to: "/terms",
      },
    ],
  },
  {
    id: Math.random(),
    title: "Integrações",
    items: [
      {
        id: Math.random(),
        icon: AiOutlineUserSwitch,
        text: "Clientes",
        to: "/customers",
      },
      {
        id: Math.random(),
        icon: AiOutlineLock,
        text: "Autenticação",
        to: "/auths",
      },
    ],
  },
];
