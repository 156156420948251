import React, { useState, useEffect } from "react";
import _ from "lodash";
import { IconButton, Box, HStack, Text, Icon } from "@chakra-ui/react";
import { MdArrowBack, MdDelete, MdRefresh } from "react-icons/md";
import { api } from "lib";

export const Cacher = () => {
  const [data, setData] = useState({});
  const [stats, setStats] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDeleteData, setLoadingDeleteData] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [key, setKey] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setLoadingData(true);
        const data = await api.get("/cacher", { params: { key } });
        if (data?.stats) setStats(data.stats);
        setData(data);
      } finally {
        setLoadingData(false);
      }
    })();
  }, [key, timestamp]);

  const handleFlushAll = () => {
    const flushAll = async () => {
      try {
        setLoadingDeleteData(true);
        await api.delete("/cacher");
        setTimestamp(true);
      } finally {
        setLoadingDeleteData(false);
      }
    };
    if (window.confirm("Deseja realmente limpar o cache do servidor?")) flushAll();
  };

  return (
    <>
      <HStack p={3}>
        <IconButton
          size="sm"
          colorScheme="main"
          icon={<Icon as={MdArrowBack} />}
          onClick={() => setKey("")}
          isLoading={loadingData}
          isDisabled={key?.length === 0}
        />
        <Text flex={1} fontWeight="bold" fontSize="lg">
          Cache Monitoring
        </Text>
        <HStack>
          <IconButton size="sm" colorScheme="red" icon={<Icon as={MdDelete} />} onClick={handleFlushAll} isLoading={loadingDeleteData} />
          <IconButton
            size="sm"
            colorScheme="main"
            icon={<Icon as={MdRefresh} />}
            onClick={() => setTimestamp(Date.now())}
            isLoading={loadingData}
          />
        </HStack>
      </HStack>
      <Box m={3} p={6} backgroundColor="gray.100" borderRadius="lg">
        <pre>{JSON.stringify(stats, null, 2)}</pre>
      </Box>
      <Box m={3} p={6} backgroundColor="gray.100" borderRadius="lg">
        {data?.keys ? _.map(data?.keys, (key) => <div onClick={() => setKey(key)}>{key}</div>) : <pre>{JSON.stringify(data, null, 2)}</pre>}
      </Box>
    </>
  );
};
