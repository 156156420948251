import { useState, useCallback, useEffect } from "react";

const defaultParams = {};

export const useSocketGet = ({ socket, event, params = defaultParams }) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      socket.emit(event, params, function (data) {
        setResponse(data);
        setLoading(false);
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [socket, event, params, timestamp]);

  const refresh = useCallback(() => setTimestamp(Date.now()), []);

  return [response, loading, refresh];
};
