import React, { useState, useEffect } from "react";
import { Text, Stack, Icon, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { MdSignalWifiOff, MdRefresh } from "react-icons/md";
import { eventEmitter } from "lib";

export const NetworkError = () => {
  const [loading, setLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    eventEmitter.subscribe("onNetworkError", () => setNetworkError(true));
  }, []);

  const handleReload = () => {
    setLoading(true);
    window.location.reload();
  };

  return (
    <Modal isOpen={networkError} onClose={() => setNetworkError(false)} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="30px">
          <Stack align="center">
            <Icon as={MdSignalWifiOff} boxSize={40} />
            <Text fontSize="lg" fontWeight="bold">
              Sem conexão
            </Text>
            <Text fontSize="xs" textAlign="center" marginBottom="20px">
              Não foi possível estabelecer a comunicação com o servidor. Por favor, verifique sua conexão.
            </Text>
            <Button onClick={handleReload} leftIcon={<Icon as={MdRefresh} />} isLoading={loading}>
              Tentar novamente
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
