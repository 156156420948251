import React, { useState, useEffect } from "react";
import { getAuth, signOut } from "@firebase/auth";
import { Text, Stack, Icon, Modal, ModalOverlay, ModalContent, ModalBody, Button } from "@chakra-ui/react";
import { MdLockOutline, MdLockOpen } from "react-icons/md";
import { eventEmitter } from "lib";

export const InvalidAuthorization = () => {
  const [invalidAuthorization, setInvalidAuthorization] = useState(false);

  useEffect(() => {
    eventEmitter.subscribe("onAuthorizationError", (error) => setInvalidAuthorization(error));
  }, []);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    setInvalidAuthorization(false);
  };

  return (
    <Modal isOpen={invalidAuthorization.message} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding="30px">
          <Stack align="center">
            <Icon as={MdLockOutline} boxSize={40} />
            <Text fontSize="lg" fontWeight="bold">
              Autorização inválida
            </Text>
            <Text fontSize="xs" textAlign="center" marginBottom="20px">
              {invalidAuthorization.message}
            </Text>
            <Button colorScheme="main" leftIcon={<Icon as={MdLockOpen} />} onClick={handleSignOut}>
              Realizar novo login
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
