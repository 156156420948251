import { useState, useCallback } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";
import api from "lib/api";

const defaults = {
  params: {},
  options: { isNil: true },
};

export const useApiGet = (url, params = defaults.params, options = defaults.options) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timestamp, setTimestamp] = useState(Date.now());

  useDeepCompareEffect(() => {
    const isValidUrl = () => {
      const path = url.split("/");
      const filtered = _.filter(path, (o) => ["undefined", "null"].indexOf(o) === -1);
      if (!options.isNil) return true;
      if (path.length === filtered.length) return true;
      return false;
    };
    (async () => {
      try {
        setLoading(true);
        if (isValidUrl()) {
          const response = await api.get(url, { params });
          setResponse(response);
          setError(null);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [url, params, options, timestamp]);

  const refresh = useCallback(() => setTimestamp(Date.now()), []);

  return [response, loading, refresh, error];
};
