import React, { useContext } from "react";
import { FormControl, FormLabel, Input, InputGroup, SimpleGrid, InputLeftAddon, FormErrorMessage } from "@chakra-ui/react";
import { CurrencyInput } from "components";
import { EnvironmentContext } from "./index";

const Prices = () => {
  const { formData, setFormData, formErrors } = useContext(EnvironmentContext);

  const handleChange = (value) => setFormData((state) => ({ ...state, prices: { ...state.prices, ...value } }));

  return (
    <>
      <SimpleGrid columns={[1, 2]} spacing={4}>
        <FormControl isRequired={true} isInvalid={formErrors["prices.issue"]}>
          <FormLabel>Emissão de cartão</FormLabel>
          <InputGroup>
            <InputLeftAddon>R$</InputLeftAddon>
            <Input
              as={CurrencyInput}
              allowNegative={true}
              decimalSeparator=","
              thousandSeparator="."
              precision="2"
              value={formData.prices?.issue ?? 0}
              onChangeEvent={(_, __, floatValue) => handleChange({ issue: floatValue })}
              autoFocus={false}
            />
          </InputGroup>
          <FormErrorMessage>{formErrors["prices.issue"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["prices.reissue"]}>
          <FormLabel>Reemissão de cartão</FormLabel>
          <InputGroup>
            <InputLeftAddon>R$</InputLeftAddon>
            <Input
              as={CurrencyInput}
              allowNegative={true}
              decimalSeparator=","
              thousandSeparator="."
              precision="2"
              value={formData.prices?.reissue ?? 0}
              onChangeEvent={(_, __, floatValue) => handleChange({ reissue: floatValue })}
              autoFocus={false}
            />
          </InputGroup>
          <FormErrorMessage>{formErrors["prices.reissue"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default Prices;
