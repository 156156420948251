import React, { useState, useEffect, createContext, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { messages } from "consts";
import { validationErrorHandler, api } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdChevronLeft } from "react-icons/md";
import Messages from "./messages";
import Prices from "./prices";
import Services from "./services";
import Taxes from "./taxes";
import BankData from "./bankData";

export const EnvironmentContext = createContext();

export const Environment = () => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet("/environment");
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();
  const tabRefs = {
    messages: useRef(),
    services: useRef(),
    prices: useRef(),
    taxes: useRef(),
  };

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const taxeSchema = yup.object().shape({
        isPercent: yup.boolean().required(messages.error.required),
        value: yup.number().required(messages.error.required),
        min: yup.number().required(messages.error.required),
        max: yup.number().required(messages.error.required),
      });
      const schema = yup.object().shape({
        messages: yup.object().shape({
          unavailableRealTime: yup.string().required(messages.error.required),
          unavailableDynamicRecharge: yup.string().required(messages.error.required),
          paymentSuccessCreditCard: yup.string().required(messages.error.required),
          paymentWaitingCreditCard: yup.string().required(messages.error.required),
          paymentErrorCreditCard: yup.string().required(messages.error.required),
          paymentSuccessPix: yup.string().required(messages.error.required),
          paymentWaitingPix: yup.string().required(messages.error.required),
          paymentErrorPix: yup.string().required(messages.error.required),
          paymentWaitingBoleto: yup.string().required(messages.error.required),
          paymentErrorBoleto: yup.string().required(messages.error.required),
          paymentWaitingDeposit: yup.string().required(messages.error.required),
          paymentMethodSelectCreditCard: yup.string().required(messages.error.required),
          paymentMethodSelectPix: yup.string().required(messages.error.required),
          paymentMethodSelectBoleto: yup.string().required(messages.error.required),
          paymentMethodSelectDeposit: yup.string().required(messages.error.required),
        }),
        taxes: yup.object().shape({
          delivery: taxeSchema,
          boleto: taxeSchema,
          credit_card: taxeSchema,
          deposit: taxeSchema,
          pix: taxeSchema,
        }),
        prices: yup.object().shape({
          reissue: yup.number().required(messages.error.required),
          issue: yup.number().required(messages.error.required),
        }),
        services: yup.object().shape({
          isRealtimeActive: yup.boolean().required(messages.error.required),
          isDynamicRechargeActive: yup.boolean().required(messages.error.required),
          siumobileApiUrl: yup.string().required(messages.error.required),
        }),
        bankData: yup.object().shape({
          bankName: yup.string().required(messages.error.required),
          agency: yup.string().required(messages.error.required),
          accountType: yup.string().required(messages.error.required),
          accountNumber: yup.string().required(messages.error.required),
          holderDocument: yup.string().required(messages.error.required),
          holderName: yup.string().required(messages.error.required),
        }),
      });
      await schema.validate(formData, { abortEarly: false });
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
      const [firstKey] = Object.keys(formErrors);
      if (firstKey) {
        const [path] = firstKey.split(".") ?? [];
        (tabRefs[path] ?? tabRefs.messages).current?.focus();
      }
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      await api.put("/environment", data);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
      refreshData();
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <EnvironmentContext.Provider value={{ formData, setFormData, formErrors }}>
      <HStack marginBottom="20px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>{loadingData ? <Spinner size="xs" /> : "Ambiente"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Text fontSize="xs" fontWeight="bold">
          v{formData?.version}
        </Text>
      </HStack>

      <Tabs isFitted variant="enclosed">
        <TabList>
          <Tab fontSize="xs" ref={tabRefs.messages}>
            Mensagens
          </Tab>
          <Tab fontSize="xs" ref={tabRefs.services}>
            Serviços
          </Tab>
          <Tab fontSize="xs" ref={tabRefs.prices}>
            Preços
          </Tab>
          <Tab fontSize="xs" ref={tabRefs.taxes}>
            Taxas
          </Tab>
          <Tab fontSize="xs" ref={tabRefs.bank}>
            Dados bancários
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Messages />
          </TabPanel>
          <TabPanel>
            <Services />
          </TabPanel>
          <TabPanel>
            <Prices />
          </TabPanel>
          <TabPanel>
            <Taxes />
          </TabPanel>
          <TabPanel>
            <BankData />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <form onSubmit={handleSubmit}>
        <HStack justify="flex-end" spacing={4} mt={6}>
          <Button as={RouterLink} to="/settings" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </EnvironmentContext.Provider>
  );
};
