import React, { useState, useEffect, createContext } from "react";
import { getAuth, onAuthStateChanged, getIdTokenResult, signOut } from "@firebase/auth";
import { Private, Public } from "pages";
import { Preloader } from "components";

export const AppContext = createContext();

const App = () => {
  const [currentUser, setCurrentUser] = useState();
  const [currentClaims, setCurrentClaims] = useState();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      try {
        await user.reload();
        const { claims } = await getIdTokenResult(user, true);
        setCurrentClaims(claims);
        if (claims.role !== "customer") return;
        signOut(auth);
      } finally {
        setCurrentUser(user);
      }
    });
  }, []);

  return (
    <AppContext.Provider value={{ currentUser, currentClaims }}>
      {currentUser === null ? <Public /> : currentUser?.uid && currentClaims?.role ? <Private /> : <Preloader />}
    </AppContext.Provider>
  );
};

export default App;
