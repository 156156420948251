import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  SimpleGrid,
  Spinner,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { messages } from "consts";
import { validationErrorHandler } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { CurrencyInput } from "components";
import { MdSave, MdChevronLeft } from "react-icons/md";
import api from "lib/api";

export const OutletsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(`/outlets/${_id}`);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        phone: yup.string().required(messages.error.required),
        description: yup.string().required(messages.error.required),
        coords: yup.object().shape({
          lat: yup.number().required(messages.error.required),
          lng: yup.number().required(messages.error.required),
        }),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) await api.put(`/outlets/${_id}`, data);
      else {
        const saved = await api.post("/outlets", data);
        navigate(`/outlets/details/${saved._id}`, { replace: true });
      }
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <Box>
      <HStack marginBottom="20px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/outlets">
              Pontos de venda
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <form onSubmit={handleSubmit}>
        <SimpleGrid spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors.name}>
            <FormLabel>Nome</FormLabel>
            <Input value={formData.name ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, name: target.value }))} />
            <FormErrorMessage>{formErrors.name}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid spacing={4} mb={4} columns={[1, 3]}>
          <FormControl isRequired={true} isInvalid={formErrors.phone}>
            <FormLabel>Telefone</FormLabel>
            <Input
              as={InputMask}
              mask="9999-9999?"
              formatChars={{ 9: "[0-9]", "?": "[0-9 ]" }}
              maskChar={null}
              value={formData.phone ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
            />
            <FormErrorMessage>{formErrors.phone}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors["coords.lat"]}>
            <FormLabel>Latitude</FormLabel>
            <Input
              as={CurrencyInput}
              allowNegative={true}
              decimalSeparator=","
              thousandSeparator="."
              precision="7"
              value={formData.coords?.lat ?? 0}
              onChangeEvent={(_, __, lat) => setFormData((state) => ({ ...state, coords: { ...state.coords, lat } }))}
              autoFocus={false}
            />
            <FormErrorMessage>{formErrors["coords.lat"]}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired={true} isInvalid={formErrors["coords.lng"]}>
            <FormLabel>Longitude</FormLabel>
            <Input
              as={CurrencyInput}
              allowNegative={true}
              decimalSeparator=","
              thousandSeparator="."
              precision="7"
              value={formData.coords?.lng ?? 0}
              onChangeEvent={(_, __, lng) => setFormData((state) => ({ ...state, coords: { ...state.coords, lng } }))}
              autoFocus={false}
            />
            <FormErrorMessage>{formErrors["coords.lng"]}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors.description}>
            <FormLabel>Descrição</FormLabel>
            <Textarea
              value={formData.description ?? ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, description: target.value }))}
            />
            <FormErrorMessage>{formErrors.description}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <HStack justify="flex-end" spacing={4} mt={6}>
          <Button as={RouterLink} to="/outlets" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </Box>
  );
};
