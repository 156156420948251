import React, { useState, useContext, useEffect } from "react";
import { Avatar, Tooltip } from "@chakra-ui/react";
import { ChatContext } from "../contexts";

export const Author = ({ sender }) => {
  const { selectedAttendance, setSelectedMember } = useContext(ChatContext);
  const [member, setMember] = useState({});

  useEffect(() => {
    const member = selectedAttendance.members.find((o) => o.user?._id === sender) ?? {};
    setMember(member);
  }, [sender, selectedAttendance?.members]);

  return (
    <Tooltip label={member?.user?.name}>
      <Avatar size="sm" cursor="pointer" name={member?.user?.name} onClick={() => setSelectedMember(member)} />
    </Tooltip>
  );
};
