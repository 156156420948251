import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { messages } from "consts";
import { ImageLibrary } from "containers";
import * as yup from "yup";
import { MdAttachFile, MdEdit } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";

const Content = ({ data, onChange, isTitleRequired = true }) => {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenImageLibrary, setIsOpenImageLibrary] = useState(false);

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const shape = { body: yup.string().required(messages.error.required) };
      if (isTitleRequired) shape.title = yup.string().required(messages.error.required);
      const schema = yup.object().shape(shape);
      await schema.validate(formData);
      onChange(formData);
      setIsOpen(false);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  return (
    <>
      <IconButton icon={<Icon as={MdEdit} />} onClick={() => setIsOpen(true)} size="sm" />
      <Modal isCentered scrollBehavior="inside" size="6xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <form onSubmit={handleSubmit}>
          <ModalContent>
            <ModalHeader>Finalizar atendimento</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb="20px">
                {formData.img && (
                  <Box width="300px">
                    <Image src={formData.img?.transforms?.original?.location} alt={formData.img?.name} borderRadius="lg" />
                  </Box>
                )}
                <HStack>
                  <Button colorScheme="main" onClick={() => setIsOpenImageLibrary(true)} leftIcon={<Icon as={MdAttachFile} />}>
                    Selecionar imagem
                  </Button>
                  {formData.img && (
                    <Button onClick={() => setFormData((state) => ({ ...state, img: null }))} leftIcon={<Icon as={IoMdTrash} />}>
                      Remover imagem
                    </Button>
                  )}
                </HStack>
              </VStack>

              <SimpleGrid spacing={4} mb={4}>
                <FormControl isRequired={isTitleRequired} isInvalid={formErrors.title}>
                  <FormLabel>Título</FormLabel>
                  <Input
                    value={formData.title ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.title}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid spacing={4} mb={4}>
                <FormControl isRequired={true} isInvalid={formErrors.body}>
                  <FormLabel>Conteúdo</FormLabel>
                  <Textarea
                    rows={10}
                    value={formData.body ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, body: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.body}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => setIsOpen(false)}>
                Cancelar
              </Button>
              <Button type="submit" colorScheme="main">
                Finalizar
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
      <ImageLibrary
        isOpen={isOpenImageLibrary}
        onClose={() => setIsOpenImageLibrary(false)}
        onFinish={([img]) => setFormData((state) => ({ ...state, img }))}
      />
    </>
  );
};

export default Content;
