import React, { useState } from "react";
import moment from "moment";
import { Box, Text, Icon, Flex, useColorModeValue, Alert } from "@chakra-ui/react";
import { FiClock } from "react-icons/fi";
import { Author } from "./author";

export const Message = ({ currentUser, currentMessage, nextMessage }) => {
  const itsMe = currentUser.uid === currentMessage.sender;
  const itsLast = nextMessage.sender !== currentMessage.sender;
  const backgroundColor = useColorModeValue("gray.100", "gray.700");
  const [pressed, setPressed] = useState(false);

  if (!currentMessage.sender)
    return (
      <Flex direction="column" alignItems="center" justifyContent="center" pb="10px">
        {pressed && (
          <Text fontSize="xs" fontWeight="bold">
            {moment(currentMessage.timestamp).format("DD [de] MMM HH:mm").toUpperCase()}
          </Text>
        )}
        <Alert w="auto" status="info" fontSize="xs" borderRadius="lg" cursor="pointer" onClick={() => setPressed((state) => !state)}>
          {currentMessage.body}
        </Alert>
      </Flex>
    );

  return (
    <Flex alignItems="flex-end" direction={itsMe ? "row-reverse" : "row"} pb={itsLast && "10px"}>
      <Box w="50px" textAlign="center">
        {currentMessage.sender !== nextMessage.sender && <Author sender={currentMessage.sender} />}
      </Box>
      {currentMessage.tmp && (
        <Box mx="5px">
          <Icon as={FiClock} />
        </Box>
      )}
      <Flex direction="column" alignItems={itsMe ? "flex-end" : "flex-start"} w="100%">
        {pressed && (
          <Text fontSize="xs" fontWeight="bold">
            {moment(currentMessage.timestamp).format("DD [de] MMM HH:mm").toUpperCase()}
          </Text>
        )}
        <Box
          maxW="60%"
          bg={itsMe ? "main.500" : backgroundColor}
          borderRadius="lg"
          borderBottomRightRadius={itsMe && itsLast ? "2px" : "lg"}
          borderBottomLeftRadius={!itsMe && itsLast ? "2px" : "lg"}
          p="10px"
          fontWeight="600"
          cursor="pointer"
          _active={{ opacity: 0.8 }}
          onClick={() => setPressed((state) => !state)}
        >
          <Text fontSize="sm" color={itsMe && "#fff"} style={{ whiteSpace: "pre-wrap" }}>
            {currentMessage.body}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
