module.exports = {
  firebase: {
    apiKey: "AIzaSyCt0R2HH95dlVxL3Vij6ocx0Q54IJXtbvY",
    authDomain: "busapp-falebus.firebaseapp.com",
    projectId: "busapp-falebus",
    storageBucket: "busapp-falebus.appspot.com",
    messagingSenderId: "768332976725",
    appId: "1:768332976725:web:924725226d5cd8672cfadc",
  },
  theme: {
    colors: {
      main: {
        50: "#E9F0FC",
        100: "#D0E7FA",
        200: "#A2CEF5",
        300: "#6FA7E3",
        400: "#4880C7",
        500: "#1950A2",
        600: "#123D8B",
        700: "#0C2D74",
        800: "#071F5D",
        900: "#04164D",
      },
    },
  },
};
