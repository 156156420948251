import moment from "moment";

export const formDataToQuery = (formData) => {
  const response = {};

  if (formData._id?.length) response._id = formData._id;
  if (formData.nid?.length) response.nid = parseInt(formData.nid);
  if (formData.cardNumber) response.cardNumber = formData.cardNumber;

  if (formData.user?.length) response["user._id"] = formData.user;
  if (formData.phone?.replace(/\D/g, "")?.length) response["user.phone"] = formData.phone.replace(/\D/g, "");
  if (formData.document?.replace(/\D/g, "")?.length) response["user.document"] = formData.document.replace(/\D/g, "");

  if (formData.amount?.start) response.amount = { ...response.amount, $gte: parseFloat(formData.amount.start) };
  if (formData.amount?.end) response.amount = { ...response.amount, $lte: parseFloat(formData.amount.end) };

  if (formData.platformOss?.length) response["platform.os"] = { $in: formData.platformOss };
  if (formData.platformOsVersions?.length) response["platform.osVersion"] = { $in: formData.platformOsVersions };
  if (formData.platformAppVersions?.length) response["platform.appVersion"] = { $in: formData.platformAppVersions };

  if (formData.deleteds !== undefined) response.deletedAt = { $exists: formData.deleteds };

  if (formData.statuses?.length) response.status = { $in: formData.statuses };
  if (formData.items?.length) response["items.id"] = { $in: formData.items };
  if (formData.paymentMethods?.length) response["payment.method"] = { $in: formData.paymentMethods };

  if (formData.rechargeStorageId) response["recharge.storage.id"] = parseInt(formData.rechargeStorageId);
  if (formData.rechargeStatuses) response["recharge.status"] = { $in: formData.rechargeStatuses };
  if (formData.rechargeClosedAt?.start && moment(formData.rechargeClosedAt.start, "DD/MM/YYYY").isValid())
    response["recharge.closedAt"] = {
      ...response["recharge.closedAt"],
      $gte: moment(formData.rechargeClosedAt.start, "DD/MM/YYYY").startOf("day").toDate(),
    };
  if (formData.rechargeClosedAt?.end && moment(formData.rechargeClosedAt.end, "DD/MM/YYYY").isValid())
    response["recharge.closedAt"] = {
      ...response["recharge.closedAt"],
      $lte: moment(formData.rechargeClosedAt.end, "DD/MM/YYYY").endOf("day").toDate(),
    };

  if (formData.closedAt?.start && moment(formData.closedAt.start, "DD/MM/YYYY").isValid())
    response.closedAt = { ...response.closedAt, $gte: moment(formData.closedAt.start, "DD/MM/YYYY").startOf("day").toDate() };
  if (formData.closedAt?.end && moment(formData.closedAt.end, "DD/MM/YYYY").isValid())
    response.closedAt = { ...response.closedAt, $lte: moment(formData.closedAt.end, "DD/MM/YYYY").endOf("day").toDate() };

  if (formData.createdAt?.start && moment(formData.createdAt.start, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $gte: moment(formData.createdAt.start, "DD/MM/YYYY").startOf("day").toDate() };
  if (formData.createdAt?.end && moment(formData.createdAt.end, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $lte: moment(formData.createdAt.end, "DD/MM/YYYY").endOf("day").toDate() };

  return response;
};

export const queryToFormData = (query) => {
  const response = {};

  if (query._id) response._id = query._id;
  if (query.nid) response.nid = query.nid;
  if (query.cardNumber) response.cardNumber = query.cardNumber;
  if (query["user._id"]) response.user = query["user._id"];
  if (query["user.phone"]) response.phone = query["user.phone"];
  if (query["user.document"]) response.document = query["user.document"];

  if (query["platform.os"]?.$in) response.platformOss = query["platform.os"].$in;
  if (query["platform.osVersion"]?.$in) response.platformOsVersions = query["platform.osVersion"].$in;
  if (query["platform.appVersion"]?.$in) response.platformAppVersions = query["platform.appVersion"].$in;

  if (query.status?.$in) response.statuses = query.status.$in;
  if (query["payment.method"]?.$in) response.paymentMethods = query["payment.method"].$in;
  if (query["items.id"]?.$in) response.items = query["items.id"].$in;

  if (query["recharge.storage.id"]) response.rechargeStorageId = query["recharge.storage.id"];
  if (query["recharge.status"]) response.rechargeStatuses = query["recharge.status"].$in;
  if (query["recharge.closedAt"]?.$gte)
    response.rechargeClosedAt = { ...response.rechargeClosedAt, start: moment(query["recharge.closedAt"].$gte).format("DD/MM/YYYY") };
  if (query["recharge.closedAt"]?.$lte)
    response.rechargeClosedAt = { ...response.rechargeClosedAt, end: moment(query["recharge.closedAt"].$lte).format("DD/MM/YYYY") };

  if (query.deletedAt !== undefined) response.deleteds = query.deletedAt.$exists;

  if (query.amount?.$gte) response.amount = { ...response.amount, start: query.amount.$gte };
  if (query.amount?.$lte) response.amount = { ...response.amount, end: query.amount.$lte };

  if (query.closedAt?.$gte) response.closedAt = { ...response.closedAt, start: moment(query.closedAt.$gte).format("DD/MM/YYYY") };
  if (query.closedAt?.$lte) response.closedAt = { ...response.closedAt, end: moment(query.closedAt.$lte).format("DD/MM/YYYY") };

  if (query.createdAt?.$gte) response.createdAt = { ...response.createdAt, start: moment(query.createdAt.$gte).format("DD/MM/YYYY") };
  if (query.createdAt?.$lte) response.createdAt = { ...response.createdAt, end: moment(query.createdAt.$lte).format("DD/MM/YYYY") };

  return response;
};
