import React, { useEffect, useState, useContext, forwardRef } from "react";
import { useParams } from "react-router";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Editable,
  EditableInput,
  EditablePreview,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  StackDivider,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdSave, MdClose, MdAdd } from "react-icons/md";
import { BiCalendarEvent } from "react-icons/bi";
import * as yup from "yup";
import { DatePicker, InputChips } from "components";
import { messages } from "consts";
import { api, validationErrorHandler } from "lib";
import TimesheetsContext from "./context";
import LinesContext from "../context";
import TimesheetsTime from "./time";

const DatePickerButton = forwardRef(({ value, onClick }, ref) => {
  return (
    <Button size="sm" leftIcon={<Icon as={BiCalendarEvent} />} onClick={onClick}>
      {value}
    </Button>
  );
});

const TimesheetsDetails = (props) => {
  const { formData: currentLine } = useContext(LinesContext);
  const { selected, setSelected, refreshTimesheets } = useContext(TimesheetsContext);
  const { _id } = useParams();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState({});
  const [groups, setGroups] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const formData = selected ?? {};
    setFormData({
      ...formData,
      startDate: formData.startDate && new Date(formData.startDate),
      endDate: formData.endDate && new Date(formData.endDate),
    });
  }, [selected]);

  useEffect(() => {
    const groups = [];
    for (const period of currentLine?.periods ?? []) {
      for (const zone of currentLine?.zones ?? []) {
        const filtered = _.filter(formData?.items, (o) => o.zone === zone._id && o.period === period);
        const items = _.sortBy(filtered, (o) => o.time);
        groups.push({ zone, period, items });
      }
    }
    setGroups(groups);
  }, [currentLine?.periods, currentLine?.zones, formData.items]);

  const handleClose = () => {
    if (isDataSaved) refreshTimesheets();
    setSelected();
    setIsDataSaved(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        startDate: yup.date().typeError(messages.error.date).required(messages.error.required),
        endDate: yup.date().typeError(messages.error.date).required(messages.error.required),
      });
      const data = { ...formData, items: _.flatMap(groups, (o) => o.items) };
      await schema.validate(data);
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.message });
    }
  };

  const handleSaveData = async ({ _id: timesheet, ...data }) => {
    try {
      setLoadingSaveData(true);
      const saved = timesheet
        ? await api.put(`/lines/${_id}/timesheets/${timesheet}`, data)
        : await api.post(`/lines/${_id}/timesheets`, data);
      setSelected(saved);
      setIsDataSaved(true);
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleTimeAdd = (groupIndex, { period, zone }) => {
    setGroups((state) => {
      const tmp = [...state];
      tmp[groupIndex].items.unshift({ period, zone: zone._id, time: "00:00" });
      return tmp;
    });
  };

  const handleTimeSubmit = (data) => {
    setGroups((state) => {
      const tmp = [...state];
      tmp[selectedIndex.groupIndex].items[selectedIndex.itemIndex] = data;
      tmp[selectedIndex.groupIndex].items = _.sortBy(tmp[selectedIndex.groupIndex].items, (o) => o.time);
      return tmp;
    });
  };

  const handleTimeDelete = (groupIndex, itemIndex) => {
    setGroups((state) => {
      const tmp = [...state];
      tmp[groupIndex].items.splice(itemIndex, 1);
      return tmp;
    });
  };

  const handleChangeUra = (groupIndex, data) => {
    setGroups((state) => {
      const tmp = [...state];
      const items = _.map(tmp[groupIndex].items, (item) => ({ ...item, ura: _.map(data, (value) => parseInt(value)) }));
      tmp[groupIndex].items = items;
      return tmp;
    });
  };

  return (
    <Modal isOpen={selected} onClose={handleClose} size="xl" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent maxW="90%">
          <ModalHeader>
            <HStack justify="space-between">
              <FormControl isInvalid={formErrors.name}>
                <Editable
                  placeholder="Nome do quadro"
                  value={formData.name ?? ""}
                  onChange={(name) => setFormData((state) => ({ ...state, name }))}
                >
                  <EditablePreview />
                  <EditableInput />
                </Editable>
                <FormErrorMessage fontWeight="normal">{formErrors.name}</FormErrorMessage>
              </FormControl>
              <HStack spacing={3}>
                <HStack>
                  <Text fontSize="sm" fontWeight="normal">
                    Ativo?
                  </Text>
                  <Switch isChecked={formData.active} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
                </HStack>
                <FormControl isInvalid={formErrors.startDate || formErrors.endDate}>
                  <DatePicker
                    selected={formData.startDate}
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    onChange={([startDate, endDate]) => setFormData((state) => ({ ...state, startDate, endDate }))}
                    dateFormat="d, MMMM yyyy"
                    selectsRange
                    customInput={<DatePickerButton />}
                  />
                  <FormErrorMessage fontWeight="normal">{formErrors.startDate || formErrors.endDate}</FormErrorMessage>
                </FormControl>
              </HStack>
            </HStack>
          </ModalHeader>
          <Divider />
          <ModalBody pt={4}>
            <VStack spacing={4} align="stretch" divider={<StackDivider />}>
              {_.map(groups, (group, groupIndex) => (
                <Box key={groupIndex}>
                  <HStack justify="space-between">
                    <Button size="sm" colorScheme="green">
                      {group.zone.name} • {group.period}
                    </Button>
                    <HStack>
                      <Text fontSize="xs" fontWeight="semibold" whiteSpace="nowrap">
                        Códigos da URA
                      </Text>
                      <InputChips data={group.items?.[0]?.ura} onChange={(data) => handleChangeUra(groupIndex, data)} />
                    </HStack>
                  </HStack>
                  <Box pt="10px">
                    {group.items.map((item, itemIndex) => (
                      <Button
                        key={itemIndex}
                        size="sm"
                        colorScheme="main"
                        m={1}
                        onClick={() => setSelectedIndex({ groupIndex, itemIndex })}
                      >
                        {item.time}
                      </Button>
                    ))}
                    <IconButton size="sm" m={1} icon={<Icon as={MdAdd} />} onClick={() => handleTimeAdd(groupIndex, group)} />
                  </Box>
                </Box>
              ))}
            </VStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button mr={3} icon={<Icon as={MdClose} />} onClick={handleClose}>
              Fechar
            </Button>
            <Button type="submit" colorScheme="main" leftIcon={<Icon as={MdSave} />} isLoading={loadingSaveData}>
              Salvar
            </Button>
          </ModalFooter>
          <TimesheetsTime
            data={groups?.[selectedIndex.groupIndex]?.items?.[selectedIndex.itemIndex]}
            onClose={() => setSelectedIndex({})}
            onDelete={() => handleTimeDelete(selectedIndex.groupIndex, selectedIndex.itemIndex)}
            onSubmit={handleTimeSubmit}
          />
        </ModalContent>
      </form>
    </Modal>
  );
};

export default TimesheetsDetails;
