import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  StackDivider,
  Switch,
  Text,
  useToast,
  VisuallyHidden,
  VStack,
} from "@chakra-ui/react";
import { messages } from "consts";
import { validationErrorHandler } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdChevronLeft, MdDelete, MdAttachFile } from "react-icons/md";
import api from "lib/api";

export const ContactListsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(`/contact-lists/${_id}`);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const formRef = useRef();
  const inputRef = useRef();
  const inputFileRef = useRef();
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? { isActive: true });
  }, [data]);

  const handleSubmit = useCallback(async () => {
    try {
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  }, [formData]);

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) await api.put(`/contact-lists/${_id}`, data);
      else {
        const saved = await api.post("/contact-lists", data);
        navigate(`/contact-lists/details/${saved._id}`, { replace: true });
      }
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  const handleParseText = useCallback(
    (text) => {
      const splited = text.split(",");
      const phones = {};
      const invalidPhones = {};
      const duplicatedPhones = {};
      for (const key of formData.phones ?? []) phones[key] = 1;
      for (const item of splited) {
        const value = item.replace(/\D/g, "");
        const key = "55".concat(value);
        if (value.length >= 10) {
          if (phones[key]) duplicatedPhones[key] = 1;
          else phones[key] = 1;
        } else if (value.length >= 1) invalidPhones[key] = 1;
      }
      setFormData((state) => ({
        ...state,
        phones: Object.keys(phones),
        invalidPhones: Object.keys(invalidPhones),
        duplicatedPhones: Object.keys(duplicatedPhones),
      }));
    },
    [formData.phones]
  );

  const handlePhonesTextSubmit = useCallback(
    (e) => {
      try {
        e.preventDefault();
        handleParseText(inputRef.current.value);
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        inputRef.current.value = "";
      }
    },
    [inputRef.current, handleParseText]
  );

  const handleFileChange = useCallback(
    async ({ target }) => {
      try {
        const [file] = target.files;
        const text = await file.text();
        handleParseText(text);
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        formRef.current.reset();
      }
    },
    [formRef.current, handleParseText]
  );

  const handleDeletePhone = useCallback((index) => {
    setFormData((state) => {
      const phones = [...state.phones];
      phones.splice(index, 1);
      return { ...state, phones };
    });
  }, []);

  return (
    <Box>
      <HStack marginBottom="20px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/contact-lists">
              Listas de contato
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.title ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <Text fontSize="sm">Ativo?</Text>
          <Switch isChecked={formData.isActive} onChange={() => setFormData((state) => ({ ...state, isActive: !state.isActive }))} />
        </HStack>
      </HStack>

      <SimpleGrid spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors.title}>
          <FormLabel>Título</FormLabel>
          <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
          <FormErrorMessage>{formErrors.title}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid spacing={4} mb={4}>
        <FormControl>
          <FormLabel>Adicionar telefones</FormLabel>
          <form onSubmit={handlePhonesTextSubmit}>
            <Input ref={inputRef} placeholder="37999999999,37999999998" />
          </form>
        </FormControl>
        <Button variant="outline" leftIcon={<Icon as={MdAttachFile} />} onClick={() => inputFileRef.current.click()}>
          selecionar arquivo
        </Button>
      </SimpleGrid>

      <SimpleGrid columns={[1, 3]} spacing={4}>
        <Box borderWidth="1px" borderRadius="lg" p="20px">
          <HStack mb={4}>
            <Heading size="sm" flex="1">
              Telefones adicionados ({_.size(formData.phones).toLocaleString()})
            </Heading>
            <Button size="xs" variant="outline" onClick={() => setFormData((state) => ({ ...state, phones: [] }))}>
              remover todos
            </Button>
          </HStack>
          <VStack alignItems="stretch" divider={<StackDivider />}>
            {_.map(formData.phones, (phone, index) => (
              <HStack key={phone} justifyContent="space-between">
                <Text fontSize="sm">{phone}</Text>
                <IconButton size="xs" icon={<Icon as={MdDelete} />} onClick={() => handleDeletePhone(index)} />
              </HStack>
            ))}
          </VStack>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" p="20px">
          <Heading size="sm" mb={4}>
            Telefones duplicados ({_.size(formData.duplicatedPhones).toLocaleString()})
          </Heading>
          <VStack alignItems="stretch" divider={<StackDivider />}>
            {_.map(formData.duplicatedPhones, (phone) => (
              <Text key={phone} fontSize="sm">
                {phone}
              </Text>
            ))}
          </VStack>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" p="20px">
          <Heading size="sm" mb={4}>
            Telefones inválidos ({_.size(formData.invalidPhones).toLocaleString()})
          </Heading>
          <VStack alignItems="stretch" divider={<StackDivider />}>
            {_.map(formData.invalidPhones, (phone) => (
              <Text key={phone} fontSize="sm">
                {phone}
              </Text>
            ))}
          </VStack>
        </Box>
      </SimpleGrid>

      <HStack justify="flex-end" spacing={4} mt={6}>
        <Button as={RouterLink} to="/contact-lists" leftIcon={<Icon as={MdChevronLeft} />}>
          Voltar
        </Button>
        <Button leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData} onClick={handleSubmit}>
          Salvar
        </Button>
      </HStack>

      <VisuallyHidden>
        <form ref={formRef}>
          <input ref={inputFileRef} type="file" accept=".txt" onChange={handleFileChange} />
        </form>
      </VisuallyHidden>
    </Box>
  );
};
