import React, { forwardRef, useContext } from "react";
import moment from "moment";
import { Box, HStack, SimpleGrid, StackDivider, Text, VStack, Heading, VisuallyHidden } from "@chakra-ui/react";
import { masks, currency, translator } from "lib";
import { StatusBadge } from "components";
import { OrdersDetailsContext } from ".";

const PrintableDelivery = forwardRef((props, ref) => {
  const { data } = useContext(OrdersDetailsContext);
  return (
    <VisuallyHidden>
      <Box ref={ref}>
        <Heading size="lg" textAlign="center" my="20px">
          ORDEM DE ENTREGA
        </Heading>
        <VStack align="stretch" divider={<StackDivider />} p={5} spacing={6}>
          <Box>
            <Text fontWeight="bold" mb={4}>
              Transação
            </Text>
            <HStack justify="space-between" spacing={4}>
              <Box>
                <Text fontSize="xs">ID Pedido</Text>
                <Text>{data?._id}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">NID Pedido</Text>
                <Text>{data?.nid}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Status</Text>
                <Text>
                  <StatusBadge label={data?.status ?? ""} />
                </Text>
              </Box>
              <Box>
                <Text fontSize="xs">Método</Text>
                <Text>{translator(data?.payment?.method)}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Valor</Text>
                <Text fontWeight="bold">{currency.format(data?.amount ?? 0)}</Text>
              </Box>
            </HStack>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={4}>
              Cliente
            </Text>
            <SimpleGrid columns={3} spacing={4} mb={4}>
              <Box>
                <Text fontSize="xs">Nome do cliente</Text>
                <Text>{data?.user?.name}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Documento</Text>
                <Text>{masks.document(data?.user?.document ?? "")}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Nascimento</Text>
                <Text>{moment(data?.user?.birth).format("DD/MM/YYYY")}</Text>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={3} spacing={4}>
              <Box>
                <Text fontSize="xs">Telefone</Text>
                <Text>{masks.phone(data?.user?.phone?.replace("55", ""))}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">E-mail</Text>
                <Text>{data?.user?.email}</Text>
              </Box>
            </SimpleGrid>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={4}>
              Endereço
            </Text>
            <SimpleGrid columns={3} spacing={4} mb={4}>
              <Box>
                <Text fontSize="xs">CEP</Text>
                <Text>{data?.address?.zipCode}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Rua</Text>
                <Text>{data?.address?.street}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Número</Text>
                <Text>{data?.address?.number}</Text>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={3} spacing={4}>
              <Box>
                <Text fontSize="xs">Complemento</Text>
                <Text>{data?.address?.complement}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Bairro</Text>
                <Text>{data?.address?.neighborhood}</Text>
              </Box>
              <Box>
                <Text fontSize="xs">Cidade</Text>
                <Text>{data?.address?.city}</Text>
              </Box>
            </SimpleGrid>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={4}>
              Entrega
            </Text>
            <SimpleGrid columns={3} spacing={4} pt="70px" textAlign="center">
              <Box borderTop="1px solid" pt={2}>
                <Text fontWeight="semibold">Recebedor</Text>
              </Box>
              <Box borderTop="1px solid" pt={2}>
                <Text fontWeight="semibold">Documento Identificação</Text>
              </Box>
              <Box borderTop="1px solid" pt={2}>
                <Text fontWeight="semibold">Data</Text>
              </Box>
            </SimpleGrid>
          </Box>

          <Box>
            <Text fontWeight="bold" mb={4}>
              Observações
            </Text>
            <Box border="1px solid" w="100%" h="150px" />
          </Box>
        </VStack>
      </Box>
    </VisuallyHidden>
  );
});

export default PrintableDelivery;
