import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { FormControl, VStack, Divider, Icon, IconButton, HStack, Textarea } from "@chakra-ui/react";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { BsCardText } from "react-icons/bs";
import { BiSend } from "react-icons/bi";
import { AppContext } from "App";
import { Emojis } from "components";
import { Message, QuickMessages } from "../components";
import { ChatContext, SocketContext } from "../contexts";

export const Container = () => {
  const { currentUser } = useContext(AppContext);
  const { socket } = useContext(SocketContext);
  const { selectedAttendance, isMember, isFinished } = useContext(ChatContext);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [isOpen, setIsOpen] = useState({});
  const bottomRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    setMessages(selectedAttendance.messages ?? []);
  }, [selectedAttendance.messages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView();
  }, [bottomRef, messages, isOpen]);

  const handleSendMessage = useCallback(
    (body) => {
      if (body.length === 0) return;
      const timestamp = new Date().getTime();
      const message = { _id: timestamp, sender: currentUser.uid, body, timestamp, tmp: true };
      setMessages((state) => [...state, message]);
      socket.emit("@send_message", { attendanceId: selectedAttendance._id, body });
      setMessageText("");
      setIsOpen({});
    },
    [socket, currentUser.uid, selectedAttendance._id]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault?.();
      if (!socket) return;
      handleSendMessage(messageText);
    },
    [handleSendMessage, socket, messageText]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode !== 13) return;
      if (!e.shiftKey) return handleSubmit(e);
    },
    [handleSubmit]
  );

  return (
    <>
      <VStack spacing={1} align="stretch" flex={1} overflowY="auto" py="10px">
        {_.map(messages, (currentMessage, index) => (
          <Message
            key={currentMessage._id}
            currentUser={currentUser}
            currentMessage={currentMessage}
            nextMessage={messages?.[index + 1] ?? {}}
          />
        ))}
        <div ref={bottomRef} />
      </VStack>
      <Divider />
      <Emojis isOpen={isOpen.emojis} inputRef={inputRef} value={messageText} onSubmit={(value) => setMessageText(value)} />
      <QuickMessages isOpen={isOpen.quickMessages} searchText={messageText} onSubmit={(value) => setMessageText(value)} />
      <form onSubmit={handleSubmit}>
        <HStack p="10px">
          <IconButton
            colorScheme="main"
            variant="ghost"
            icon={<Icon as={isOpen.emojis ? MdClose : HiOutlineEmojiHappy} />}
            onClick={() => setIsOpen((state) => ({ emojis: !state.emojis }))}
            disabled={!isMember || isFinished}
          />
          <IconButton
            colorScheme="main"
            variant="ghost"
            icon={<Icon as={isOpen.quickMessages ? MdClose : BsCardText} />}
            onClick={() => setIsOpen((state) => ({ quickMessages: !state.quickMessages }))}
            disabled={!isMember || isFinished}
          />
          <FormControl flex={1} isRequired={true}>
            <Textarea
              ref={inputRef}
              placeholder="Digite uma mensagem"
              variant="filled"
              value={messageText}
              onChange={({ target }) => setMessageText(target.value)}
              onKeyDown={handleKeyDown}
              disabled={!isMember || isFinished}
            />
          </FormControl>
          <IconButton
            type="submit"
            colorScheme="main"
            variant="ghost"
            icon={<Icon as={BiSend} />}
            disabled={!socket || !isMember || isFinished}
          />
        </HStack>
      </form>
    </>
  );
};
