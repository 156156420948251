import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiExternalLink, FiShoppingBag } from "react-icons/fi";
import { translator } from "lib";
import { ChatContext } from "../contexts";

export const Member = () => {
  const { selectedMember, setSelectedMember } = useContext(ChatContext);

  return (
    <Drawer isOpen={selectedMember} placement="right" onClose={() => setSelectedMember(null)}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody as={HStack} alignItems="center">
          <VStack flex={1} p={0} spacing={6}>
            <Avatar size="2xl" name={selectedMember?.user?.name} />
            <Box textAlign="center">
              <Text fontSize="lg" fontWeight="bold">
                {selectedMember?.user?.name}
              </Text>
              <Text fontSize="sm">{translator(selectedMember?.user?.role)}</Text>
            </Box>
            <VStack w="100%">
              <Button
                as={Link}
                target="_blank"
                leftIcon={<Icon as={FiExternalLink} />}
                to={`/users/details/${selectedMember?.user?._id}`}
                isFullWidth
              >
                Perfil
              </Button>
              <Button
                as={Link}
                target="_blank"
                leftIcon={<Icon as={FiShoppingBag} />}
                to={`/orders?user._id=${selectedMember?.user?._id}`}
                isFullWidth
              >
                Pedidos
              </Button>
            </VStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
