import React, { useState, useContext, useEffect, useMemo } from "react";
import moment from "moment";
import _ from "lodash";
import {
  Badge,
  Box,
  Divider,
  Flex,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  StackDivider,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { MdRefresh, MdSearch } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { useSocketGet, useFuse } from "hooks";
import { AppContext } from "App";
import { ChatContext, SocketContext } from "../contexts";
import { translator } from "lib";

export const Sidebar = () => {
  const { currentUser } = useContext(AppContext);
  const { socket } = useContext(SocketContext);
  const { selectedAttendance, setSelectedAttendance } = useContext(ChatContext);
  const [data, loadingData, refreshData] = useSocketGet(useMemo(() => ({ socket, event: "@get_attendances" }), [socket]));
  const [attendances, setAttendances] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searched = useFuse(["nid", "subject.title"], attendances, searchText);
  const backgroundColor = useColorModeValue("gray.100", "gray.900");

  useEffect(() => {
    setAttendances(data ?? []);
  }, [data]);

  useEffect(() => {
    if (socket) {
      socket.on("@received_attendance", (data) => {
        setSelectedAttendance((state) => (state?._id === data._id ? data : state));
        setAttendances((state) => {
          const attendances = [...state];
          const index = attendances.findIndex((o) => o._id === data._id);
          if (index !== -1) attendances.splice(index, 1);
          attendances.unshift(data);
          return attendances;
        });
      });
      return () => socket.removeListener("@received_attendance");
    }
  }, [socket, setSelectedAttendance]);

  return (
    <Flex direction="column" h="100%" w="320px">
      <FormControl p="10px">
        <InputGroup>
          <InputLeftElement>
            <Icon as={MdSearch} />
          </InputLeftElement>
          <Input placeholder="Pesquisar..." variant="filled" value={searchText} onChange={({ target }) => setSearchText(target.value)} />
          <InputRightElement>
            <IconButton icon={<Icon as={MdRefresh} />} variant="ghost" isLoading={loadingData} onClick={refreshData} />
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Divider />
      <VStack align="stretch" spacing={0} divider={<StackDivider />} overflowX="hidden" overflowY="auto">
        {_.map(searched, (item) => {
          const { lastAccessAt } = item.members.find((o) => o.user?._id === currentUser.uid) ?? {};
          return (
            <HStack
              key={item._id}
              p="10px"
              cursor="pointer"
              onClick={() => setSelectedAttendance(item)}
              bg={item._id === selectedAttendance._id && backgroundColor}
            >
              <Box flex={1} overflow="hidden">
                <Text fontSize="md" fontWeight="bold">
                  Atendimento #{item.nid}
                </Text>
                <Text fontSize="xs" fontWeight="bold">
                  {item.lastMessageAt ? moment(item.lastMessageAt).format("DD [de] MMMM HH:mm") : "-"}
                </Text>
                <Text fontSize="xs">{item.subject?.title}</Text>
              </Box>
              <Badge>{translator(item.status)}</Badge>
              {new Date(item.lastMessageAt) > new Date(lastAccessAt) && <Icon as={FaCircle} color="main.500" />}
            </HStack>
          );
        })}
      </VStack>
    </Flex>
  );
};
