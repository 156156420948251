import { useState } from "react";
import { IconButton, Icon, Tooltip } from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import { CgHashtag } from "react-icons/cg";

export const HashButton = ({ _id }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = (e) => {
    navigator.clipboard.writeText(_id);
    setCopied(true);
    setTimeout(() => setCopied(false), [2000]);
  };

  return (
    <Tooltip label={copied ? "Copiado" : _id} closeOnClick={false}>
      <IconButton
        icon={<Icon as={copied ? MdCheck : CgHashtag} />}
        size="sm"
        colorScheme={copied ? "green" : "gray"}
        onClick={handleCopyToClipboard}
      />
    </Tooltip>
  );
};
