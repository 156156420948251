import React, { useRef } from "react";
import moment from "moment";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { HashButton, DataTable, Dialog, ActiveButton } from "components";
import { messages } from "consts";
import { api } from "lib";

export const LinesList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "LinesList:perPage");
  const [sort, setSort] = useStickyState({ code: 1 }, "LinesList:sort");
  const [page, setPage] = useCacheState(0, "LinesList:page");
  const [search, setSearch] = useCacheState("", "LinesList:search");
  const [response, loading, refresh, error] = useApiGet("/lines", { search, perPage, page, sort });
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/lines/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack py="5px" px="10px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/lines">
              Linhas
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/lines/details" icon={<Icon as={MdAdd} boxSize="22px" />} />
        </Tooltip>
      </HStack>
      <DataTable
        tableId="LinesList:table"
        title="Linhas"
        data={response?.data ?? []}
        size={response?.size ?? 0}
        loading={loading}
        sort={sort}
        onSort={setSort}
        perPage={perPage}
        onPerPage={setPerPage}
        page={page}
        onPaginate={setPage}
        search={search}
        onSearch={setSearch}
        onRefresh={refresh}
        error={error}
        renderItems={[
          { key: "_id", label: "ID", visible: true, render: (value) => <HashButton _id={value} /> },
          { key: "code", label: "Código", visible: true },
          { key: "name", label: "Nome", visible: true },
          {
            key: "itineraries",
            label: "Itinerários",
            visible: true,
            sortable: false,
            formatter: (value) => _.join(_.map(value, "code"), ", "),
          },
          { key: "periods", label: "Períodos", visible: true, sortable: false, formatter: (value) => _.join(value, ", ") },
          { key: "qrCodeLineCode", label: "QRCode", visible: true },
          {
            key: "active",
            label: "Ativo?",
            visible: true,
            textAlign: "center",
            render: (value) => <ActiveButton isActive={value} />,
            exporter: (value) => (value ? "Sim" : "Não"),
          },
          {
            key: "createdAt",
            label: "Criação",
            visible: true,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            key: "updatedAt",
            label: "Atualização",
            visible: false,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
        ]}
        ActionsComponent={({ item }) => (
          <Tooltip label="Excluir">
            <IconButton
              icon={<Icon as={MdDelete} />}
              size="sm"
              variant="ghost"
              colorScheme="red"
              onClick={() => deleteRef.current.confirm(item, handleDelete)}
            />
          </Tooltip>
        )}
        onRowDoubleClick={({ _id }) => navigate(`/lines/details/${_id}`)}
      />
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
