import React, { useCallback, useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  VStack,
  Text,
  StackDivider,
  Box,
  SimpleGrid,
  Spinner,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  ModalFooter,
  FormErrorMessage,
  Button,
  Input,
  Textarea,
  Select,
} from "@chakra-ui/react";
import moment from "moment";
import * as yup from "yup";
import { messages } from "consts";
import { currency, translator, api, masks } from "lib";
import { MdEdit } from "react-icons/md";
import { UsersContext } from ".";
import InputMask from "react-input-mask";

const Cards = () => {
  const { cards, loadingBalances } = useContext(UsersContext);
  const [selectedCard, setSelectedCard] = useState(null);

  return (
    <>
      <VStack align="stretch" divider={<StackDivider />} spacing={4}>
        {_.map(cards, (item) => (
          <HStack>
            <VStack key={item._id} flex="1" align="stretch" spacing={4}>
              <SimpleGrid columns={[2, 4]} spacing={4}>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Tipo
                  </Text>
                  <Text>{translator(item.type)}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Nome
                  </Text>
                  <Text>{item.name}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Número
                  </Text>
                  <Text>{item.number}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Documento
                  </Text>
                  <Text>{masks.document(item.document)}</Text>
                </Box>
              </SimpleGrid>
              <SimpleGrid columns={[2, 4]} spacing={4}>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Identificado
                  </Text>
                  <Text>{item.identified ? "Sim" : "Não"}</Text>
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Saldo disponível
                  </Text>
                  {loadingBalances?.[item._id] ? <Spinner size="xs" /> : <Text>{currency.format(item.balance?.available ?? 0)}</Text>}
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Saldo pendente
                  </Text>
                  {loadingBalances?.[item._id] ? <Spinner size="xs" /> : <Text>{currency.format(item.balance?.pending ?? 0)}</Text>}
                </Box>
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    Bloqueado?
                  </Text>
                  <Text>{item.blocked ? "Sim" : "Não"}</Text>
                </Box>
              </SimpleGrid>
              {item.blocked && (
                <SimpleGrid columns={[2, 4]} spacing={4}>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Data de bloqueio
                    </Text>
                    <Text>{moment(item.blockedAt).format("DD/MM/YYYY [às] HH:mm:ss")}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Motivo do bloqueio
                    </Text>
                    <Text>{item.blockedReason}</Text>
                  </Box>
                </SimpleGrid>
              )}
            </VStack>
            <IconButton size="sm" variant="outline" icon={<Icon as={MdEdit} />} onClick={() => setSelectedCard(item)} />
          </HStack>
        ))}
      </VStack>
      <EditCard data={selectedCard} onClose={() => setSelectedCard(null)} />
    </>
  );
};

const EditCard = ({ data, onClose }) => {
  const { refreshCards } = useContext(UsersContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);

  useEffect(() => {
    setFormData(data ?? {});
  }, [data]);

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setIsLoadingSaveData(true);
        await api.put(`cards/${data._id}`, data);
        onClose();
        refreshCards();
      } finally {
        setIsLoadingSaveData(false);
      }
    },
    [onClose, refreshCards]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          name: yup.string().required(messages.error.required),
          document: yup.string().required(messages.error.required),
          blockedReason: yup.string().when("blocked", {
            is: true,
            then: yup.string().required(messages.error.required),
          }),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSaveData(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [formData, handleSaveData]
  );

  return (
    <Modal isOpen={formData._id} onClose={onClose} isCentered>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Editar cartão {formData.number}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired={true} isInvalid={formErrors.comments}>
                <FormLabel>Documento</FormLabel>
                <Input
                  as={InputMask}
                  mask="999.999.999-99"
                  value={formData.document ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
                />
                <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired={true} isInvalid={formErrors.comments}>
                <FormLabel>Bloqueado?</FormLabel>
                <Select
                  value={formData.blocked ? "yes" : "no"}
                  onChange={({ target }) => setFormData((state) => ({ ...state, blocked: target.value === "yes" }))}
                >
                  <option value="yes">Sim</option>
                  <option value="no">Não</option>
                </Select>
                <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
              </FormControl>
              {formData.blocked && (
                <FormControl isRequired={true} isInvalid={formErrors.blockedReason}>
                  <FormLabel>Motivo do bloqueio</FormLabel>
                  <Textarea
                    value={formData.blockedReason ?? ""}
                    onChange={({ target }) => setFormData((state) => ({ ...state, blockedReason: target.value }))}
                  />
                  <FormErrorMessage>{formErrors.blockedReason}</FormErrorMessage>
                </FormControl>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" colorScheme="main" isLoading={isLoadingSaveData}>
              Finalizar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default Cards;
