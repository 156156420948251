import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { MdDelete, MdRefresh, MdSearch } from "react-icons/md";
import { Dialog } from "components";
import { api } from "lib";
import { messages } from "consts";
import InputMask from "react-input-mask";

export const AuthsList = () => {
  const [formData, setFormData] = useState({});
  const [query, setQuery] = useState({});
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [timestampData, setTimestampData] = useState(Date.now());
  const toast = useToast();
  const deleteRef = useRef();

  useEffect(() => {
    if (query.phoneNumber) {
      const timeout = setTimeout(async () => {
        try {
          setLoadingData(true);
          const response = await api.get("/auths", { params: query });
          setData(response);
        } catch (error) {
          setData({});
        } finally {
          setLoadingData(false);
        }
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [timestampData, query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery(formData);
  };

  const handleDelete = async (item) => {
    try {
      await api.delete(`/auths/${item.uid}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      setData({});
      setQuery({});
      setFormData({});
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack py="5px" px="10px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/customers">
              Autenticação
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <IconButton size="sm" icon={<Icon as={MdRefresh} />} isLoading={loadingData} onClick={() => setTimestampData(Date.now())} />
        </HStack>
      </HStack>

      <Box p="10px">
        <form onSubmit={handleSubmit}>
          <HStack alignItems="flex-end" spacing={4} mb={6}>
            <FormControl>
              <FormLabel>Número de telefone</FormLabel>
              <Input
                as={InputMask}
                mask="+55 (99) 9 9999-9999"
                value={formData.phoneNumber}
                onChange={({ target }) => setFormData((state) => ({ ...state, phoneNumber: target.value }))}
              />
            </FormControl>
            <IconButton type="submit" icon={<Icon as={MdSearch} />} />
          </HStack>
        </form>

        <Table size="sm" fontSize="xs">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Número de telefone</Th>
              <Th>Nome</Th>
              <Th>Usuário associado</Th>
              <Th>Qualificado</Th>
              <Th>Último login</Th>
              <Th>Criação</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.uid && (
              <Tr>
                <Td>{data.uid}</Td>
                <Td>{data.phoneNumber}</Td>
                <Td whiteSpace="nowrap">{data.displayName ?? "-"}</Td>
                <Td>{data.userData?._id ? "SIM" : "NÃO"}</Td>
                <Td>{data.customClaims?.qualified ? "SIM" : "NÃO"}</Td>
                <Td>{data.metadata.lastSignInTime ? moment(data.metadata.lastSignInTime).format("DD/MM/YYYY HH:mm:ss") : "-"}</Td>
                <Td>{moment(data.metadata.creationTime).format("DD/MM/YYYY HH:mm:ss")}</Td>
                <Td>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    icon={<Icon as={MdDelete} />}
                    onClick={() => deleteRef.current.confirm(data, handleDelete)}
                  />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
