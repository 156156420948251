import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, HStack, Icon, Spinner, useToast } from "@chakra-ui/react";
import { messages } from "consts";
import { api } from "lib";
import { useApiGet } from "hooks";
import { MdSave, MdChevronLeft } from "react-icons/md";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const Terms = () => {
  const [data, loadingData] = useApiGet("/terms");
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [editorState, setEditorState] = useState();
  const toast = useToast();

  useEffect(() => {
    const blocksFromHtml = htmlToDraft(data ?? "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoadingSaveData(true);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const body = draftToHtml(rawContentState);
      await api.put("/terms", { body });
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <>
      <HStack mb="20px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>{loadingData ? <Spinner size="xs" /> : "Termos"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>
      <Editor editorState={editorState} onEditorStateChange={(editorState) => setEditorState(editorState)} />
      <form onSubmit={handleSubmit}>
        <HStack justify="flex-end" spacing={4} mt={6}>
          <Button as={RouterLink} to="/settings" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </>
  );
};
