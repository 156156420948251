import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { VStack, Text, StackDivider, Box, IconButton, Icon, HStack, Button } from "@chakra-ui/react";
import { MdCheck } from "react-icons/md";
import { useApiGet } from "hooks";
import { masks } from "lib";
import { UsersContext } from ".";

const PaymentMethods = () => {
  const { _id } = useParams();
  const { formData, setFormData } = useContext(UsersContext);
  const [paymentMethods] = useApiGet(`/users/${_id}/payment-methods`);

  const handleChange = (index, data) => {
    setFormData((state) => {
      const paymentMethods = [...state.paymentMethods];
      paymentMethods[index] = { ...paymentMethods[index], ...data };
      return { ...state, paymentMethods };
    });
  };

  const handleChangePrimary = (index) => {
    setFormData((state) => ({
      ...state,
      paymentMethods: _.map(state.paymentMethods, (o, _index) => ({
        ...o,
        primary: index === _index,
      })),
    }));
  };

  return (
    <VStack align="stretch" divider={<StackDivider />} spacing={6}>
      {_.map(formData.paymentMethods, (item, index) => {
        const method = _.find(paymentMethods, (o) => o._id === item._id) ?? {};
        return (
          <HStack key={item._id}>
            <Box flex={1}>
              <Text fontWeight="bold">{method.title}</Text>
              {method.type === "credit_card" && (
                <HStack justify="space-between" pr={6}>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Número
                    </Text>
                    <Text fontSize="xs">{method.number}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Bandeira
                    </Text>
                    <Text fontSize="xs">{method.brand}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Nome do titular
                    </Text>
                    <Text fontSize="xs">{method.holderName}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs" fontWeight="bold">
                      Documento do titular
                    </Text>
                    <Text fontSize="xs">{masks.document(method.holderDocument)}</Text>
                  </Box>
                </HStack>
              )}
            </Box>
            <HStack>
              <Button
                size="xs"
                colorScheme={item.verified ? "main" : "gray"}
                onClick={() => handleChange(index, { verified: !item.verified })}
              >
                {item.verified ? "Verificado" : "Não Verificado"}
              </Button>
              <Button size="xs" colorScheme={item.active ? "main" : "gray"} onClick={() => handleChange(index, { active: !item.active })}>
                {item.active ? "Ativo" : "Inativo"}
              </Button>
              <IconButton
                size="xs"
                colorScheme={item.primary ? "green" : "gray"}
                icon={<Icon as={MdCheck} />}
                onClick={() => handleChangePrimary(index)}
              />
            </HStack>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default PaymentMethods;
