import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const object = queryString.parse(search);
    return [object, Object.keys(object).length === 0];
  }, [search]);
};
