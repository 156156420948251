import React, { useContext } from "react";
import { SimpleGrid, FormControl, FormLabel, Select, Input, FormErrorMessage, InputRightAddon, InputGroup } from "@chakra-ui/react";
import { CurrencyInput } from "components";
import { EnvironmentContext } from "./index";

const Services = () => {
  const { formData, setFormData, formErrors } = useContext(EnvironmentContext);

  const handleChange = (value) => setFormData((state) => ({ ...state, services: { ...state.services, ...value } }));

  return (
    <>
      <SimpleGrid spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors["services.isRealtimeActive"]}>
          <FormLabel fontSize="xs">Tempo real ativo?</FormLabel>
          <Select
            fontSize="xs"
            value={formData.services?.isRealtimeActive ? "yes" : "no"}
            onChange={({ target }) => handleChange({ isRealtimeActive: target.value === "yes" })}
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </Select>
          <FormErrorMessage>{formErrors["services.isRealtimeActive"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["services.isDynamicRechargeActive"]}>
          <FormLabel fontSize="xs">Recarga dinâmica ativa?</FormLabel>
          <Select
            fontSize="xs"
            value={formData.services?.isDynamicRechargeActive ? "yes" : "no"}
            onChange={({ target }) => handleChange({ isDynamicRechargeActive: target.value === "yes" })}
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </Select>
          <FormErrorMessage>{formErrors["services.isDynamicRechargeActive"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["services.siumobileApiUrl"]}>
          <FormLabel fontSize="xs">URL API Siumobile?</FormLabel>
          <Input
            fontSize="xs"
            value={formData.services?.siumobileApiUrl ?? ""}
            onChange={({ target }) => handleChange({ siumobileApiUrl: target.value })}
          />
          <FormErrorMessage>{formErrors["services.siumobileApiUrl"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors["services.minimumIntervalBetweenSendingSMSCodesInMinutes"]}>
          <FormLabel fontSize="xs">Intervalo mínimo entre envios de SMS</FormLabel>
          <InputGroup>
            <Input
              as={CurrencyInput}
              allowNegative={true}
              decimalSeparator=","
              thousandSeparator="."
              precision="0"
              value={formData.services?.minimumIntervalBetweenSendingSMSCodesInMinutes ?? 0}
              onChangeEvent={(_, __, floatValue) => handleChange({ minimumIntervalBetweenSendingSMSCodesInMinutes: floatValue })}
              autoFocus={false}
            />
            <InputRightAddon>minutos</InputRightAddon>
          </InputGroup>
          <FormErrorMessage>{formErrors["services.minimumIntervalBetweenSendingSMSCodesInMinutes"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors["services.isStagingAndroidTransitActive"]}>
          <FormLabel fontSize="xs">Android Transit ativo? (Testes)</FormLabel>
          <Select
            fontSize="xs"
            value={formData.services?.isStagingAndroidTransitActive ? "yes" : "no"}
            onChange={({ target }) => handleChange({ isStagingAndroidTransitActive: target.value === "yes" })}
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </Select>
          <FormErrorMessage>{formErrors["services.isStagingAndroidTransitActive"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["services.isStagingIosTransitActive"]}>
          <FormLabel fontSize="xs">iOS Transit ativo? (Testes)</FormLabel>
          <Select
            fontSize="xs"
            value={formData.services?.isStagingIosTransitActive ? "yes" : "no"}
            onChange={({ target }) => handleChange({ isStagingIosTransitActive: target.value === "yes" })}
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </Select>
          <FormErrorMessage>{formErrors["services.isStagingIosTransitActive"]}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired={true} isInvalid={formErrors["services.isProductionAndroidTransitActive"]}>
          <FormLabel fontSize="xs">Android Transit ativo? (Produção)</FormLabel>
          <Select
            fontSize="xs"
            value={formData.services?.isProductionAndroidTransitActive ? "yes" : "no"}
            onChange={({ target }) => handleChange({ isProductionAndroidTransitActive: target.value === "yes" })}
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </Select>
          <FormErrorMessage>{formErrors["services.isProductionAndroidTransitActive"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["services.isProductionIosTransitActive"]}>
          <FormLabel fontSize="xs">iOS Transit ativo? (Produção)</FormLabel>
          <Select
            fontSize="xs"
            value={formData.services?.isProductionIosTransitActive ? "yes" : "no"}
            onChange={({ target }) => handleChange({ isProductionIosTransitActive: target.value === "yes" })}
          >
            <option value="yes">Sim</option>
            <option value="no">Não</option>
          </Select>
          <FormErrorMessage>{formErrors["services.isProductionIosTransitActive"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default Services;
