import React, { useState, useRef, useContext } from "react";
import _ from "lodash";
import { Box, Center, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { MdAdd, MdDelete, MdCreate } from "react-icons/md";
import { FaRoute, FaCircle } from "react-icons/fa";
import { Dialog, CardSubTitle, Card, CardHeader, CardBody, CardTitle } from "components";
import ItinerariesDetails from "./details";
import LinesContext from "../context";
import ItinerariesContext from "./context";

const ItinerariesList = () => {
  const { formData: currentLine, setFormData: setCurrentLine } = useContext(LinesContext);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const deleteRef = useRef();

  const handleDelete = async (index) => {
    setCurrentLine((state) => {
      const itineraries = [...state.itineraries];
      itineraries.splice(index, 1);
      return { ...state, itineraries };
    });
  };

  return (
    <ItinerariesContext.Provider value={{ selectedIndex, setSelectedIndex }}>
      <Card>
        <CardHeader>
          <HStack justify="space-between">
            <Box>
              <CardTitle>Itinerários</CardTitle>
              <CardSubTitle>Você pode adicionar vários itinerários com diferentes legendas e cores.</CardSubTitle>
            </Box>
            <IconButton colorScheme="main" icon={<Icon as={MdAdd} />} onClick={() => setSelectedIndex(-1)} />
          </HStack>
        </CardHeader>
        <CardBody>
          <Table fontSize="sm">
            <Thead>
              <Tr>
                <Th>Código</Th>
                <Th>Legenda</Th>
                <Th>Cor</Th>
                <Th>#</Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.map(currentLine?.itineraries, (item, index) => (
                <Tr key={index}>
                  <Td>{item.code}</Td>
                  <Td>{item.label}</Td>
                  <Td>
                    <Icon as={FaCircle} color={item.color} />
                  </Td>
                  <Td>
                    <HStack spacing={4}>
                      <Tooltip label="Editar">
                        <IconButton icon={<Icon as={MdCreate} />} variant="ghost" onClick={() => setSelectedIndex(index)} />
                      </Tooltip>
                      <Tooltip label="Excluir">
                        <IconButton
                          icon={<Icon as={MdDelete} />}
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => deleteRef.current.confirm(index, handleDelete)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {_.size(currentLine?.itineraries) === 0 && (
            <Center pt="40px" pb="20px">
              <Box textAlign="center">
                <Icon as={FaRoute} boxSize={20} marginBottom="10px" />
                <Text fontSize="lg" fontWeight="bold">
                  Nenhum itinerário
                </Text>
                <Text fontSize="sm">Ainda não foram adicionados itinerários nesta linha.</Text>
              </Box>
            </Center>
          )}
        </CardBody>
      </Card>
      <ItinerariesDetails />
      <Dialog.Delete ref={deleteRef} />
    </ItinerariesContext.Provider>
  );
};

export default ItinerariesList;
