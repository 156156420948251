import { Box, Text } from "@chakra-ui/react";

export const Card = ({ children, ...rest }) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" mb={6} {...rest}>
      {children}
    </Box>
  );
};

export const CardHeader = ({ children, ...rest }) => {
  return (
    <Box p={6} pb={0} {...rest}>
      {children}
    </Box>
  );
};

export const CardTitle = ({ children, ...rest }) => {
  return (
    <Text fontSize="lg" {...rest}>
      {children}
    </Text>
  );
};

export const CardSubTitle = ({ children, ...rest }) => {
  return (
    <Text fontSize="xs" fontWeight="normal">
      {children}
    </Text>
  );
};

export const CardBody = ({ children, ...rest }) => {
  return (
    <Box p={6} {...rest}>
      {children}
    </Box>
  );
};
