import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import _ from "lodash";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { MdDelete, MdSave } from "react-icons/md";
import { messages } from "consts";
import * as yup from "yup";
import { SyncSelect } from "components";
import LinesContext from "../context";

const TimesheetsItems = ({ data, onSubmit, onClose, onDelete }) => {
  const { zones } = useContext(LinesContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const formData = data ?? {};
    const route = _.map(formData.route, (_id) => _.find(zones?.data, (o) => o._id === _id));
    setFormData({ ...formData, route });
  }, [data, zones]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const schema = yup.object().shape({
        time: yup.date().typeError(messages.error.time).required(messages.error.required),
      });
      await schema.validate({ ...formData, time: moment(formData.time, "HH:mm").toDate() });
      onSubmit?.({ ...formData, route: _.map(formData.route, (o) => o._id) });
      onClose?.();
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.message });
    }
  };

  const handleDelete = () => {
    onDelete?.();
    onClose?.();
  };

  return (
    <Modal isOpen={data} onClose={onClose} size="xl" isCentered scrollBehavior="inside">
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader as={HStack} justify="space-between">
            <HStack>
              <Box>
                <Text>Horário</Text>
                <Text fontSize="xs" fontWeight="normal">
                  Informe os atributos do horário nos campos abaixo.
                </Text>
              </Box>
            </HStack>
            <HStack>
              <Text fontSize="sm" fontWeight="normal">
                Reforço?
              </Text>
              <Switch
                isChecked={formData.reinforcement}
                onChange={() => setFormData((state) => ({ ...state, reinforcement: !state.reinforcement }))}
              />
            </HStack>
          </ModalHeader>
          <Divider />
          <ModalBody pt="20px">
            <SimpleGrid spacing={4} mb={4}>
              <FormControl isRequired={true} isInvalid={formErrors.time}>
                <FormLabel>Horário</FormLabel>
                <Input
                  as={InputMask}
                  mask="99:99"
                  value={formData.time ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, time: target.value }))}
                />
                <FormErrorMessage>{formErrors.time}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <SimpleGrid spacing={4} mb={4}>
              <FormControl>
                <FormLabel>Via</FormLabel>
                <SyncSelect
                  isMulti
                  placeholder="Selecione as zonas"
                  value={formData.route ?? []}
                  options={zones?.data ?? []}
                  getOptionValue={(o) => o._id}
                  getOptionLabel={(o) => o.name}
                  onChange={(route) => setFormData((state) => ({ ...state, route }))}
                />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid spacing={4} mb={4}>
              <FormControl>
                <FormLabel>Observações</FormLabel>
                <Textarea
                  value={formData.comments ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
                />
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <Divider />
          <ModalFooter display="inline-block">
            <HStack justify="space-between">
              <Button colorScheme="red" variant="ghost" leftIcon={<Icon as={MdDelete} />} onClick={handleDelete}>
                Excluir
              </Button>
              <HStack justify="space-between">
                <Button onClick={onClose}>Fechar</Button>
                <Button type="submit" colorScheme="main" leftIcon={<Icon as={MdSave} />}>
                  Salvar
                </Button>
              </HStack>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default TimesheetsItems;
