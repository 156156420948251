import React, { useState, useEffect } from "react";
import _ from "lodash";
import { csvDownload } from "lib";
import { Paginator } from "components";
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdFilterList, MdSettings, MdVisibility, MdGetApp, MdSearch, MdRefresh, MdInfo } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const perPageOptions = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "50", label: "50" },
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "500", label: "500" },
  { value: "1000", label: "1.000" },
  // { value: "-1", label: "Todos" },
];

export const DataTable = ({
  tableId,
  title,
  data = [],
  size = 0,
  perPage = 50,
  sort = {},
  loading,
  page,
  renderItems,
  onPerPage,
  onSort,
  onPaginate,
  search = "",
  onSearch,
  onRefresh,
  error,
  ActionsComponent,
  onRowDoubleClick,
}) => {
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [renderRows, setRenderRows] = useState([]);
  const [exporterRows, setExporterRows] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [sortKey, setSortKey] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [searchText, setSearchText] = useState(search);
  const [refreshVisibleColumns, setRefreshVisibleColumns] = useState();
  const rowBackgroundColor = useColorModeValue("gray.50", "gray.700");

  useEffect(() => {
    for (const [sortKey, sortDirection] of Object.entries(sort)) {
      setSortKey(sortKey);
      setSortDirection(sortDirection);
    }
  }, [sort]);

  useEffect(() => {
    const parseLocalStorage = (key) => {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch (error) {
        localStorage.removeItem(key);
        return null;
      }
    };
    const storageVisibleColumns = parseLocalStorage(tableId) ?? _.map(_.filter(renderItems, "visible"), "key");
    const storageVisibleColumnsOrder = parseLocalStorage(`${tableId}-columns-order`) ?? [];
    const sortedVisibleColumns = _.sortBy(storageVisibleColumns, (item) => storageVisibleColumnsOrder.indexOf(item));
    const mapped = _.map(sortedVisibleColumns, (key) => _.find(renderItems, (o) => o.key === key));
    const visibles = _.reject(mapped, _.isUndefined);
    setVisibleColumns(visibles);
  }, [renderItems, tableId, refreshVisibleColumns]);

  useEffect(() => {
    const renderRows = [];
    const exporterRows = [];
    for (let i = 0; i < data.length; i++) {
      renderRows[i] = [];
      exporterRows[i] = [];
      for (const { key, formatter, render, exporter, exportable } of visibleColumns) {
        const value = _.get(data[i], key);
        if (formatter) {
          const aux = formatter(value, data[i]);
          renderRows[i].push(aux);
          if (exportable !== false) exporterRows[i].push(aux);
        } else if (!render && !exporter) {
          const aux = value || "-";
          renderRows[i].push(aux);
          if (exportable !== false) exporterRows[i].push(aux);
        } else {
          renderRows[i].push(render ? render(value, data[i]) : value);
          if (exportable !== false) exporterRows[i].push(exporter ? exporter(value, data[i]) : value);
        }
      }
    }
    setRenderRows(renderRows);
    setExporterRows(exporterRows);
  }, [visibleColumns, data]);

  const handleVisibleColumns = (value) => {
    const visibles = renderItems.filter(({ key }) => value.indexOf(key) !== -1);
    localStorage.setItem(tableId, JSON.stringify(visibles.map(({ key }) => key)));
    setVisibleColumns(visibles);
  };

  const handleExport = async () => {
    setLoadingExport(true);
    await csvDownload(
      visibleColumns.filter((o) => o.exportable !== false),
      exporterRows,
      new Date().toISOString()
    );
    setLoadingExport(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (onSearch instanceof Function) onSearch(searchText);
  };

  const resetColumnsOrder = () => {
    localStorage.removeItem(tableId);
    localStorage.removeItem(`${tableId}-columns-order`);
    setRefreshVisibleColumns(Date.now());
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const sorted = reorder(visibleColumns, result.source.index, result.destination.index);
    const store = sorted.map((o) => o.key);
    localStorage.setItem(`${tableId}-columns-order`, JSON.stringify(store));
    setVisibleColumns(sorted);
  };

  return (
    <Box>
      <Flex p="10px" alignItems="center">
        <Menu closeOnSelect={false}>
          <Tooltip label="Ordenação">
            <MenuButton as={IconButton} marginRight="10px">
              <Icon as={MdFilterList} />
            </MenuButton>
          </Tooltip>
          <MenuList minWidth="240px" maxHeight="240px" overflowY="auto" fontSize="xs">
            <MenuOptionGroup
              type="radio"
              title="Direção"
              value={sortDirection.toString()}
              onChange={(value) => onSort({ [sortKey]: parseInt(value) })}
            >
              <MenuItemOption value="1">Crescente</MenuItemOption>
              <MenuItemOption value="-1">Decrescente</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup type="radio" title="Atributo" value={sortKey} onChange={(value) => onSort({ [value]: sortDirection })}>
              {renderItems
                .filter((e) => e.sortable !== false)
                .map(({ key, label }) => (
                  <MenuItemOption key={key} value={key}>
                    {label}
                  </MenuItemOption>
                ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        {onSearch ? (
          <Box as="form" flex="1" marginRight="20px" onSubmit={handleSearchSubmit}>
            <InputGroup>
              <Input
                variant="filled"
                placeholder={`Buscar em ${title}...`}
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
              />
              <InputRightElement>
                <IconButton type="button" icon={<Icon as={MdSearch} />} isLoading={loading} variant="ghost" colorScheme="none" />
              </InputRightElement>
            </InputGroup>
          </Box>
        ) : (
          <Text fontSize="lg" fontWeight="bold">
            {title}
          </Text>
        )}
        <Flex flex="1" />
        <Tooltip label="Exportar">
          <IconButton icon={<Icon as={MdGetApp} />} marginRight="10px" isLoading={loadingExport} onClick={handleExport} />
        </Tooltip>
        <Tooltip label="Atualizar">
          <IconButton icon={<Icon as={MdRefresh} />} marginRight="10px" isLoading={loading} onClick={onRefresh} />
        </Tooltip>
        <Menu closeOnSelect={false}>
          <Tooltip label="Colunas visíveis">
            <MenuButton as={IconButton} marginRight="10px">
              <Icon as={MdSettings} />
            </MenuButton>
          </Tooltip>
          <MenuList minWidth="240px" maxHeight="240px" overflowY="auto" fontSize="xs">
            <MenuItemOption onClick={resetColumnsOrder}>Exibição padrão</MenuItemOption>
            <MenuDivider />
            <MenuOptionGroup type="checkbox" value={visibleColumns.map(({ key }) => key)} onChange={handleVisibleColumns}>
              {renderItems.map(({ key, label }) => (
                <MenuItemOption key={key} value={key}>
                  {label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        <Menu closeOnSelect={true}>
          <Tooltip label="Limite">
            <MenuButton as={Button} leftIcon={<Icon as={MdVisibility} boxSize={4} />} fontSize="xs">
              {perPageOptions.find((o) => o.value === perPage.toString())?.label}
            </MenuButton>
          </Tooltip>
          <MenuList minWidth="240px" fontSize="xs">
            <MenuOptionGroup value={perPage.toString()} type="radio" onChange={(value) => onPerPage(parseInt(value))}>
              {perPageOptions.map(({ value, label }) => (
                <MenuItemOption key={value} value={value}>
                  {label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>
      <Box overflowX="auto">
        <Table variant="simple" size="sm">
          <Thead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <Tr {...provided.droppableProps} ref={provided.innerRef}>
                    {visibleColumns.map((item, index) => (
                      <Draggable key={item.key} draggableId={item.key} index={index}>
                        {(provided, snapshot) => (
                          <Th ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {item.label}
                          </Th>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    {ActionsComponent && (
                      <Th textAlign="center" key={visibleColumns.length}>
                        #
                      </Th>
                    )}
                  </Tr>
                )}
              </Droppable>
            </DragDropContext>
          </Thead>
          <Tbody>
            {renderRows.map((row, index) => (
              <Tr
                key={index}
                {...(onRowDoubleClick && {
                  _active: { opacity: 0.6 },
                  _hover: { backgroundColor: rowBackgroundColor, cursor: "pointer" },
                })}
                onDoubleClick={() => onRowDoubleClick?.(data[index])}
              >
                <>
                  {row.map((column, index) => (
                    <Td key={index} fontSize="xs">
                      {column}
                    </Td>
                  ))}
                  {ActionsComponent && (
                    <Td>
                      <ActionsComponent item={data[index]} />
                    </Td>
                  )}
                </>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {error ? (
        <Center paddingTop="40px" paddingBottom="20px">
          <Box textAlign="center">
            <Icon as={MdInfo} boxSize={20} marginBottom="10px" />
            <Text fontSize="lg" fontWeight="bold">
              Problemas ao buscar informação
            </Text>
            <Text fontSize="sm" mb="20px">
              Encontramos alguns problemas ao buscar a informação solicitada. <strong>{error.message}</strong>
            </Text>
            <Button leftIcon={<Icon as={MdRefresh} />} isLoading={loading} onClick={onRefresh}>
              Tentar novamente
            </Button>
          </Box>
        </Center>
      ) : (
        <>
          {loading && (
            <Center marginTop="20px">
              <Spinner />
            </Center>
          )}
          {!renderRows.length && !loading && (
            <Center paddingTop="40px" paddingBottom="20px">
              <Box textAlign="center">
                <Icon as={MdSearch} boxSize={20} marginBottom="10px" />
                <Text fontSize="lg" fontWeight="bold">
                  Nenhum registro encontrado
                </Text>
                <Text fontSize="sm">Não encontramos registros correspondentes à sua busca.</Text>
              </Box>
            </Center>
          )}
        </>
      )}
      <Flex justify="flex-end" p={3}>
        <Paginator loading={loading} page={page} size={size} perPage={perPage} onPaginate={onPaginate} />
      </Flex>
    </Box>
  );
};
