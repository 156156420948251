import React, { useContext } from "react";
import { BrowserRouter, Routes, Navigate, Route } from "react-router-dom";
import { NetworkError, PermissionDenied, InvalidAuthorization } from "exceptions";
import { Preloader } from "components";
import { AppContext } from "App";
import Container from "./Container";
import * as Pages from "./";

const Private = () => {
  const { currentUser } = useContext(AppContext);

  return (
    <>
      {currentUser?.uid ? (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Container />}>
              <Route path="/chat" element={<Pages.Chat />} />

              <Route path="/orders">
                <Route index element={<Pages.OrdersList />} />
                <Route path="details" element={<Pages.OrdersDetails />} />
                <Route path="details/:_id" element={<Pages.OrdersDetails />} />
              </Route>

              <Route path="/templates">
                <Route index element={<Pages.TemplatesList />} />
                <Route path="details" element={<Pages.TemplatesDetails />} />
                <Route path="details/:_id" element={<Pages.TemplatesDetails />} />
              </Route>

              <Route path="/attendances">
                <Route index element={<Pages.AttendancesList />} />
                <Route path="details" element={<Pages.AttendancesDetails />} />
                <Route path="details/:_id" element={<Pages.AttendancesDetails />} />
              </Route>

              <Route path="/attendance-subjects">
                <Route index element={<Pages.AttendanceSubjectsList />} />
                <Route path="details" element={<Pages.AttendanceSubjectsDetails />} />
                <Route path="details/:_id" element={<Pages.AttendanceSubjectsDetails />} />
              </Route>

              <Route path="/attendance-quick-messages">
                <Route index element={<Pages.AttendanceQuickMessagesList />} />
                <Route path="details" element={<Pages.AttendanceQuickMessagesDetails />} />
                <Route path="details/:_id" element={<Pages.AttendanceQuickMessagesDetails />} />
              </Route>

              <Route path="/users">
                <Route index element={<Pages.UsersList />} />
                <Route path="details" element={<Pages.UsersDetails />} />
                <Route path="details/:_id" element={<Pages.UsersDetails />} />
              </Route>

              <Route path="/zones">
                <Route index element={<Pages.ZonesList />} />
                <Route path="details" element={<Pages.ZonesDetails />} />
                <Route path="details/:_id" element={<Pages.ZonesDetails />} />
              </Route>

              <Route path="/lines">
                <Route index element={<Pages.LinesList />} />
                <Route path="details" element={<Pages.LinesDetails />} />
                <Route path="details/:_id" element={<Pages.LinesDetails />} />
              </Route>

              <Route path="/notifications">
                <Route index element={<Pages.NotificationsList />} />
                <Route path="details" element={<Pages.NotificationsDetails />} />
                <Route path="details/:_id" element={<Pages.NotificationsDetails />} />
              </Route>

              <Route path="/outlets">
                <Route index element={<Pages.OutletsList />} />
                <Route path="details" element={<Pages.OutletsDetails />} />
                <Route path="details/:_id" element={<Pages.OutletsDetails />} />
              </Route>

              <Route path="/contact-lists">
                <Route index element={<Pages.ContactListsList />} />
                <Route path="details" element={<Pages.ContactListsDetails />} />
                <Route path="details/:_id" element={<Pages.ContactListsDetails />} />
              </Route>

              <Route path="/faq">
                <Route index element={<Pages.FaqList />} />
                <Route path="details" element={<Pages.FaqDetails />} />
                <Route path="details/:_id" element={<Pages.FaqDetails />} />
              </Route>

              <Route path="/greetings">
                <Route index element={<Pages.GreetingsList />} />
                <Route path="details" element={<Pages.GreetingsDetails />} />
                <Route path="details/:_id" element={<Pages.GreetingsDetails />} />
              </Route>

              <Route path="/environment" element={<Pages.Environment />} />

              <Route path="/customers" element={<Pages.CustomersList />} />

              <Route path="/auths" element={<Pages.AuthsList />} />

              <Route path="/terms" element={<Pages.Terms />} />

              <Route path="/backups" element={<Pages.BackupsList />} />
            </Route>

            <Route path="/cacher" element={<Pages.Cacher />} />

            <Route path="*" element={<Navigate to="/users" replace={true} />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <Preloader />
      )}
      <NetworkError />
      <PermissionDenied />
      <InvalidAuthorization />
    </>
  );
};

export default Private;
