import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "@firebase/auth";
import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { FiSmartphone } from "react-icons/fi";
import { messages } from "consts";
import * as yup from "yup";

export const SignInPhoneNumberSend = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        phoneNumber: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSignIn(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSignIn = async ({ phoneNumber }) => {
    try {
      setLoadingSignIn(true);
      const phone = "+55".concat(phoneNumber.replace(/\D/g, ""));
      const auth = getAuth();
      const verifier = new RecaptchaVerifier("recaptcha-container", { size: "invisible" }, auth);
      const { verificationId } = await signInWithPhoneNumber(auth, phone, verifier);
      navigate("/phone-number/validate", { state: { phoneNumber, verificationId } });
    } catch (error) {
      toast({ description: messages.error.signInPhoneNumber, status: "error", duration: 5000, isClosable: true });
    } finally {
      setLoadingSignIn(false);
    }
  };

  return (
    <>
      <Heading marginBottom="0.5rem">Número de telefone</Heading>
      <Text fontSize="sm" marginBottom="1.5rem">
        Informe seu número de telefone no campo abaixo para que possamos enviar o código de verificação.
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={4} marginBottom="1rem">
          <FormControl isRequired={true} isInvalid={formErrors.phoneNumber}>
            <InputGroup>
              <InputLeftElement>
                <Icon as={FiSmartphone} />
              </InputLeftElement>
              <Input
                as={InputMask}
                mask="(99) 9 9999-9999"
                placeholder="Informe seu número de telefone"
                value={formData.phoneNumber ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, phoneNumber: target.value }))}
                autoFocus={true}
              />
            </InputGroup>
            <FormErrorMessage>{formErrors.phoneNumber}</FormErrorMessage>
          </FormControl>
        </Stack>
        <Stack marginBottom="1rem">
          <Button type="submit" rightIcon={<Icon as={IoPaperPlaneOutline} />} isLoading={loadingSignIn} colorScheme="main">
            Enviar
          </Button>
        </Stack>
      </form>
      <div id="recaptcha-container"></div>
    </>
  );
};
