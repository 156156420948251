import React, { createContext, useRef } from "react";
import moment from "moment";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, useToast } from "@chakra-ui/react";
import { MdAdd, MdDelete } from "react-icons/md";
import { useApiGet, useCacheState, useStickyState, useQuery } from "hooks";
import { HashButton, DataTable, StatusBadge, Dialog } from "components";
import { api, translator, currency } from "lib";
import { messages } from "consts";
import Filter from "./filter";

export const OrdersListContext = createContext();

const amountWithoutTaxes = (value, { items }) => {
  return _.sum(items.filter((o) => o.id !== "taxes").map((o) => o.amount ?? 0));
};

const findAmountById = (id, { items }) => {
  const finded = items.find((o) => o.id === id);
  return finded?.amount ?? 0;
};

export const OrdersList = () => {
  const navigate = useNavigate();
  const [params, caching] = useQuery();
  const [perPage, setPerPage] = useCacheState(20, "OrdersList:perPage");
  const [sort, setSort] = useStickyState({ createdAt: -1 }, "OrdersList:sort");
  const [page, setPage] = useCacheState(0, "OrdersList:page");
  const [query, setQuery] = useCacheState(params ?? { deletedAt: { $exists: false } }, "OrdersList:query", { caching });
  const [response, loading, refresh, error] = useApiGet("/orders", { query, perPage, page, sort });
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/orders/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <OrdersListContext.Provider value={{ query, setQuery, setPage, loading }}>
      <HStack py="5px" px="10px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/orders">
              Pedidos
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/orders/details" icon={<Icon as={MdAdd} boxSize="22px" />} />
        </Tooltip>
      </HStack>

      <Filter />

      <DataTable
        tableId="OrdersList:table"
        title="Pedidos"
        data={response?.data ?? []}
        size={response?.size ?? 0}
        loading={loading}
        sort={sort}
        onSort={setSort}
        perPage={perPage}
        onPerPage={setPerPage}
        page={page}
        onPaginate={setPage}
        onRefresh={refresh}
        error={error}
        renderItems={[
          { key: "_id", label: "ID", visible: true, render: (value) => <HashButton _id={value} /> },
          { key: "nid", label: "NID", visible: true },
          { key: "user.name", label: "Usuário", visible: true },
          { key: "user.phone", label: "Telefone", visible: false },
          { key: "user.document", label: "CPF", visible: false },
          { key: "platform.os", label: "S.O.", visible: false },
          { key: "platform.osVersion", label: "Versão do S.O.", visible: false },
          { key: "platform.appVersion", label: "Versão do App", visible: false },
          { key: "status", label: "Status", visible: true, exporter: translator, render: (value) => <StatusBadge label={value} /> },
          { key: "payment.method", label: "Método", visible: true, formatter: translator },
          {
            key: "items",
            label: "Itens",
            visible: true,
            sortable: false,
            formatter: (value) => {
              const items = _.filter(value, (o) => ["delivery", "taxes"].indexOf(o.id) === -1);
              return _.join(
                _.map(items, (o) => translator(o.id)),
                ", "
              );
            },
          },
          { key: "payment.creditCard.acquirerTid", label: "TID", visible: true },
          { key: "payment.creditCard.brand", label: "Bandeira", visible: true },
          { key: "amount", label: "Valor", visible: true, formatter: currency.format },
          {
            key: "amountWithoutTaxes",
            label: "Valor s/ taxas",
            visible: true,
            sortable: false,
            formatter: (...args) => currency.format(amountWithoutTaxes(...args)),
          },
          {
            key: "taxesAmount",
            label: "Taxas",
            visible: true,
            sortable: false,
            formatter: (value, data) => currency.format(findAmountById("taxes", data)),
          },
          { key: "cardNumber", label: "Cartão", visible: true },
          { key: "recharge.storage.id", label: "Estocagem", visible: true },
          {
            key: "recharge.closedAt",
            label: "Fechamento recarga",
            visible: true,
            formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
          },
          {
            key: "recharge.status",
            label: "Recarga",
            visible: true,
            exporter: translator,
            render: (value) => <StatusBadge label={value} />,
          },
          {
            key: "rechargeAmount",
            label: "Valor Recarga",
            visible: true,
            formatter: (value, data) => currency.format(findAmountById("recharge", data)),
          },
          {
            key: "closedAt",
            label: "Fechamento pedido",
            visible: true,
            formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
          },
          {
            key: "createdAt",
            label: "Criação",
            visible: true,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            key: "updatedAt",
            label: "Atualização",
            visible: false,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
        ]}
        ActionsComponent={({ item }) => (
          <Tooltip label="Excluir">
            <IconButton
              icon={<Icon as={MdDelete} />}
              size="sm"
              variant="ghost"
              colorScheme="red"
              onClick={() => deleteRef.current.confirm(item, handleDelete)}
            />
          </Tooltip>
        )}
        onRowDoubleClick={({ _id }) => navigate(`/orders/details/${_id}`)}
      />
      <Dialog.Delete ref={deleteRef} />
    </OrdersListContext.Provider>
  );
};
