import React, { useContext, useMemo } from "react";
import { FormControl, FormErrorMessage, FormLabel, Select, SimpleGrid } from "@chakra-ui/react";
import { UsersContext } from "./index";
import { AppContext } from "App";

const roles = [
  { value: "customer", label: "Cliente" },
  { value: "editor", label: "Editor" },
  { value: "manager", label: "Gerente" },
  { value: "admin", label: "Administrador" },
];

const Security = (props, ref) => {
  const { formData, formErrors, setFormData } = useContext(UsersContext);
  const { currentClaims } = useContext(AppContext);
  const isDisabled = useMemo(() => currentClaims?.role !== "admin", [currentClaims?.role]);

  return (
    <>
      <SimpleGrid spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors?.role}>
          <FormLabel>Nível</FormLabel>
          <Select
            value={formData?.role ?? ""}
            onChange={({ target }) => setFormData((state) => ({ ...state, role: target.value }))}
            isDisabled={isDisabled}
          >
            {roles.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{formErrors?.role}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default Security;
