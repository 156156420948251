import { useState } from "react";
import Fuse from "fuse.js";
import useDeepCompareEffect from "use-deep-compare-effect";

export const useFuse = (keys = [], data = [], search) => {
  const [response, setResponse] = useState([]);

  useDeepCompareEffect(() => {
    if (!search || search?.length === 0) setResponse(data);
    else {
      const fuse = new Fuse(data, { includeScore: true, keys });
      const results = fuse.search(search);
      setResponse(results.map((o) => o.item));
    }
  }, [keys, data, search]);

  return response;
};
