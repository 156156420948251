import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  SimpleGrid,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useApiGet } from "hooks";
import { StatusBadge } from "components";

export const AttendancesDetails = () => {
  const { _id } = useParams();
  const [formData, setFormData] = useState({});
  const [data, loadingData] = useApiGet(`/attendances/${_id}`);

  useEffect(() => {
    const formData = data ?? {};
    const messages = _.map(formData.messages, (item) => {
      const sender = _.find(formData.members, (o) => o.user?._id === item.sender)?.user?.name;
      return { ...item, sender };
    });
    setFormData({ ...formData, messages });
  }, [data]);

  return (
    <Box>
      <HStack marginBottom="20px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/attendances">
              Atendimentos
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>

      <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
        <Box>
          <Text fontSize="xs">NID</Text>
          <Text>{formData.nid}</Text>
        </Box>
        <Box>
          <Text fontSize="xs">Status</Text>
          <StatusBadge label={formData.status} />
        </Box>
        <Box>
          <Text fontSize="xs">Assunto</Text>
          <Text>{formData.subject?.title}</Text>
        </Box>
        <Box>
          <Text fontSize="xs">Membros</Text>
          <Text>{_.map(formData.members, (o) => o.user?.name).join(", ")}</Text>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
        <Box>
          <Text fontSize="xs">Avaliação</Text>
          <Text>{formData.rating}</Text>
        </Box>
        <Box>
          <Text fontSize="xs">Última mensagem</Text>
          <Text>{moment(formData.lastMessageAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
        </Box>
        <Box>
          <Text fontSize="xs">Finalização</Text>
          <Text>{moment(formData.finishedAt).format("DD/MM/YYYY HH:mm:ss")}</Text>
        </Box>
        <Box>
          <Text fontSize="xs">Observações</Text>
          <Text>{formData.comments}</Text>
        </Box>
      </SimpleGrid>

      <VStack align="stretch" divider={<StackDivider />} spacing={4}>
        {_.map(formData.messages, (item) =>
          item.sender ? (
            <Box key={item._id}>
              <Text fontSize="xs">
                [{moment(item.timestamp).format("DD/MM/YYYY [às] HH:mm:ss")}] {item.sender} diz:
              </Text>
              <Text>{item.body}</Text>
            </Box>
          ) : (
            <Alert borderRadius="lg">
              <AlertDescription fontSize="xs">
                [{moment(item.timestamp).format("DD/MM/YYYY [às] HH:mm:ss")}] {item.body}
              </AlertDescription>
            </Alert>
          )
        )}
      </VStack>
    </Box>
  );
};
