import moment from "moment";

export const formDataToQuery = (formData) => {
  const response = {};

  if (formData._id?.length) response._id = formData._id;
  if (formData.phone?.replace(/\D/g, "")?.length) response.phone = formData.phone.replace(/\D/g, "");
  if (formData.document?.replace(/\D/g, "")?.length) response.document = formData.document.replace(/\D/g, "");
  if (formData.email) response.email = formData.email;
  if (formData.cardNumber) response.cardNumber = formData.cardNumber;

  if (formData.roles?.length) response.role = { $in: formData.roles };
  if (formData.platformOss?.length) response["platform.os"] = { $in: formData.platformOss };
  if (formData.platformOsVersions?.length) response["platform.osVersion"] = { $in: formData.platformOsVersions };
  if (formData.platformAppVersions?.length) response["platform.appVersion"] = { $in: formData.platformAppVersions };

  if (formData.active !== undefined) response.active = formData.active;
  if (formData.anonymous !== undefined) response.anonymous = formData.anonymous;
  if (formData.qualified !== undefined) response.qualified = formData.qualified;

  if (formData.birth?.start && moment(formData.birth.start, "DD/MM/YYYY").isValid())
    response.birth = { ...response.birth, $gte: moment(formData.birth.start, "DD/MM/YYYY").startOf("day").toDate() };
  if (formData.birth?.end && moment(formData.birth.end, "DD/MM/YYYY").isValid())
    response.birth = { ...response.birth, $lte: moment(formData.birth.end, "DD/MM/YYYY").endOf("day").toDate() };

  if (formData["counters.cards"]?.start)
    response["counters.cards"] = { ...response["counters.cards"], $gte: formData["counters.cards"].start };
  if (formData["counters.cards"]?.end) response["counters.cards"] = { ...response["counters.cards"], $lte: formData["counters.cards"].end };

  if (formData.createdAt?.start && moment(formData.createdAt.start, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $gte: moment(formData.createdAt.start, "DD/MM/YYYY").startOf("day").toDate() };
  if (formData.createdAt?.end && moment(formData.createdAt.end, "DD/MM/YYYY").isValid())
    response.createdAt = { ...response.createdAt, $lte: moment(formData.createdAt.end, "DD/MM/YYYY").endOf("day").toDate() };

  return response;
};

export const queryToFormData = (query) => {
  const response = {};

  if (query._id) response._id = query._id;
  if (query.phone) response.phone = query.phone;
  if (query.document) response.document = query.document;
  if (query.email) response.email = query.email;
  if (query.cardNumber) response.cardNumber = query.cardNumber;

  if (query.role?.$in) response.roles = query.role.$in;
  if (query["platform.os"]?.$in) response.platformOss = query["platform.os"].$in;
  if (query["platform.osVersion"]?.$in) response.platformOsVersions = query["platform.osVersion"].$in;
  if (query["platform.appVersion"]?.$in) response.platformAppVersions = query["platform.appVersion"].$in;

  if (query.active !== undefined) response.active = query.active;
  if (query.anonymous !== undefined) response.anonymous = query.anonymous;
  if (query.qualified !== undefined) response.qualified = query.qualified;

  if (query.birth?.$gte) response.birth = { ...response.birth, start: moment(query.birth.$gte).format("DD/MM/YYYY") };
  if (query.birth?.$lte) response.birth = { ...response.birth, end: moment(query.birth.$lte).format("DD/MM/YYYY") };

  if (query["counters.cards"]?.$gte) response["counters.cards"] = { ...response["counters.cards"], start: query["counters.cards"].$gte };
  if (query["counters.cards"]?.$lte) response["counters.cards"] = { ...response["counters.cards"], end: query["counters.cards"].$lte };

  if (query.createdAt?.$gte) response.createdAt = { ...response.createdAt, start: moment(query.createdAt.$gte).format("DD/MM/YYYY") };
  if (query.createdAt?.$lte) response.createdAt = { ...response.createdAt, end: moment(query.createdAt.$lte).format("DD/MM/YYYY") };

  return response;
};
