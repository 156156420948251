import React, { useState, useContext, useCallback } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import * as yup from "yup";
import { messages } from "consts";
import { ChatContext, SocketContext } from "../contexts";

export const Finish = ({ isOpen, onClose }) => {
  const { socket } = useContext(SocketContext);
  const { selectedAttendance } = useContext(ChatContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingFinishAttendance, setLoadingFinishAttendance] = useState(false);
  const toast = useToast();

  const handleFinishAttendance = useCallback(
    ({ comments }) => {
      setLoadingFinishAttendance(true);
      socket.emit("@finish_attendance", { attendanceId: selectedAttendance._id, comments }, () => {
        toast({ description: messages.success.finishAttendance, status: "success", isClosable: true });
        setLoadingFinishAttendance(false);
        onClose?.();
      });
    },
    [socket, toast, onClose]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          comments: yup.string().required(messages.error.required),
        });
        await schema.validate(formData);
        handleFinishAttendance(formData);
        setFormErrors({});
      } catch (error) {
        setFormErrors({ [error.path]: error.errors });
      }
    },
    [formData, handleFinishAttendance]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Finalizar atendimento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired={true} isInvalid={formErrors.comments}>
              <FormLabel>Observações</FormLabel>
              <Textarea
                value={formData.comments}
                onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
              />
              <FormErrorMessage>{formErrors.comments}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button type="submit" colorScheme="main" isLoading={loadingFinishAttendance}>
              Finalizar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};
