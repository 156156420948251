import React, { useContext } from "react";
import { SimpleGrid, FormControl, FormLabel, Input, FormErrorMessage } from "@chakra-ui/react";
import { EnvironmentContext } from "./index";

const Bank = () => {
  const { formData, setFormData, formErrors } = useContext(EnvironmentContext);

  const handleChange = (value) => setFormData((state) => ({ ...state, bankData: { ...state.bankData, ...value } }));

  return (
    <>
      <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors["bankData.bankName"]}>
          <FormLabel fontSize="xs">Banco</FormLabel>
          <Input
            fontSize="xs"
            value={formData.bankData?.bankName ?? ""}
            onChange={({ target }) => handleChange({ bankName: target.value })}
          />
          <FormErrorMessage>{formErrors["bankData.bankName"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["bankData.agency"]}>
          <FormLabel fontSize="xs">Agência</FormLabel>
          <Input fontSize="xs" value={formData.bankData?.agency ?? ""} onChange={({ target }) => handleChange({ agency: target.value })} />
          <FormErrorMessage>{formErrors["bankData.agency"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors["bankData.accountType"]}>
          <FormLabel fontSize="xs">Tipo de conta</FormLabel>
          <Input
            fontSize="xs"
            value={formData.bankData?.accountType ?? ""}
            onChange={({ target }) => handleChange({ accountType: target.value })}
          />
          <FormErrorMessage>{formErrors["bankData.accountType"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["bankData.accountNumber"]}>
          <FormLabel fontSize="xs">Número da conta</FormLabel>
          <Input
            fontSize="xs"
            value={formData.bankData?.accountNumber ?? ""}
            onChange={({ target }) => handleChange({ accountNumber: target.value })}
          />
          <FormErrorMessage>{formErrors["bankData.accountNumber"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
        <FormControl isRequired={true} isInvalid={formErrors["bankData.holderDocument"]}>
          <FormLabel fontSize="xs">CNPJ</FormLabel>
          <Input
            fontSize="xs"
            value={formData.bankData?.holderDocument ?? ""}
            onChange={({ target }) => handleChange({ holderDocument: target.value })}
          />
          <FormErrorMessage>{formErrors["bankData.holderDocument"]}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired={true} isInvalid={formErrors["bankData.holderName"]}>
          <FormLabel fontSize="xs">Nome</FormLabel>
          <Input
            fontSize="xs"
            value={formData.bankData?.holderName ?? ""}
            onChange={({ target }) => handleChange({ holderName: target.value })}
          />
          <FormErrorMessage>{formErrors["bankData.holderName"]}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>
    </>
  );
};

export default Bank;
