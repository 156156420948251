import React, { useCallback, useRef } from "react";
import moment from "moment";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, Button, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd, MdClose } from "react-icons/md";
import { useApiGet, useCacheState, useStickyState, useQuery } from "hooks";
import { HashButton, DataTable, Dialog, StatusBadge } from "components";
import { messages } from "consts";
import { api } from "lib";

export const AttendancesList = () => {
  const navigate = useNavigate();
  const [params, caching] = useQuery();
  const [perPage, setPerPage] = useCacheState(20, "AttendancesList:perPage");
  const [sort, setSort] = useStickyState({ name: 1 }, "AttendancesList:sort");
  const [page, setPage] = useCacheState(0, "AttendancesList:page");
  const [search, setSearch] = useCacheState("", "AttendancesList:search");
  const [query, setQuery] = useCacheState(params ?? {}, "AttendancesList:query", { caching });
  const [response, loading, refresh, error] = useApiGet("/attendances", { query, search, perPage, page, sort });
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/attendances/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  const handleCleanQuery = useCallback(() => {
    setQuery((state) => ({ ...state, "members.user": undefined }));
    navigate("/attendances", { replace: true });
  }, []);

  return (
    <>
      <HStack py="5px" px="10px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/attendances">
              Atendimentos
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/attendances/details" icon={<Icon as={MdAdd} boxSize="22px" />} />
        </Tooltip>
      </HStack>

      {query["members.user"] && (
        <Button size="xs" colorScheme="main" rightIcon={<Icon as={MdClose} />} onClick={handleCleanQuery}>
          Usuário: {query["members.user"]}
        </Button>
      )}

      <DataTable
        tableId="AttendancesList:table"
        title="Atendimentos"
        data={response?.data ?? []}
        size={response?.size ?? 0}
        loading={loading}
        sort={sort}
        onSort={setSort}
        perPage={perPage}
        onPerPage={setPerPage}
        page={page}
        onPaginate={setPage}
        search={search}
        onSearch={setSearch}
        onRefresh={refresh}
        error={error}
        renderItems={[
          { key: "_id", label: "ID", visible: true, render: (value) => <HashButton _id={value} /> },
          { key: "nid", label: "NID", visible: true },
          { key: "status", label: "Status", visible: true, render: (value) => <StatusBadge label={value} /> },
          { key: "subject.title", label: "Assunto", visible: true },
          {
            key: "members",
            label: "Membros",
            visible: true,
            formatter: (value) => _.map(value, (o) => o.user?.name).join(", "),
          },
          {
            key: "lastMessageAt",
            label: "Última mensagem",
            visible: true,
            formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
          },
          {
            key: "finishedAt",
            label: "Finalização",
            visible: true,
            formatter: (value) => (value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "-"),
          },
          {
            key: "createdAt",
            label: "Criação",
            visible: true,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            key: "updatedAt",
            label: "Atualização",
            visible: true,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
        ]}
        ActionsComponent={({ item }) => (
          <Tooltip label="Excluir">
            <IconButton
              icon={<Icon as={MdDelete} />}
              size="sm"
              variant="ghost"
              colorScheme="red"
              onClick={() => deleteRef.current.confirm(item, handleDelete)}
            />
          </Tooltip>
        )}
        onRowDoubleClick={({ _id }) => navigate(`/attendances/details/${_id}`)}
      />
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
