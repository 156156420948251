import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { Box, Center, HStack, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useToast } from "@chakra-ui/react";
import { MdAdd, MdDelete, MdCreate } from "react-icons/md";
import { BiTimer } from "react-icons/bi";
import { Dialog, ActiveButton, CardSubTitle, Card, CardHeader, CardBody, CardTitle, HashButton } from "components";
import { useApiGet } from "hooks";
import { messages } from "consts";
import { api } from "lib";
import TimesheetsContext from "./context";
import TimesheetsDetails from "./details";

const TimesheetsList = () => {
  const { _id } = useParams();
  const [timesheets, loadingTimesheets, refreshTimesheets] = useApiGet(`/lines/${_id}/timesheets`);
  const [selected, setSelected] = useState();
  const deleteRef = useRef();
  const toast = useToast();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/lines/${_id}/timesheets/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshTimesheets();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <TimesheetsContext.Provider value={{ selected, setSelected, refreshTimesheets }}>
      <Card>
        <CardHeader>
          <HStack justify="space-between">
            <Box>
              <CardTitle>Quadros de horário</CardTitle>
              <CardSubTitle>Você pode adicionar vários quadros de horário com diferentes datas de vigência.</CardSubTitle>
            </Box>
            <IconButton colorScheme="main" icon={<Icon as={MdAdd} />} onClick={() => setSelected({})} isLoading={loadingTimesheets} />
          </HStack>
        </CardHeader>
        <CardBody>
          <Table fontSize="sm">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th>Data inicial</Th>
                <Th>Data final</Th>
                <Th>Ativo?</Th>
                <Th>#</Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.map(timesheets?.data, (item) => (
                <Tr key={item._id}>
                  <Td>
                    <HashButton _id={item._id} />
                  </Td>
                  <Td>{item.name}</Td>
                  <Td>{moment(item.startDate).format("DD/MM/YYYY")}</Td>
                  <Td>{moment(item.endDate).format("DD/MM/YYYY")}</Td>
                  <Td>
                    <ActiveButton isActive={item.active} />
                  </Td>
                  <Td>
                    <HStack spacing={4}>
                      <Tooltip label="Editar">
                        <IconButton icon={<Icon as={MdCreate} />} variant="ghost" onClick={() => setSelected(item)} />
                      </Tooltip>
                      <Tooltip label="Excluir">
                        <IconButton
                          icon={<Icon as={MdDelete} />}
                          variant="ghost"
                          colorScheme="red"
                          onClick={() => deleteRef.current.confirm(item, handleDelete)}
                        />
                      </Tooltip>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {_.size(timesheets?.data) === 0 && (
            <Center pt="40px" pb="20px">
              <Box textAlign="center">
                <Icon as={BiTimer} boxSize={20} marginBottom="10px" />
                <Text fontSize="lg" fontWeight="bold">
                  Nenhum quadro de horários
                </Text>
                <Text fontSize="sm">Ainda não foram adicionados quadros de horário nesta linha.</Text>
              </Box>
            </Center>
          )}
        </CardBody>
      </Card>

      <TimesheetsDetails />

      <Dialog.Delete ref={deleteRef} />
    </TimesheetsContext.Provider>
  );
};

export default TimesheetsList;
