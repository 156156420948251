import React, { useState } from "react";
import { Box, Input, InputGroup, Icon, InputRightElement } from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
import { emojis } from "consts";
import { useFuse } from "hooks";

export const Emojis = ({ isOpen, inputRef, value, onSubmit }) => {
  const [searchText, setSearchText] = useState("");
  const data = useFuse(["tags"], emojis, searchText);

  const handleEmoji = ({ emoji }) => {
    const tmp = [...value];
    const { selectionStart, selectionEnd } = inputRef.current;
    tmp.splice(selectionStart, selectionEnd - selectionStart, emoji);
    const message = tmp.join("");
    onSubmit?.(message);
    inputRef.current?.focus();
  };

  return isOpen ? (
    <Box>
      <Box p="10px">
        <InputGroup>
          <Input
            placeholder="Pesquisar emoji..."
            variant="filled"
            value={searchText}
            onChange={({ target }) => setSearchText(target.value)}
          />
          <InputRightElement>
            <Icon as={MdSearch} />
          </InputRightElement>
        </InputGroup>
      </Box>
      <Box p="10px" pt="0" h="200px" overflowY="auto">
        {data.map((item, index) => (
          <span key={index} className="emoji-button" onClick={() => handleEmoji?.(item)}>
            {item.emoji}
          </span>
        ))}
      </Box>
    </Box>
  ) : null;
};
