import React from "react";
import { Outlet } from "react-router-dom";
import { Center, Box } from "@chakra-ui/react";

const Container = () => (
  <Center width="100vw" height="100vh">
    <Box width={{ base: "90%", md: "400px" }} p={5}>
      <Outlet />
    </Box>
  </Center>
);

export default Container;
