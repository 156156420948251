import React, { useRef } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useToast, Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdDelete, MdAdd } from "react-icons/md";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { HashButton, DataTable, Dialog } from "components";
import { messages } from "consts";
import { api } from "lib";

export const OutletsList = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useCacheState(20, "OutletsList:perPage");
  const [sort, setSort] = useStickyState({ name: 1 }, "OutletsList:sort");
  const [page, setPage] = useCacheState(0, "OutletsList:page");
  const [search, setSearch] = useCacheState("", "OutletsList:search");
  const [response, loading, refresh, error] = useApiGet("/outlets", { search, perPage, page, sort });
  const toast = useToast();
  const deleteRef = useRef();

  const handleDelete = async (item) => {
    try {
      await api.delete(`/outlets/${item._id}`);
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refresh();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack py="5px" px="10px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/outlets">
              Outlets
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Tooltip label="Novo">
          <IconButton size="sm" colorScheme="main" as={Link} to="/outlets/details" icon={<Icon as={MdAdd} boxSize="22px" />} />
        </Tooltip>
      </HStack>
      <DataTable
        tableId="OutletsList:table"
        title="Outlets"
        data={response?.data ?? []}
        size={response?.size ?? 0}
        loading={loading}
        sort={sort}
        onSort={setSort}
        perPage={perPage}
        onPerPage={setPerPage}
        page={page}
        onPaginate={setPage}
        search={search}
        onSearch={setSearch}
        onRefresh={refresh}
        error={error}
        renderItems={[
          { key: "_id", label: "ID", visible: true, render: (value) => <HashButton _id={value} /> },
          { key: "name", label: "Nome", visible: true },
          { key: "phone", label: "Telefone", visible: true },
          { key: "coords.lat", label: "Latitude", visible: true },
          { key: "coords.lng", label: "Longitude", visible: true },
          {
            key: "createdAt",
            label: "Criação",
            visible: true,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            key: "updatedAt",
            label: "Atualização",
            visible: false,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
        ]}
        ActionsComponent={({ item }) => (
          <Tooltip label="Excluir">
            <IconButton
              icon={<Icon as={MdDelete} />}
              size="sm"
              variant="ghost"
              colorScheme="red"
              onClick={() => deleteRef.current.confirm(item, handleDelete)}
            />
          </Tooltip>
        )}
        onRowDoubleClick={({ _id }) => navigate(`/outlets/details/${_id}`)}
      />
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
