import React from "react";
import { HStack, Icon, Text, VStack, Image, Spinner, Center } from "@chakra-ui/react";
import { MdImage } from "react-icons/md";
import { Card, CardBody } from "components";
import { formatBytes } from "lib";

export const ImagePreview = ({ data, isSelected, onSelect }) => (
  <Card
    mb={0}
    onClick={() => onSelect?.(data)}
    bg={isSelected && "main.50"}
    color={isSelected && "main.500"}
    cursor={onSelect && "pointer"}
    overflow="hidden"
  >
    <Image
      width="100%"
      height="150px"
      src={data.transforms.thumbnail.location}
      objectFit="cover"
      alt={data.name}
      fallback={
        <Center py={6}>
          <Spinner size="sm" />
        </Center>
      }
    />
    <CardBody p={3} as={HStack}>
      <Icon as={MdImage} />
      <VStack align="stretch" spacing={0}>
        <Text fontSize="xs" fontWeight="bold" noOfLines={1}>
          {data.name}
        </Text>
        <Text fontSize="xs">{formatBytes(data.transforms.original.size)}</Text>
      </VStack>
    </CardBody>
  </Card>
);
