import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";
import { getAuth, getIdToken } from "@firebase/auth";
import { Preloader } from "components";
import { SocketContext } from "./contexts";

const Socket = ({ children }) => {
  const [authorization, setAuthorization] = useState();
  const [socket, setSocket] = useState();

  useEffect(() => {
    const auth = getAuth();
    getIdToken(auth.currentUser).then((authorization) => setAuthorization(authorization));
  }, []);

  useEffect(() => {
    if (authorization) {
      const socket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
        path: process.env.REACT_APP_SOCKET_BASE_PATH,
        reconnectionDelayMax: 10000,
        auth: { authorization },
      });
      socket.on("connect", () => setSocket(socket));
      socket.on("disconnect", () => setSocket(null));
      return () => socket.disconnect();
    }
  }, [authorization]);

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export default Socket;
