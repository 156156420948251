import { Badge } from "@chakra-ui/react";
import { translator } from "lib";

const colorSchemes = {
  paid: "green",
  pending: "yellow",
  canceled: "red",
  failed: "red",
  success: "green",
  retrying: "main",
  waiting_payment: "yellow",
  payment_failed: "red",
  waiting: "yellow",
  expired: "purple",
  sending: "blue",
  sended: "green",
  active: "green",
  deleted: "red",
};

export const StatusBadge = ({ label }) => <Badge colorScheme={colorSchemes[label]}>{translator(label)}</Badge>;
