import React, { useState, useContext, useEffect, useCallback, createContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HashButton } from "components";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { getAuth, getIdToken, signOut } from "firebase/auth";
import { MdBrightness2, MdBrightness7, MdMenu, MdExpandMore, MdExpandLess } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { sidebarItems } from "consts";
import { AppContext } from "App";
import packageJson from "../../../package.json";
import Logo from "_config/logo";

const ContainerContext = createContext();

const Nav = ({ items = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const background = useColorModeValue("main.50", "gray.700");
  const color = useColorModeValue("main.500", "white");
  const [expanded, setExpanded] = useState({});

  const handleMenuItemClick = ({ id, to }) => {
    if (to) return navigate(to);
    setExpanded((state) => ({ ...state, [id]: !expanded[id] }));
  };

  const getMenuItemButtonProps = ({ to }) => {
    const props = { variant: "ghost", borderLeftRadius: "none", isFullWidth: true };
    if (to && location.pathname.search(to) !== -1) return { ...props, background, color };
    return props;
  };

  const MenuItems = ({ data }) => {
    return (
      <List>
        {data.map(({ id, icon, size, text, to, items }) => (
          <ListItem key={id}>
            <Button {...getMenuItemButtonProps({ to })} onClick={() => handleMenuItemClick({ id, to })}>
              {icon && <Icon as={icon} marginRight="10px" boxSize={size} />}
              <Text fontSize="xs" flex="1" textAlign="left">
                {text}
              </Text>
              {items && <Icon as={expanded[id] ? MdExpandLess : MdExpandMore} marginLeft="10px" />}
            </Button>
            {items && expanded[id] && <MenuItems data={items} />}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box py="5px">
      {items.map((group) => (
        <Box key={group.id}>
          {group.title && (
            <Text fontSize="xs" fontWeight="bold" borderTopWidth="1px" p={3} mt={3}>
              {group.title}
            </Text>
          )}
          <MenuItems data={group.items} isRootMenu={true} />
        </Box>
      ))}
    </Box>
  );
};

export const Sidebar = () => {
  const { isMobileView, opened, handleToggle } = useContext(ContainerContext);

  return isMobileView ? (
    <Drawer placement="left" onClose={handleToggle} isOpen={opened}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody padding="20px 0">
            <Nav items={sidebarItems} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  ) : (
    <>
      {opened && (
        <Box overflowY="auto" pr="5px">
          <Nav items={sidebarItems} />
        </Box>
      )}
    </>
  );
};

const Toolbar = () => {
  const { opened, handleToggle } = useContext(ContainerContext);
  const { currentUser } = useContext(AppContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const iconButtonColor = useColorModeValue("main.200", "white");
  const [token, setToken] = useState();

  useEffect(() => {
    const auth = getAuth();
    getIdToken(auth.currentUser).then((token) => setToken(token));
  }, []);

  const handleSignOut = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  return (
    <>
      <HStack height="55px" px="10px" justify="space-between" backgroundColor="main.500">
        <HStack>
          <Tooltip label={`${opened ? "Recolher" : "Expandir"} menu`}>
            <IconButton
              icon={<Icon as={MdMenu} boxSize="20px" />}
              variant="ghost"
              color={iconButtonColor}
              _hover={{ backgroundColor: "main.400" }}
              isRound
              onClick={handleToggle}
            />
          </Tooltip>
          <Box pl="5px">
            <Logo width={60} height={20} />
            <Text fontSize="xs" fontWeight="bold" color="white">
              v{packageJson.version}
            </Text>
          </Box>
        </HStack>
        <HStack>
          {process.env.NODE_ENV === "development" && <HashButton _id={token} />}
          <Tooltip label={`Tema ${colorMode === "light" ? "escuro" : "claro"}`}>
            <IconButton
              icon={<Icon as={colorMode === "light" ? MdBrightness7 : MdBrightness2} boxSize="20px" />}
              variant="ghost"
              color={iconButtonColor}
              _hover={{ backgroundColor: "main.400" }}
              isRound
              onClick={toggleColorMode}
            />
          </Tooltip>
          <Box>
            <Menu>
              <MenuButton as={IconButton} isRound variant="ghost">
                <Avatar size="sm" />
              </MenuButton>
              <MenuList>
                <Box textAlign="center" paddingY="10px">
                  <Avatar size="lg" marginBottom="10px" />
                  <Text fontWeight="bold">{currentUser.displayName}</Text>
                  <Text fontSize="xs">{currentUser.phoneNumber}</Text>
                </Box>
                <MenuDivider />
                <MenuItem onClick={handleSignOut}>
                  <Icon as={IoLogOutOutline} marginRight="10px" />
                  <Text fontSize="xs">Sair da minha conta</Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </HStack>
      <Divider />
    </>
  );
};

const Container = () => {
  const [isDesktopView] = useMediaQuery("(min-width: 940px)");
  const [isMobileView] = useMediaQuery("(max-width: 940px)");
  const [opened, setOpened] = useState(isDesktopView);

  const handleToggle = useCallback(() => setOpened(!opened), [opened]);

  return (
    <ContainerContext.Provider value={{ isDesktopView, isMobileView, opened, handleToggle }}>
      <Flex width="100vw" height="100vh" flexDirection="column">
        <Toolbar />
        <Flex flex="1" overflow="hidden">
          <Sidebar opened={opened} onToggle={handleToggle} />
          <Box flex="1" overflowY="auto" padding="20px">
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </ContainerContext.Provider>
  );
};

export default Container;
