export const messages = {
  success: {
    deleteData: "Registro removido com sucesso!",
    saveData: "Registro salvo com sucesso!",
    finishAttendance: "Atendimento finaliado com sucesso!",
  },
  error: {
    required: "Este campo é obrigatório.",
    date: "A data informada é inválida.",
    time: "O horário informado é inválido.",
    signInPhoneNumber: "Número de telefone inválido.",
    signInVerificationCode: "Código de verificação inválido.",
    disabledUser: "Sua conta foi desativada. Para mais informações entre em contato com nossa equipe de suporte.",
  },
  warning: {
    deleteData: "Deseja realmente excluir este registro?",
    cancelOrder:
      "Deseja realmente alterar este pedido para o status CANCELADO? Para continuar, digite 'CONFIRMAR' na caixa de texto abaixo.",
  },
};
