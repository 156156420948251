import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import * as yup from "yup";
import { messages } from "consts";
import { ColorPicker } from "components";
import ItinerariesContext from "./context";
import LinesContext from "../context";

const ItinerariesDetails = (props) => {
  const { formData: currentLine, setFormData: setCurrentLine } = useContext(LinesContext);
  const { selectedIndex, setSelectedIndex } = useContext(ItinerariesContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    setFormData(currentLine.itineraries?.[selectedIndex] ?? {});
  }, [selectedIndex, currentLine.itineraries]);

  const handleClose = () => setSelectedIndex(null);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const schema = yup.object().shape({
        label: yup.string().required(messages.error.required),
        color: yup.string().required(messages.error.required),
        code: yup.string().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.message });
    }
  };

  const handleSaveData = async (data) => {
    setCurrentLine((state) => {
      const itineraries = [...state.itineraries];
      if (selectedIndex === -1) itineraries.push(data);
      else itineraries[selectedIndex] = data;
      return { ...state, itineraries };
    });
    handleClose();
  };

  return (
    <Modal isOpen={selectedIndex !== null} onClose={handleClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Itinerário</ModalHeader>
          <Divider />
          <ModalBody pt={4}>
            <SimpleGrid spacing={4} mb={4}>
              <FormControl isRequired={true} isInvalid={formErrors.code}>
                <FormLabel>Código</FormLabel>
                <Input value={formData.code ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, code: target.value }))} />
                <FormErrorMessage>{formErrors.code}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <SimpleGrid spacing={4} mb={4}>
              <FormControl isRequired={true} isInvalid={formErrors.label}>
                <FormLabel>Legenda</FormLabel>
                <Input
                  value={formData.label ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, label: target.value }))}
                />
                <FormErrorMessage>{formErrors.label}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <SimpleGrid spacing={4} mb={4}>
              <FormControl isRequired={true} isInvalid={formErrors.color}>
                <FormLabel>Cor</FormLabel>
                <ColorPicker value={formData.color ?? ""} onChange={(color) => setFormData((state) => ({ ...state, color }))} />
                <FormErrorMessage>{formErrors.color}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
            <SimpleGrid spacing={4} mb={4}>
              <FormControl>
                <FormLabel>Observações</FormLabel>
                <Textarea
                  value={formData.comments ?? ""}
                  onChange={({ target }) => setFormData((state) => ({ ...state, comments: target.value }))}
                />
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button mr={3} icon={<Icon as={MdClose} />} onClick={handleClose}>
              Fechar
            </Button>
            <Button type="submit" colorScheme="main">
              Finalizar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ItinerariesDetails;
