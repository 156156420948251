import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdSearch } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import _ from "lodash";
import InputMask from "react-input-mask";
import { usePrevious, useCacheState, useApiGet } from "hooks";
import { SyncSelect } from "components";
import { OrdersListContext } from "./index";
import * as queryFormatter from "./queryFormatter";
import CurrencyInput from "react-currency-input";

const LabelButton = ({ children, active, onClick }) => (
  <Button size="xs" colorScheme={active ? "main" : "gray"} onClick={onClick}>
    {children}
  </Button>
);

const Filter = () => {
  const { query, setQuery, setPage, loading } = useContext(OrdersListContext);
  const [formData, setFormData] = useState({});
  const [visible, setVisible] = useCacheState(false, "UsersListFilter:visible");
  const [platforms] = useApiGet("/platforms");
  const [options, setOptions] = useState({
    deleteds: [
      { value: "", label: "Selecione" },
      { value: true, label: "Sim" },
      { value: false, label: "Não" },
    ],
    statuses: [
      { value: "paid", label: "Pago" },
      { value: "pending", label: "Pendente" },
      { value: "expired", label: "Expirado" },
      { value: "failed", label: "Falha" },
      { value: "canceled", label: "Cancelado" },
    ],
    items: [
      { value: "recharge", label: "Recarga" },
      { value: "issue", label: "Emissão" },
      { value: "reissue", label: "Reemissão" },
    ],
    paymentMethods: [
      { value: "credit_card", label: "Cartão de Crédito" },
      { value: "pix", label: "Pix" },
      { value: "boleto", label: "Boleto" },
      { value: "deposit", label: "Depósito" },
    ],
    rechargeStatuses: [
      { value: "success", label: "Sucesso" },
      { value: "pending", label: "Pendente" },
      { value: "retrying", label: "Tentando novamente" },
      { value: "failed", label: "Falha" },
    ],
    platformOss: [
      { value: "android", label: "Android" },
      { value: "ios", label: "iOS" },
    ],
  });
  const prevFormData = usePrevious(formData);

  useEffect(() => {
    const data = { platformAppVersions: [], platformOsVersions: [] };
    if (formData.platformOss?.length === 1) {
      const [key] = formData.platformOss;
      data.platformAppVersions = _.map(platforms?.[key]?.appVersions, (value) => ({ value, label: value }));
      data.platformOsVersions = _.map(platforms?.[key]?.osVersions, (value) => ({ value, label: value }));
    }
    setFormData((state) => ({ ...state, platformAppVersions: [], platformOsVersions: [] }));
    setOptions((state) => ({ ...state, ...data }));
  }, [platforms, formData.platformOss]);

  useEffect(() => {
    if (!prevFormData) setFormData(queryFormatter.queryToFormData(query));
  }, [prevFormData, query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const query = queryFormatter.formDataToQuery(formData);
    setQuery(query);
    setPage(0);
  };

  const handleCleanQuery = () => {
    setFormData({});
    setQuery({});
    setPage(0);
  };

  return (
    <form onSubmit={handleSubmit}>
      <HStack p="10px" justify="space-between">
        <HStack>
          {Object.keys(query).length > 0 && (
            <Tooltip label="Limpar filtros">
              <IconButton size="sm" colorScheme="main" variant="ghost" icon={<Icon as={FaCircle} />} onClick={handleCleanQuery} />
            </Tooltip>
          )}
          <Text fontWeight="bold">Filtros</Text>
        </HStack>
        <HStack>
          <Tooltip label="Filtrar">
            <IconButton type="submit" size="sm" icon={<Icon as={MdSearch} />} isLoading={loading} />
          </Tooltip>
          <Tooltip label="Exibir/Ocultar filtros">
            <IconButton
              size="sm"
              icon={<Icon as={visible ? MdKeyboardArrowUp : MdKeyboardArrowDown} />}
              onClick={() => setVisible(!visible)}
            />
          </Tooltip>
        </HStack>
      </HStack>

      {visible && (
        <Box p="10px">
          <SimpleGrid columns={[1, 5]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={_.isBoolean(formData.deleteds)}
                onClick={() => setFormData((state) => ({ ...state, deleteds: undefined }))}
              >
                Deletados?
              </LabelButton>
              <SyncSelect
                placeholder="Selecione"
                value={options.deleteds.find((o) => o.value === formData.deleteds) ?? options.deleteds?.[0]}
                options={options.deleteds}
                onChange={({ value }) => setFormData((state) => ({ ...state, deleteds: value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={_.isBoolean(formData.statuses)}
                onClick={() => setFormData((state) => ({ ...state, statuses: undefined }))}
              >
                Status
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.statuses, (value) => _.find(options.statuses, (o) => o.value === value))}
                options={options.statuses}
                onChange={(data) => setFormData((state) => ({ ...state, statuses: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={_.isBoolean(formData.anonymous)}
                onClick={() => setFormData((state) => ({ ...state, anonymous: undefined }))}
              >
                Itens
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.items, (value) => _.find(options.items, (o) => o.value === value))}
                options={options.items}
                onChange={(data) => setFormData((state) => ({ ...state, items: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={_.isBoolean(formData.paymentMethods)}
                onClick={() => setFormData((state) => ({ ...state, paymentMethods: undefined }))}
              >
                Métodos de pagamento
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.paymentMethods, (value) => _.find(options.paymentMethods, (o) => o.value === value))}
                options={options.paymentMethods}
                onChange={(data) => setFormData((state) => ({ ...state, paymentMethods: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={_.isBoolean(formData.paymentMethods)}
                onClick={() => setFormData((state) => ({ ...state, paymentMethods: undefined }))}
              >
                Status da recarga
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.rechargeStatuses, (value) => _.find(options.rechargeStatuses, (o) => o.value === value))}
                options={options.rechargeStatuses}
                onChange={(data) => setFormData((state) => ({ ...state, rechargeStatuses: _.map(data, "value") }))}
              />
            </VStack>
          </SimpleGrid>

          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton active={formData._id} onClick={() => setFormData((state) => ({ ...state, _id: undefined }))}>
                ID
              </LabelButton>
              <Input value={formData._id ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, _id: target.value }))} />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.nid} onClick={() => setFormData((state) => ({ ...state, nid: undefined }))}>
                NID
              </LabelButton>
              <Input value={formData.nid ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, nid: target.value }))} />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.rechargeStorageId}
                onClick={() => setFormData((state) => ({ ...state, rechargeStorageId: undefined }))}
              >
                ID Estocagem
              </LabelButton>
              <Input
                value={formData.rechargeStorageId ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, rechargeStorageId: target.value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.cardNumber} onClick={() => setFormData((state) => ({ ...state, cardNumber: undefined }))}>
                Número do cartão
              </LabelButton>
              <Input
                value={formData.cardNumber ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, cardNumber: target.value }))}
              />
            </VStack>
          </SimpleGrid>

          <SimpleGrid columns={[1, 3]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData.platformOs?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, roles: undefined }))}
              >
                S.O
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.platformOss, (value) => _.find(options.platformOss, (o) => o.value === value))}
                options={options.platformOss}
                onChange={(data) => setFormData((state) => ({ ...state, platformOss: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.platformAppVersion?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, roles: undefined }))}
              >
                Versão S.O
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.platformOsVersions, (value) => _.find(options.platformOsVersions, (o) => o.value === value))}
                options={options.platformOsVersions}
                onChange={(data) => setFormData((state) => ({ ...state, platformOsVersions: _.map(data, "value") }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.platformAppVersion?.length > 0}
                onClick={() => setFormData((state) => ({ ...state, roles: undefined }))}
              >
                Versão App
              </LabelButton>
              <SyncSelect
                isMulti
                placeholder="Selecione"
                value={_.map(formData.platformAppVersions, (value) => _.find(options.platformAppVersions, (o) => o.value === value))}
                options={options.platformAppVersions}
                onChange={(data) => setFormData((state) => ({ ...state, platformAppVersions: _.map(data, "value") }))}
              />
            </VStack>
          </SimpleGrid>

          <SimpleGrid columns={[1, 5]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton active={formData.user} onClick={() => setFormData((state) => ({ ...state, user: undefined }))}>
                ID usuário
              </LabelButton>
              <Input value={formData.user ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, user: target.value }))} />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.phone} onClick={() => setFormData((state) => ({ ...state, phone: undefined }))}>
                Celular
              </LabelButton>
              <Input
                as={InputMask}
                mask="55 (99) 99999-9999"
                value={formData.phone ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton active={formData.document} onClick={() => setFormData((state) => ({ ...state, document: undefined }))}>
                CPF
              </LabelButton>
              <Input
                as={InputMask}
                mask="999.999.999-99"
                value={formData.document ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.amount?.start}
                onClick={() => setFormData((state) => ({ ...state, amount: { ...state.amount, start: undefined } }))}
              >
                Valor (início)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs" fontWeight="bold">
                  R$
                </InputLeftElement>
                <Input
                  as={CurrencyInput}
                  allowNegative={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  value={formData.amount?.start ?? 0}
                  onChangeEvent={(_, __, start) => setFormData((state) => ({ ...state, amount: { ...state.amount, start } }))}
                  autoFocus={false}
                />
              </InputGroup>
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.amount?.end}
                onClick={() => setFormData((state) => ({ ...state, amount: { ...state.amount, end: undefined } }))}
              >
                Valor (fim)
              </LabelButton>
              <InputGroup>
                <InputLeftElement fontSize="xs" fontWeight="bold">
                  R$
                </InputLeftElement>
                <Input
                  as={CurrencyInput}
                  allowNegative={true}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  value={formData.amount?.end ?? 0}
                  onChangeEvent={(_, __, end) => setFormData((state) => ({ ...state, amount: { ...state.amount, end } }))}
                  autoFocus={false}
                />
              </InputGroup>
            </VStack>
          </SimpleGrid>

          <SimpleGrid columns={[1, 4]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData.closedAt?.start}
                onClick={() => setFormData((state) => ({ ...state, closedAt: { ...state.closedAt, start: undefined } }))}
              >
                Fechamento pedido (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.closedAt?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, closedAt: { ...state.closedAt, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.closedAt?.end}
                onClick={() => setFormData((state) => ({ ...state, closedAt: { ...state.closedAt, end: undefined } }))}
              >
                Fechamento pedido (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.closedAt?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, closedAt: { ...state.closedAt, end: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.createdAt?.start}
                onClick={() => setFormData((state) => ({ ...state, closedAt: { ...state.closedAt, start: undefined } }))}
              >
                Criação (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.createdAt?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.createdAt?.end}
                onClick={() => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, end: undefined } }))}
              >
                Criação (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.createdAt?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, createdAt: { ...state.createdAt, end: target.value } }))}
              />
            </VStack>
          </SimpleGrid>
          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <VStack align="stretch">
              <LabelButton
                active={formData.rechargeClosedAt?.start}
                onClick={() => setFormData((state) => ({ ...state, rechargeClosedAt: { ...state.rechargeClosedAt, start: undefined } }))}
              >
                Fechamento recarga (início)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.rechargeClosedAt?.start ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, rechargeClosedAt: { ...state.rechargeClosedAt, start: target.value } }))}
              />
            </VStack>
            <VStack align="stretch">
              <LabelButton
                active={formData.rechargeClosedAt?.end}
                onClick={() => setFormData((state) => ({ ...state, rechargeClosedAt: { ...state.rechargeClosedAt, end: undefined } }))}
              >
                Fechamento recarga (fim)
              </LabelButton>
              <Input
                as={InputMask}
                mask="99/99/9999"
                value={formData.rechargeClosedAt?.end ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, rechargeClosedAt: { ...state.rechargeClosedAt, end: target.value } }))}
              />
            </VStack>
          </SimpleGrid>
        </Box>
      )}
    </form>
  );
};

export default Filter;
