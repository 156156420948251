import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip, IconButton, Icon, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { MdOutlineError } from "react-icons/md";
import { useApiGet, useCacheState, useStickyState } from "hooks";
import { HashButton, DataTable, StatusBadge } from "components";
import { translator } from "lib";

export const BackupsList = () => {
  const [perPage, setPerPage] = useCacheState(20, "BackupsList:perPage");
  const [sort, setSort] = useStickyState({ createdAt: -1 }, "BackupsList:sort");
  const [page, setPage] = useCacheState(0, "BackupsList:page");
  const [response, loading, refresh, error] = useApiGet("/backups", { perPage, page, sort });

  return (
    <>
      <HStack py="5px" px="10px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/backups">
              Backups
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </HStack>
      <DataTable
        tableId="BackupsList:table"
        title="Backups"
        data={response?.data ?? []}
        size={response?.size ?? 0}
        loading={loading}
        sort={sort}
        onSort={setSort}
        perPage={perPage}
        onPerPage={setPerPage}
        page={page}
        onPaginate={setPage}
        onRefresh={refresh}
        error={error}
        renderItems={[
          { key: "_id", label: "ID", visible: true, render: (value) => <HashButton _id={value} /> },
          { key: "status", label: "Status", visible: true, exporter: translator, render: (value) => <StatusBadge label={value} /> },
          { key: "path", label: "Caminho", visible: true },
          {
            key: "errMessage",
            label: "Mensagem",
            visible: true,
            render: (value) =>
              value ? (
                <Tooltip label={value}>
                  <IconButton colorScheme="red" variant="ghost" icon={<Icon as={MdOutlineError} />} />
                </Tooltip>
              ) : (
                "-"
              ),
          },
          {
            key: "createdAt",
            label: "Criação",
            visible: true,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
          {
            key: "updatedAt",
            label: "Atualização",
            visible: false,
            formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
          },
        ]}
      />
    </>
  );
};
