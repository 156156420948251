import React, { useState, useEffect } from "react";
import { Text, Stack, Icon, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import { MdBlock, MdClose } from "react-icons/md";
import { eventEmitter } from "lib";

export const PermissionDenied = () => {
  const [permissionDenied, setPermissionDenied] = useState(false);

  useEffect(() => {
    eventEmitter.subscribe("onPermissionDenied", (error) => setPermissionDenied(error));
  }, []);

  const handleClose = () => setPermissionDenied(false);

  return (
    <Modal isOpen={permissionDenied.message} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="30px">
          <Stack align="center">
            <Icon as={MdBlock} boxSize={40} />
            <Text fontSize="lg" fontWeight="bold">
              Acesso negado
            </Text>
            <Text fontSize="xs" textAlign="center" marginBottom="20px">
              {permissionDenied.message}
            </Text>
            <Button onClick={handleClose} leftIcon={<Icon as={MdClose} />}>
              Fechar
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
