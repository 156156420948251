import React, { useState, useEffect, createContext, useRef } from "react";
import moment from "moment";
import _ from "lodash";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { MdChevronLeft, MdSave, MdRefresh } from "react-icons/md";
import { FiShoppingBag } from "react-icons/fi";
import { BsChatSquareText } from "react-icons/bs";
import { messages } from "consts";
import { useApiGet } from "hooks";
import { api, validationErrorHandler } from "lib";
import * as yup from "yup";
import Personal from "./personal";
import Addresses from "./addresses";
import Security from "./security";
import Cards from "./cards";
import PaymentMethods from "./paymentMethods";
import PhoneNumber from "./phoneNumber";

export const UsersContext = createContext();

export const UsersDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [data, loadingData, refreshData] = useApiGet(`/users/${_id}`);
  const [cardsData, loadingCards, refreshCards] = useApiGet(`/users/${_id}/cards`);
  const [cards, setCards] = useState([]);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [loadingBalances, setLoadingBalances] = useState(false);
  const [isOpenPhoneNumber, setIsOpenPhoneNumber] = useState(false);
  const toast = useToast();
  const tabRefs = {
    personal: useRef(),
    addresses: useRef(),
    cards: useRef(),
    paymentMethods: useRef(),
    security: useRef(),
  };

  useEffect(() => {
    const formData = data ?? { active: true };
    formData.birth = formData.birth && moment(formData.birth).format("DD/MM/YYYY");
    setFormData(formData);
  }, [data]);

  useEffect(() => {
    const fetchBalance = async (card, index) => {
      try {
        setLoadingBalances((state) => ({ ...state, [card._id]: true }));
        const balance = await api.get(`/users/${_id}/cards/${card.number}/balance`);
        setCards((state) => {
          const tmp = [...state];
          const index = tmp.findIndex((o) => o._id === card._id);
          if (index !== -1) tmp[index].balance = balance;
          return tmp;
        });
      } finally {
        setLoadingBalances((state) => ({ ...state, [card._id]: false }));
      }
    };
    setCards(cardsData ?? []);
    !loadingCards && Promise.all(_.map(cardsData, fetchBalance));
  }, [cardsData, loadingCards, _id]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        name: yup.string().required(messages.error.required),
        phone: yup.string().required(messages.error.required),
      });
      const data = { ...formData, birth: formData.birth && moment(formData.birth, "DD/MM/YYYY").toDate() };
      await schema.validate(data, { abortEarly: false });
      handleSaveData(data);
      setFormErrors({});
    } catch (error) {
      const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
      setFormErrors(formErrors);
      const [firstKey] = Object.keys(formErrors);
      if (firstKey) {
        const [path] = firstKey.split(".") ?? [];
        (tabRefs[path] ?? tabRefs.personal).current?.focus();
      }
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) await api.put(`/users/${_id}`, data);
      else {
        const saved = await api.post("/users", data);
        navigate(`/users/details/${saved._id}`, { replace: true });
      }
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        formData,
        formErrors,
        setFormData,
        setFormErrors,
        cards,
        refreshCards,
        loadingBalances,
        isOpenPhoneNumber,
        setIsOpenPhoneNumber,
        refreshData,
      }}
    >
      <HStack justify="space-between" mb={8}>
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/users">
              Usuários
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.name ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <HStack>
            <Text fontSize="xs">Testador?</Text>
            <Switch size="sm" isChecked={formData.tester} onChange={() => setFormData((state) => ({ ...state, tester: !state.tester }))} />
          </HStack>
          <HStack>
            <Text fontSize="xs">Ativo?</Text>
            <Switch size="sm" isChecked={formData.active} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
          </HStack>
          <Button
            size="sm"
            colorScheme="main"
            leftIcon={<Icon as={BsChatSquareText} />}
            onClick={() => navigate(`/attendances?members.user=${formData._id}`)}
          >
            Atendimentos
          </Button>
          <Button
            size="sm"
            colorScheme="main"
            leftIcon={<Icon as={FiShoppingBag} />}
            onClick={() => navigate(`/orders?user._id=${formData._id}`)}
          >
            Pedidos
          </Button>
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <Tabs isFitted variant="enclosed">
          <TabList>
            <Tab fontSize="xs" ref={tabRefs.personal}>
              Pessoal
            </Tab>
            <Tab fontSize="xs" ref={tabRefs.addresses}>
              Endereços
            </Tab>
            <Tab fontSize="xs" ref={tabRefs.cards}>
              <HStack>
                <Text>Cartões</Text>
                <IconButton size="xs" variant="ghost" icon={<Icon as={MdRefresh} />} isLoading={loadingCards} onClick={refreshCards} />
              </HStack>
            </Tab>
            <Tab fontSize="xs" ref={tabRefs.paymentMethods}>
              Métodos de Pagamento
            </Tab>
            <Tab fontSize="xs" ref={tabRefs.security}>
              Segurança
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Personal />
            </TabPanel>
            <TabPanel>
              <Addresses />
            </TabPanel>
            <TabPanel>
              <Cards />
            </TabPanel>
            <TabPanel>
              <PaymentMethods />
            </TabPanel>
            <TabPanel>
              <Security />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <HStack justify="flex-end" spacing={4} mt={4}>
          <Button as={RouterLink} to="/users" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
      <PhoneNumber />
    </UsersContext.Provider>
  );
};
