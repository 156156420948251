import React, { useCallback, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import _ from "lodash";
import * as yup from "yup";
import { api } from "lib";
import { messages } from "consts";
import { UsersContext } from "./index";

const PhoneNumber = () => {
  const { _id } = useParams();
  const { isOpenPhoneNumber, setIsOpenPhoneNumber, refreshData } = useContext(UsersContext);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  const handleSaveData = useCallback(
    async (data) => {
      try {
        setLoadingSaveData(true);
        await api.put(`/users/${_id}/phone-number`, data);
        setFormData({});
        setIsOpenPhoneNumber(false);
        refreshData();
        toast({ description: messages.success.saveData, status: "success", isClosable: true });
      } catch (error) {
        toast({ description: error.message, status: "error", isClosable: true });
      } finally {
        setLoadingSaveData(false);
      }
    },
    [_id, toast, refreshData, setIsOpenPhoneNumber]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          phone: yup.string().required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSaveData(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [formData, handleSaveData]
  );

  return (
    <Modal isCentered isOpen={isOpenPhoneNumber} onClose={() => setIsOpenPhoneNumber(false)}>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Alterar número de telefone</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired={true} isInvalid={formErrors?.phone}>
              <FormLabel>Número de telefone</FormLabel>
              <Input
                as={InputMask}
                mask="+55 (99) 9 9999-9999"
                value={formData?.phone ?? ""}
                onChange={({ target }) => setFormData((state) => ({ ...state, phone: target.value }))}
              />
              <FormErrorMessage>{formErrors?.phone}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => setIsOpenPhoneNumber(false)}>
              Cancelar
            </Button>
            <Button type="submit" colorScheme="main" isLoading={loadingSaveData}>
              Finalizar
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default PhoneNumber;
