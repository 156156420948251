import React, { useCallback, useContext, useState } from "react";
import { Box, Button, HStack, Icon, Text } from "@chakra-ui/react";
import { RiChatNewLine, RiChatDeleteLine, RiChatForwardLine } from "react-icons/ri";
import { Finish } from "../confirmations";
import { ChatContext, SocketContext } from "../contexts";

export const Toolbar = () => {
  const { socket } = useContext(SocketContext);
  const { selectedAttendance, isMember, isFinished } = useContext(ChatContext);
  const [loadingJoinAttendance, setLoadingJoinAttendance] = useState(false);
  const [loadingLeaveAttendance, setLoadingLeaveAttendance] = useState(false);
  const [isOpenFinish, setIsOpenFinish] = useState(false);

  const handleJoinAttendance = useCallback(() => {
    setLoadingJoinAttendance(true);
    socket.emit("@join_attendance", { attendanceId: selectedAttendance._id }, () => setLoadingJoinAttendance(false));
  }, [socket, selectedAttendance._id]);

  const handleLeaveAttendance = useCallback(() => {
    setLoadingLeaveAttendance(true);
    socket.emit("@leave_attendance", { attendanceId: selectedAttendance._id }, () => setLoadingLeaveAttendance(false));
  }, [socket, selectedAttendance._id]);

  return (
    <>
      <HStack p="10px" justify="space-between">
        <Box>
          <Text fontSize="md" fontWeight="bold">
            Atendimento #{selectedAttendance.nid}
          </Text>
          <Text fontSize="xs">{selectedAttendance.subject?.title}</Text>
        </Box>
        {!isFinished && (
          <HStack>
            {isMember ? (
              <>
                <Button
                  size="sm"
                  leftIcon={<Icon as={RiChatForwardLine} />}
                  colorScheme="main"
                  variant="ghost"
                  onClick={handleLeaveAttendance}
                  isLoading={loadingLeaveAttendance}
                  isDisabled={!socket}
                >
                  Sair
                </Button>
                <Button
                  size="sm"
                  leftIcon={<Icon as={RiChatDeleteLine} />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => setIsOpenFinish(true)}
                  isDisabled={!socket}
                >
                  Encerrar
                </Button>
              </>
            ) : (
              <Button
                size="sm"
                leftIcon={<Icon as={RiChatNewLine} />}
                colorScheme="green"
                variant="ghost"
                onClick={handleJoinAttendance}
                isLoading={loadingJoinAttendance}
                isDisabled={!socket}
              >
                Ingressar
              </Button>
            )}
          </HStack>
        )}
      </HStack>
      <Finish isOpen={isOpenFinish} onClose={() => setIsOpenFinish(false)} />
    </>
  );
};
