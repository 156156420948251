import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { messages } from "consts";
import { validationErrorHandler } from "lib";
import { useApiGet } from "hooks";
import * as yup from "yup";
import { MdSave, MdChevronLeft } from "react-icons/md";
import api from "lib/api";

export const AttendanceSubjectsDetails = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [data, loadingData, refreshData] = useApiGet(`/attendance-subjects/${_id}`);
  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    setFormData(data ?? { active: true });
  }, [data]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const schema = yup.object().shape({
        title: yup.string().required(messages.error.required),
        active: yup.bool().required(messages.error.required),
      });
      await schema.validate(formData);
      handleSaveData(formData);
      setFormErrors({});
    } catch (error) {
      setFormErrors({ [error.path]: error.errors });
    }
  };

  const handleSaveData = async (data) => {
    try {
      setLoadingSaveData(true);
      if (_id) await api.put(`/attendance-subjects/${_id}`, data);
      else {
        const saved = await api.post("/attendance-subjects", data);
        navigate(`/attendance-subjects/details/${saved._id}`, { replace: true });
      }
      refreshData();
      toast({ description: messages.success.saveData, status: "success", isClosable: true });
    } catch (error) {
      validationErrorHandler(error, setFormErrors);
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setLoadingSaveData(false);
    }
  };

  return (
    <Box>
      <HStack marginBottom="20px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="sm">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/attendance-subjects">
              Assuntos de atendimento
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{loadingData ? <Spinner size="xs" /> : data?.title ?? "Novo"}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <Text fontSize="sm">Ativo?</Text>
          <Switch isChecked={formData.active} onChange={() => setFormData((state) => ({ ...state, active: !state.active }))} />
        </HStack>
      </HStack>

      <form onSubmit={handleSubmit}>
        <SimpleGrid spacing={4} mb={4}>
          <FormControl isRequired={true} isInvalid={formErrors.title}>
            <FormLabel>Título</FormLabel>
            <Input value={formData.title ?? ""} onChange={({ target }) => setFormData((state) => ({ ...state, title: target.value }))} />
            <FormErrorMessage>{formErrors.title}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <HStack justify="flex-end" spacing={4} mt={6}>
          <Button as={RouterLink} to="/attendance-subjects" leftIcon={<Icon as={MdChevronLeft} />}>
            Voltar
          </Button>
          <Button type="submit" leftIcon={<Icon as={MdSave} />} colorScheme="main" isLoading={loadingSaveData || loadingData}>
            Salvar
          </Button>
        </HStack>
      </form>
    </Box>
  );
};
