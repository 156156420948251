import { useEffect, useState } from "react";

const cache = {};
const defaultOptions = { caching: true };

export const useCacheState = (defaultValue, key, options = defaultOptions) => {
  const [value, setValue] = useState(() => {
    if (options.caching && cache[key]) return cache[key];
    return defaultValue;
  });

  useEffect(() => {
    if (options.caching) cache[key] = value;
  }, [key, options.caching, value]);

  return [value, setValue];
};
