import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { MdDelete, MdRefresh, MdSearch } from "react-icons/md";
import { StatusBadge, Dialog } from "components";
import { api, masks } from "lib";
import { useApiGet } from "hooks";
import { messages } from "consts";

export const CustomersList = () => {
  const [formData, setFormData] = useState({});
  const [query, setQuery] = useState({});
  const [key, setKey] = useState("mdpg");
  const [customers, loadingCustomers, refreshCustomers] = useApiGet("/customers", { query });
  const toast = useToast();
  const deleteRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setQuery(formData);
  };

  const handleDelete = async (item) => {
    try {
      await api.delete(`/customers/${item.id}`, { data: { key } });
      toast({ description: messages.success.deleteData, status: "success", isClosable: true });
      refreshCustomers();
    } catch (error) {
      toast({ description: error.message, status: "error", isClosable: true });
    }
  };

  return (
    <>
      <HStack py="5px" px="10px" justify="space-between">
        <Breadcrumb fontWeight="medium" fontSize="xs">
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/home">
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/customers">
              Clientes
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <Select size="sm" value={key} onChange={({ target }) => setKey(target.value)}>
            <option value="mdpg">Mundipagg</option>
            <option value="pgme">Pagar.me</option>
          </Select>
          <IconButton size="sm" icon={<Icon as={MdRefresh} />} isLoading={loadingCustomers} onClick={refreshCustomers} />
        </HStack>
      </HStack>

      <Alert status="info" borderRadius="lg" my={4}>
        <AlertIcon />
        <AlertDescription>
          Antes de realizar alterações, certifique-se que o usuário buscado é realmente proprietário das contas abaixo dos gateways de
          pagamento.
        </AlertDescription>
      </Alert>

      <Box p="10px">
        <form onSubmit={handleSubmit}>
          <HStack alignItems="flex-end" spacing={4} mb={6}>
            <FormControl>
              <FormLabel>Documento</FormLabel>
              <Input
                value={formData.document}
                onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value.replace(/\D/g, "") }))}
              />
            </FormControl>
            <FormControl>
              <FormLabel>E-mail</FormLabel>
              <Input value={formData.email} onChange={({ target }) => setFormData((state) => ({ ...state, email: target.value }))} />
            </FormControl>
            <IconButton type="submit" icon={<Icon as={MdSearch} />} />
          </HStack>
        </form>

        <Table size="sm" fontSize="xs">
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>Código</Th>
              <Th>Nome</Th>
              <Th>E-mail</Th>
              <Th>Documento</Th>
              <Th>Criação</Th>
              <Th>Atualização</Th>
              <Th>#</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(customers?.[key], (item) => (
              <Tr key={item.id}>
                <Td>
                  <StatusBadge label={item.code === "deleted" ? "deleted" : "active"} />
                </Td>
                <Td>{item.code}</Td>
                <Td>{item.name}</Td>
                <Td>{item.email}</Td>
                <Td>{masks.document(item.document)}</Td>
                <Td>{moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")}</Td>
                <Td>{moment(item.updated_at).format("DD/MM/YYYY HH:mm:ss")}</Td>
                <Td>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    icon={<Icon as={MdDelete} />}
                    onClick={() => deleteRef.current.confirm(item, handleDelete)}
                    isDisabled={item.code === "deleted"}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Dialog.Delete ref={deleteRef} />
    </>
  );
};
