import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody, Image, Center } from "@chakra-ui/react";

export const ImageViewer = ({ src, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent backgroundColor="transparent" boxShadow="none">
        <ModalBody>
          <Center>
            <Image src={src} borderRadius="md" />
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
