import React from "react";
import _ from "lodash";
import { VStack, Button } from "@chakra-ui/react";
import { useApiGet, useFuse } from "hooks";

export const QuickMessages = ({ isOpen, searchText, onSubmit }) => {
  const [data] = useApiGet("/attendance-quick-messages");
  const quickMessages = useFuse(["body"], data?.data ?? [], searchText);

  return isOpen ? (
    <VStack alignItems="flex-start" p="10px" maxH="200px" overflowY="auto">
      {_.map(quickMessages, (item) => (
        <Button
          key={item._id}
          whiteSpace="normal"
          h="auto"
          px="15px"
          py="10px"
          textAlign="left"
          fontSize="sm"
          fontWeight="normal"
          onClick={() => onSubmit?.(item.body)}
        >
          {item.body}
        </Button>
      ))}
    </VStack>
  ) : null;
};
