import { io } from "socket.io-client";
import { getAuth, getIdToken } from "@firebase/auth";

export const socketConnector = async () => {
  const auth = getAuth();
  const token = await getIdToken(auth.currentUser);
  return io(process.env.REACT_APP_API_BASE_URL, {
    reconnectionDelayMax: 10000,
    auth: { token },
  });
};
