const terms = {
  ADMIN: "Administrador",
  BOLETO: "Boleto",
  CREDIT_CARD: "Cartão de Crédito",
  CUSTOMER: "Cliente",
  SUPPORT: "Suporte",
  DEPOSIT: "Depósito",
  EDITOR: "Editor",
  FINISHED: "Finalizado",
  IN_PROGRESS: "Em andamento",
  ISSUE: "Emissão",
  MANAGER: "Gerente",
  PAID: "Pago",
  PENDING: "Pendente",
  PHYSICAL: "Físico",
  PIX: "Pix",
  RECHARGE: "Recarga",
  REISSUE: "Reemissão",
  TAXES: "Taxas",
  VIRTUAL: "Virtual",
  SUCCESS: "Sucesso",
  RETRYING: "Tentando novamente",
  WAITING_PAYMENT: "Pendente",
  FAILED: "Falhou",
  DELIVERY: "Entrega",
  WAITING: "Aguardando",
  EXPIRED: "Expirado",
  SENDING: "Enviando",
  SENDED: "Enviado",
  DELETED: "Deletado",
  ACTIVE: "Ativo",
  CANCELED: "Cancelado",
  PAYMENT_CONFIRMED: "Pagamento confirmado",
  PAYMENT_FAILED: "Pagamento falhou",
  RECHARGE_CONFIRMED: "Recarga confirmada",
  SHIPPING_MANUFACTURING: "Produzindo cartão",
  SHIPPING_DELIVERING: "Saiu para entrega",
  SHIPPING_DELIVERED: "Pedido entregue",
};

export const translator = (word = "") => {
  try {
    const key = word.toUpperCase();
    if (terms[key]) return terms[key];
    return key;
  } catch (error) {
    return word;
  }
};
